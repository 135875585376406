import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DiffDTO as CrmDiffDTO } from '@swagger/crm';
import { DiffDTO as OmsDiffDTO } from '@swagger/oms';

@Component({
  selector: 'shared-history-log-entry-values',
  templateUrl: 'history-log-entry-values.component.html',
  styleUrls: ['history-log-entry-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedHistoryLogEntryValuesComponent {
  @Input() historyLogEntryValues: CrmDiffDTO | OmsDiffDTO;
  constructor() {}
}
