/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfLogisticianDTO } from '../models/list-response-args-of-logistician-dto';
import { ResponseArgsOfLogisticianDTO } from '../models/response-args-of-logistician-dto';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutLogisticianService extends __BaseService {
  static readonly StoreCheckoutLogisticianGetLogisticiansPath = '/store/checkout/logistician';
  static readonly StoreCheckoutLogisticianGetLogisticianByIdPath = '/store/checkout/logistician/{logisticianId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutLogisticianService.StoreCheckoutLogisticianGetLogisticiansParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  StoreCheckoutLogisticianGetLogisticiansResponse(
    params: StoreCheckoutLogisticianService.StoreCheckoutLogisticianGetLogisticiansParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfLogisticianDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/store/checkout/logistician`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfLogisticianDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutLogisticianService.StoreCheckoutLogisticianGetLogisticiansParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  StoreCheckoutLogisticianGetLogisticians(
    params: StoreCheckoutLogisticianService.StoreCheckoutLogisticianGetLogisticiansParams,
  ): __Observable<ListResponseArgsOfLogisticianDTO> {
    return this.StoreCheckoutLogisticianGetLogisticiansResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfLogisticianDTO));
  }

  /**
   * @param logisticianId undefined
   */
  StoreCheckoutLogisticianGetLogisticianByIdResponse(
    logisticianId: number,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfLogisticianDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/checkout/logistician/${encodeURIComponent(String(logisticianId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLogisticianDTO>;
      }),
    );
  }
  /**
   * @param logisticianId undefined
   */
  StoreCheckoutLogisticianGetLogisticianById(logisticianId: number): __Observable<ResponseArgsOfLogisticianDTO> {
    return this.StoreCheckoutLogisticianGetLogisticianByIdResponse(logisticianId).pipe(
      __map((_r) => _r.body as ResponseArgsOfLogisticianDTO),
    );
  }
}

module StoreCheckoutLogisticianService {
  /**
   * Parameters for StoreCheckoutLogisticianGetLogisticians
   */
  export interface StoreCheckoutLogisticianGetLogisticiansParams {
    take?: null | number;
    skip?: null | number;
  }
}

export { StoreCheckoutLogisticianService };
