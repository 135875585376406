import { Action, createReducer, on } from '@ngrx/store';
import {
  setSection,
  addProcess,
  removeProcess,
  setActivatedProcess,
  patchProcess,
  patchProcessData,
  setTitle,
} from './application.actions';
import { ApplicationState, INITIAL_APPLICATION_STATE } from './application.state';

const _applicationReducer = createReducer(
  INITIAL_APPLICATION_STATE,
  on(setTitle, (state, { title }) => ({ ...state, title })),
  on(setSection, (state, { section }) => ({ ...state, section })),
  on(addProcess, (state, { process }) => ({ ...state, processes: [...state.processes, { data: {}, ...process }] })),
  on(removeProcess, (state, { processId }) => {
    const processes = state?.processes?.filter((process) => process.id !== processId) || [];
    return { ...state, processes };
  }),
  on(setActivatedProcess, (state, { activatedProcessId }) => {
    const processes = state.processes.map((process) => {
      if (process.id === activatedProcessId) {
        return { ...process, activated: Date.now() };
      }
      return process;
    });

    return { ...state, processes };
  }),
  on(patchProcess, (state, { processId, changes }) => {
    const processes = state.processes.map((process) => {
      if (process.id === processId) {
        return { ...process, ...changes, id: processId };
      }
      return process;
    });

    return { ...state, processes };
  }),
  on(patchProcessData, (state, { processId, data }) => {
    const processes = state.processes.map((process) => {
      if (process.id === processId) {
        return { ...process, data: { ...(process.data || {}), ...data } };
      }
      return process;
    });

    return { ...state, processes };
  }),
);

export function applicationReducer(state: ApplicationState, action: Action) {
  return _applicationReducer(state, action);
}
