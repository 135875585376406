/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfPaymentDTO } from '../models/response-args-of-payment-dto';
import { ResponseArgsOfCheckoutDTO } from '../models/response-args-of-checkout-dto';
import { ResponseArgsOfIEnumerableOfSelectionDTOOfPaymentType } from '../models/response-args-of-ienumerable-of-selection-dtoof-payment-type';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutPaymentService extends __BaseService {
  static readonly StoreCheckoutPaymentGetCheckoutPaymentPath = '/store/checkout/{checkoutId}/payment';
  static readonly StoreCheckoutPaymentSetPaymentTypePath = '/store/checkout/{checkoutId}/payment';
  static readonly StoreCheckoutPaymentGetDeliveryPaymentPath = '/store/checkout/{checkoutId}/delivery/{deliveryId}/payment';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutPaymentService.StoreCheckoutPaymentGetCheckoutPaymentParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutPaymentGetCheckoutPaymentResponse(
    params: StoreCheckoutPaymentService.StoreCheckoutPaymentGetCheckoutPaymentParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfPaymentDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPaymentDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutPaymentService.StoreCheckoutPaymentGetCheckoutPaymentParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutPaymentGetCheckoutPayment(
    params: StoreCheckoutPaymentService.StoreCheckoutPaymentGetCheckoutPaymentParams,
  ): __Observable<ResponseArgsOfPaymentDTO> {
    return this.StoreCheckoutPaymentGetCheckoutPaymentResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfPaymentDTO));
  }

  /**
   * @param params The `StoreCheckoutPaymentService.StoreCheckoutPaymentSetPaymentTypeParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `paymentType`:
   *
   * - `locale`:
   */
  StoreCheckoutPaymentSetPaymentTypeResponse(
    params: StoreCheckoutPaymentService.StoreCheckoutPaymentSetPaymentTypeParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.paymentType != null) __params = __params.set('paymentType', params.paymentType.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutPaymentService.StoreCheckoutPaymentSetPaymentTypeParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `paymentType`:
   *
   * - `locale`:
   */
  StoreCheckoutPaymentSetPaymentType(
    params: StoreCheckoutPaymentService.StoreCheckoutPaymentSetPaymentTypeParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutPaymentSetPaymentTypeResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutPaymentService.StoreCheckoutPaymentGetDeliveryPaymentParams` containing the following parameters:
   *
   * - `deliveryId`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutPaymentGetDeliveryPaymentResponse(
    params: StoreCheckoutPaymentService.StoreCheckoutPaymentGetDeliveryPaymentParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfSelectionDTOOfPaymentType>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/delivery/${encodeURIComponent(String(params.deliveryId))}/payment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfSelectionDTOOfPaymentType>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutPaymentService.StoreCheckoutPaymentGetDeliveryPaymentParams` containing the following parameters:
   *
   * - `deliveryId`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutPaymentGetDeliveryPayment(
    params: StoreCheckoutPaymentService.StoreCheckoutPaymentGetDeliveryPaymentParams,
  ): __Observable<ResponseArgsOfIEnumerableOfSelectionDTOOfPaymentType> {
    return this.StoreCheckoutPaymentGetDeliveryPaymentResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfSelectionDTOOfPaymentType),
    );
  }
}

module StoreCheckoutPaymentService {
  /**
   * Parameters for StoreCheckoutPaymentGetCheckoutPayment
   */
  export interface StoreCheckoutPaymentGetCheckoutPaymentParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutPaymentSetPaymentType
   */
  export interface StoreCheckoutPaymentSetPaymentTypeParams {
    checkoutId: number;
    returnCheckout?: boolean;
    paymentType?: 0 | 1 | 2 | 4 | 8 | 16 | 32 | 64 | 128 | 256 | 512 | 1024 | 2048 | 4096 | 8192 | 16384 | 32768;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutPaymentGetDeliveryPayment
   */
  export interface StoreCheckoutPaymentGetDeliveryPaymentParams {
    deliveryId: number;
    checkoutId: number;
    locale?: null | string;
  }
}

export { StoreCheckoutPaymentService };
