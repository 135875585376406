/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { PrintConfiguration as __Configuration } from '../print-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfString } from '../models/response-args-of-ienumerable-of-string';
import { ListResponseArgsOfKeyValueDTOOfStringAndString } from '../models/list-response-args-of-key-value-dtoof-string-and-string';
import { ResponseArgs } from '../models/response-args';
import { PrintRequest } from '../models/print-request';
import { PrintRequestOfString } from '../models/print-request-of-string';
@Injectable({
  providedIn: 'root',
})
class PrintService extends __BaseService {
  static readonly PrintInitializeLabelPrintersPath = '/printers/label/init';
  static readonly PrintInitializeOfficePrintersPath = '/printers/office/init';
  static readonly PrintPrintersPath = '/printers';
  static readonly PrintLabelPrintersPath = '/printers/label';
  static readonly PrintOfficePrintersPath = '/printers/office';
  static readonly PrintTestPath = '/print/test';
  static readonly PrintPrinterDetailsPath = '/printers/details';
  static readonly PrintDirectPrintTestPath = '/printers/directprinttest';
  static readonly PrintPrintPdfPath = '/print/pdf';
  static readonly PrintMonitorPath = '/print/monitor';
  static readonly PrintStatusPath = '/print/status';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Initialize label printers
   */
  PrintInitializeLabelPrintersResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/printers/label/init`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfString>;
      }),
    );
  }
  /**
   * Initialize label printers
   */
  PrintInitializeLabelPrinters(): __Observable<ResponseArgsOfIEnumerableOfString> {
    return this.PrintInitializeLabelPrintersResponse().pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfString));
  }

  /**
   * Initialize office printers
   */
  PrintInitializeOfficePrintersResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/printers/office/init`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfString>;
      }),
    );
  }
  /**
   * Initialize office printers
   */
  PrintInitializeOfficePrinters(): __Observable<ResponseArgsOfIEnumerableOfString> {
    return this.PrintInitializeOfficePrintersResponse().pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfString));
  }

  /**
   * Verfügbare Drucker
   */
  PrintPrintersResponse(): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/printers`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Verfügbare Drucker
   */
  PrintPrinters(): __Observable<ListResponseArgsOfKeyValueDTOOfStringAndString> {
    return this.PrintPrintersResponse().pipe(__map((_r) => _r.body as ListResponseArgsOfKeyValueDTOOfStringAndString));
  }

  /**
   * Verfügbare Label-Drucker
   */
  PrintLabelPrintersResponse(): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/printers/label`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Verfügbare Label-Drucker
   */
  PrintLabelPrinters(): __Observable<ListResponseArgsOfKeyValueDTOOfStringAndString> {
    return this.PrintLabelPrintersResponse().pipe(__map((_r) => _r.body as ListResponseArgsOfKeyValueDTOOfStringAndString));
  }

  /**
   * Verfügbare Office-Drucker
   */
  PrintOfficePrintersResponse(): __Observable<__StrictHttpResponse<ListResponseArgsOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/printers/office`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Verfügbare Office-Drucker
   */
  PrintOfficePrinters(): __Observable<ListResponseArgsOfKeyValueDTOOfStringAndString> {
    return this.PrintOfficePrintersResponse().pipe(__map((_r) => _r.body as ListResponseArgsOfKeyValueDTOOfStringAndString));
  }

  /**
   * Testausdruck
   * @param data undefined
   */
  PrintTestResponse(data: PrintRequest): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/test`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Testausdruck
   * @param data undefined
   */
  PrintTest(data: PrintRequest): __Observable<ResponseArgs> {
    return this.PrintTestResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Druckerdetails
   * @param data undefined
   */
  PrintPrinterDetailsResponse(data: PrintRequest): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/printers/details`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Druckerdetails
   * @param data undefined
   */
  PrintPrinterDetails(data: PrintRequest): __Observable<ResponseArgs> {
    return this.PrintPrinterDetailsResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * @param data undefined
   */
  PrintDirectPrintTestResponse(data: PrintRequest): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/printers/directprinttest`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * @param data undefined
   */
  PrintDirectPrintTest(data: PrintRequest): __Observable<ResponseArgs> {
    return this.PrintDirectPrintTestResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Druckt das übergebene Pdf-Dokument (base64)
   * @param data Artikel
   */
  PrintPrintPdfResponse(data: PrintRequestOfString): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/pdf`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Druckt das übergebene Pdf-Dokument (base64)
   * @param data Artikel
   */
  PrintPrintPdf(data: PrintRequestOfString): __Observable<ResponseArgs> {
    return this.PrintPrintPdfResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Monitoring
   */
  PrintMonitorResponse(): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/print/monitor`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'blob',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      }),
    );
  }
  /**
   * Monitoring
   */
  PrintMonitor(): __Observable<Blob> {
    return this.PrintMonitorResponse().pipe(__map((_r) => _r.body as Blob));
  }

  /**
   * Status
   */
  PrintStatusResponse(): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/print/status`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'blob',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      }),
    );
  }
  /**
   * Status
   */
  PrintStatus(): __Observable<Blob> {
    return this.PrintStatusResponse().pipe(__map((_r) => _r.body as Blob));
  }
}

module PrintService {}

export { PrintService };
