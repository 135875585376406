<shared-loader [loading]="fetchShoppingCart$ | async" background="true" spinnerSize="36">
  <div class="wrapper">
    <div>
      <h1 class="text-center text-2xl font-bold">Kulturpass Warenkorb</h1>
      <p class="text-center text-lg">Bitte scannen Sie Artikel, um den Code einzulösen.</p>
    </div>
    <div>
      <shared-kulturpass-order-searchbox></shared-kulturpass-order-searchbox>
    </div>
    <div class="border-y border-solid border-[#EFF1F5] py-3 px-4 -mx-4 flex flex-row items-center">
      <div class="w-[6.875rem]">Wertgutschein</div>
      <div class="ml-4 font-bold">
        {{ kulturpassCode$ | async }}
      </div>
    </div>
    <div class="border-b border-solid border-[#EFF1F5] py-3 px-4 -mx-4 flex flex-row items-center">
      <div class="w-[6.875rem]">Filiale</div>
      <div class="ml-4 font-bold">
        {{ branch$ | async | branchName: 'name-address' }}
      </div>
    </div>
    <div class="overflow-y-auto -mx-4 scroll-bar">
      <div *ngIf="emptyShoppingCart$ | async" class="h-full grid items-center justify-center">
        <h3 class="text-xl font-bold text-center text-gray-500">
          Warenkorb ist leer, bitte suchen oder scannen <br />
          Sie Artikel um den Warenkob zu füllen.
        </h3>
      </div>
      <shared-kulturpass-order-item
        class="border-b border-solid border-[#EFF1F5]"
        *ngFor="let item of items$ | async; trackBy: trackItemById"
        [item]="item"
      >
      </shared-kulturpass-order-item>
    </div>
    <div class="flex flex-row justify-evenly items-stretch border-t border-solid border-[#EFF1F5] py-3 px-4 -mx-4">
      <div class="grid grid-flow-row text-xl">
        <div class="grid grid-cols-2 gap-5">
          <div>Guthaben</div>
          <div class="font-bold text-right">{{ credit$ | async | currency: 'EUR' }}</div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>Summe</div>
          <div class="font-bold text-right">{{ total$ | async | currency: 'EUR' }}</div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>Restbetrag</div>
          <div class="font-bold text-right" [class.text-brand]="negativeBalance$ | async">{{ balance$ | async | currency: 'EUR' }}</div>
        </div>
      </div>
      <div class="grid items-end justify-between">
        <div *ngIf="negativeBalance$ | async" class="text-xl text-warning font-bold text-center">Der Betrag übersteigt ihr Guthaben</div>
        <button
          type="button"
          class="bg-brand text-white px-6 py-3 font-bold rounded-full disabled:bg-disabled-branch disabled:text-active-branch"
          [disabled]="orderButtonDisabled$ | async"
          (click)="order()"
        >
          <shared-loader [loading]="ordering$ | async" hideContent="true"> Kauf abschließen und Rechnung drucken </shared-loader>
        </button>
      </div>
    </div>
  </div>
</shared-loader>
