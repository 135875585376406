import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FilterInput } from '../tree';

export interface FilterAutocomplete {
  /**
   * Anzeige / Bezeichner
   */
  display?: string;

  /**
   * Id
   */
  id?: string;

  /**
   * Abfragewert
   */
  query?: string;

  /**
   * Art (z.B. Titel, Autor, Verlag, ...)
   */
  type?: string;
}

@Injectable()
export abstract class FilterAutocompleteProvider {
  abstract readonly for: string;

  abstract complete(input: FilterInput): Observable<FilterAutocomplete[]>;
}
