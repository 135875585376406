import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';
import { UiModalConfig } from '@ui/modal';
import { Subject, BehaviorSubject } from 'rxjs';
import { UiModalResult } from './modal-result';

export class UiModalRef<TR = any, TD = any> {
  afterClosed$ = new Subject<UiModalResult<TR>>();
  afterChanged$ = new BehaviorSubject<boolean>(false);

  private _result: TR;
  get result(): TR {
    return this._result;
  }
  set result(value) {
    this._result = value;
  }

  constructor(
    public overlay: OverlayRef,
    public content: TemplateRef<any> | Type<any>,
    public title: string,
    public config: UiModalConfig,
    public data: TD,
  ) {}

  _close({ type, data }: { type: 'backdropClick' | 'close'; data: TR }): void {
    this.overlay.dispose();
    this.afterClosed$.next({
      type,
      data,
    });

    this.afterChanged$.complete();
    this.afterClosed$.complete();
  }

  close(data?: TR): void {
    this._close({ type: 'close', data });
  }

  markChanged() {
    this.afterChanged$.next(true);
  }
}
