import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'shared-input-control-error',
  template: `<ng-template #template>
    <div class="shared-input-control-error">
      <ng-content></ng-content>
    </div>
  </ng-template>`,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ErrorComponent {
  @Input() error: string;

  @ViewChild('template', { static: true }) tempalteRef: TemplateRef<any>;

  constructor() {}
}
