import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SearchboxComponent } from './searchbox.component';
import { UiIconModule } from '@ui/icon';
import { FormsModule } from '@angular/forms';
import { IconComponent } from '@shared/components/icon';

@NgModule({
  imports: [CommonModule, UiIconModule, IconComponent, FormsModule],
  exports: [SearchboxComponent],
  declarations: [SearchboxComponent],
})
export class SearchboxModule {}
