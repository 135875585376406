export { ResponseArgsOfSDVFilialdatenDTO } from './models/response-args-of-sdvfilialdaten-dto';
export { SDVFilialdatenDTO } from './models/sdvfilialdaten-dto';
export { ResponseArgs } from './models/response-args';
export { DialogOfString } from './models/dialog-of-string';
export { DialogSettings } from './models/dialog-settings';
export { DialogContentType } from './models/dialog-content-type';
export { KeyValueDTOOfStringAndString } from './models/key-value-dtoof-string-and-string';
export { ProblemDetails } from './models/problem-details';
export { ListResponseArgsOfPackageDTO } from './models/list-response-args-of-package-dto';
export { ResponseArgsOfIEnumerableOfPackageDTO } from './models/response-args-of-ienumerable-of-package-dto';
export { PackageDTO } from './models/package-dto';
export { EntityDTOContainerOfUserDTO } from './models/entity-dtocontainer-of-user-dto';
export { UserDTO } from './models/user-dto';
export { Gender } from './models/gender';
export { EntityDTOBaseOfUserDTOAndIUser } from './models/entity-dtobase-of-user-dtoand-iuser';
export { EntityDTOBase } from './models/entity-dtobase';
export { EntityDTO } from './models/entity-dto';
export { EntityStatus } from './models/entity-status';
export { CRUDA } from './models/cruda';
export { TouchedBase } from './models/touched-base';
export { EntityDTOReferenceContainer } from './models/entity-dtoreference-container';
export { ExternalReferenceDTO } from './models/external-reference-dto';
export { EntityDTOContainerOfLabelDTO } from './models/entity-dtocontainer-of-label-dto';
export { LabelDTO } from './models/label-dto';
export { EntityDTOBaseOfLabelDTOAndILabel } from './models/entity-dtobase-of-label-dtoand-ilabel';
export { WeightOfAvoirdupois } from './models/weight-of-avoirdupois';
export { Avoirdupois } from './models/avoirdupois';
export { AddresseeDTO } from './models/addressee-dto';
export { OrganisationDTO } from './models/organisation-dto';
export { OrganisationNamesDTO } from './models/organisation-names-dto';
export { PersonNamesDTO } from './models/person-names-dto';
export { AddressDTO } from './models/address-dto';
export { GeoLocation } from './models/geo-location';
export { CommunicationDetailsDTO } from './models/communication-details-dto';
export { EntityDTOBaseOfPackageDTOAndIPackage } from './models/entity-dtobase-of-package-dtoand-ipackage';
export { ResponseArgsOfPackageDTO } from './models/response-args-of-package-dto';
export { ResponseArgsOfNullableBoolean } from './models/response-args-of-nullable-boolean';
export { ListResponseArgsOfReturnItemDTO } from './models/list-response-args-of-return-item-dto';
export { ResponseArgsOfIEnumerableOfReturnItemDTO } from './models/response-args-of-ienumerable-of-return-item-dto';
export { ReturnItemDTO } from './models/return-item-dto';
export { EntityDTOContainerOfStockDTO } from './models/entity-dtocontainer-of-stock-dto';
export { StockDTO } from './models/stock-dto';
export { EntityDTOContainerOfBranchDTO } from './models/entity-dtocontainer-of-branch-dto';
export { BranchDTO } from './models/branch-dto';
export { BranchType } from './models/branch-type';
export { EntityDTOBaseOfBranchDTOAndIBranch } from './models/entity-dtobase-of-branch-dtoand-ibranch';
export { EntityDTOBaseOfStockDTOAndIStock } from './models/entity-dtobase-of-stock-dtoand-istock';
export { EntityDTOContainerOfStockCompartmentDTO } from './models/entity-dtocontainer-of-stock-compartment-dto';
export { StockCompartmentDTO } from './models/stock-compartment-dto';
export { SizeOfString } from './models/size-of-string';
export { EntityDTOBaseOfStockCompartmentDTOAndIStockCompartment } from './models/entity-dtobase-of-stock-compartment-dtoand-istock-compartment';
export { EntityDTOContainerOfStockItemDTO } from './models/entity-dtocontainer-of-stock-item-dto';
export { StockItemDTO } from './models/stock-item-dto';
export { EntityDTOContainerOfItemDTO } from './models/entity-dtocontainer-of-item-dto';
export { ItemDTO } from './models/item-dto';
export { ProductDTO } from './models/product-dto';
export { QuantityUnitType } from './models/quantity-unit-type';
export { EntityDTOContainerOfTenantDTO } from './models/entity-dtocontainer-of-tenant-dto';
export { TenantDTO } from './models/tenant-dto';
export { EntityDTOBaseOfTenantDTOAndITenant } from './models/entity-dtobase-of-tenant-dtoand-itenant';
export { EntityDTOBaseOfItemDTOAndIItem } from './models/entity-dtobase-of-item-dtoand-iitem';
export { EntityDTOBaseOfStockItemDTOAndIStockItem } from './models/entity-dtobase-of-stock-item-dtoand-istock-item';
export { PriceDTO } from './models/price-dto';
export { PriceValueDTO } from './models/price-value-dto';
export { VATValueDTO } from './models/vatvalue-dto';
export { VATType } from './models/vattype';
export { EntityDTOContainerOfSupplierDTO } from './models/entity-dtocontainer-of-supplier-dto';
export { SupplierDTO } from './models/supplier-dto';
export { SupplierType } from './models/supplier-type';
export { EntityDTOBaseOfSupplierDTOAndISupplier } from './models/entity-dtobase-of-supplier-dtoand-isupplier';
export { EntityDTOContainerOfStockEntryDTO } from './models/entity-dtocontainer-of-stock-entry-dto';
export { StockEntryDTO } from './models/stock-entry-dto';
export { StockEntryType } from './models/stock-entry-type';
export { EntityDTOContainerOfStockOrderItemDTO } from './models/entity-dtocontainer-of-stock-order-item-dto';
export { StockOrderItemDTO } from './models/stock-order-item-dto';
export { EntityDTOContainerOfStockOrderDTO } from './models/entity-dtocontainer-of-stock-order-dto';
export { StockOrderDTO } from './models/stock-order-dto';
export { EntityDTOBaseOfStockOrderDTOAndIStockOrder } from './models/entity-dtobase-of-stock-order-dtoand-istock-order';
export { EntityDTOContainerOfSupplierStatusCodeDTO } from './models/entity-dtocontainer-of-supplier-status-code-dto';
export { SupplierStatusCodeDTO } from './models/supplier-status-code-dto';
export { AvailabilityType } from './models/availability-type';
export { EntityDTOBaseOfSupplierStatusCodeDTOAndIStockStatusCode } from './models/entity-dtobase-of-supplier-status-code-dtoand-istock-status-code';
export { EntityDTOContainerOfStockOrderItemStatusDTO } from './models/entity-dtocontainer-of-stock-order-item-status-dto';
export { StockOrderItemStatusDTO } from './models/stock-order-item-status-dto';
export { StockOrderItemProcessingStatus } from './models/stock-order-item-processing-status';
export { EntityDTOBaseOfStockOrderItemStatusDTOAndIStockOrderItemStatus } from './models/entity-dtobase-of-stock-order-item-status-dtoand-istock-order-item-status';
export { EntityDTOBaseOfStockOrderItemDTOAndIStockOrderItem } from './models/entity-dtobase-of-stock-order-item-dtoand-istock-order-item';
export { EntityDTOContainerOfStockReservationItemDTO } from './models/entity-dtocontainer-of-stock-reservation-item-dto';
export { StockReservationItemDTO } from './models/stock-reservation-item-dto';
export { EntityDTOContainerOfStockReservationDTO } from './models/entity-dtocontainer-of-stock-reservation-dto';
export { StockReservationDTO } from './models/stock-reservation-dto';
export { EntityDTOBaseOfStockReservationDTOAndIStockReservation } from './models/entity-dtobase-of-stock-reservation-dtoand-istock-reservation';
export { EntityDTOContainerOfStockReservationItemStatusDTO } from './models/entity-dtocontainer-of-stock-reservation-item-status-dto';
export { StockReservationItemStatusDTO } from './models/stock-reservation-item-status-dto';
export { StockReservationItemProcessingStatus } from './models/stock-reservation-item-processing-status';
export { EntityDTOBaseOfStockReservationItemStatusDTOAndIStockReservationItemStatus } from './models/entity-dtobase-of-stock-reservation-item-status-dtoand-istock-reservation-item-status';
export { EntityDTOBaseOfStockReservationItemDTOAndIStockReservationItem } from './models/entity-dtobase-of-stock-reservation-item-dtoand-istock-reservation-item';
export { EntityDTOBaseOfStockEntryDTOAndIStockEntry } from './models/entity-dtobase-of-stock-entry-dtoand-istock-entry';
export { EntityDTOContainerOfReturnDTO } from './models/entity-dtocontainer-of-return-dto';
export { ReturnDTO } from './models/return-dto';
export { EntityDTOContainerOfReceiptDTO } from './models/entity-dtocontainer-of-receipt-dto';
export { ReceiptDTO } from './models/receipt-dto';
export { StockReceiptType } from './models/stock-receipt-type';
export { EntityDTOContainerOfReceiptItemDTO } from './models/entity-dtocontainer-of-receipt-item-dto';
export { ReceiptItemDTO } from './models/receipt-item-dto';
export { EntityDTOContainerOfPackageDTO } from './models/entity-dtocontainer-of-package-dto';
export { EntityDTOBaseOfReceiptItemDTOAndIStockReceiptItem } from './models/entity-dtobase-of-receipt-item-dtoand-istock-receipt-item';
export { EntityDTOBaseOfReceiptDTOAndIStockReceipt } from './models/entity-dtobase-of-receipt-dtoand-istock-receipt';
export { EntityDTOBaseOfReturnDTOAndIReturn } from './models/entity-dtobase-of-return-dtoand-ireturn';
export { EntityDTOContainerOfReturnItemDTO } from './models/entity-dtocontainer-of-return-item-dto';
export { ImpedimentDTO } from './models/impediment-dto';
export { EntityDTOBaseOfReturnItemDTOAndIReturnItem } from './models/entity-dtobase-of-return-item-dtoand-ireturn-item';
export { RemiQueryTokenDTO } from './models/remi-query-token-dto';
export { QueryTokenDTO } from './models/query-token-dto';
export { OrderByDTO } from './models/order-by-dto';
export { BatchResponseArgsOfReturnItemDTOAndReturnItemDTO } from './models/batch-response-args-of-return-item-dtoand-return-item-dto';
export { KeyValuePairOfReturnItemDTOAndReturnItemDTO } from './models/key-value-pair-of-return-item-dtoand-return-item-dto';
export { ReturnValueOfReturnItemDTO } from './models/return-value-of-return-item-dto';
export { ReturnValue } from './models/return-value';
export { KeyValuePairOfReturnItemDTOAndInteger } from './models/key-value-pair-of-return-item-dtoand-integer';
export { ResponseArgsOfInteger } from './models/response-args-of-integer';
export { ListResponseArgsOfReturnSuggestionDTO } from './models/list-response-args-of-return-suggestion-dto';
export { ResponseArgsOfIEnumerableOfReturnSuggestionDTO } from './models/response-args-of-ienumerable-of-return-suggestion-dto';
export { ReturnSuggestionDTO } from './models/return-suggestion-dto';
export { EntityDTOBaseOfReturnSuggestionDTOAndIReturnSuggestion } from './models/entity-dtobase-of-return-suggestion-dtoand-ireturn-suggestion';
export { BatchResponseArgsOfReturnSuggestionDTOAndReturnSuggestionDTO } from './models/batch-response-args-of-return-suggestion-dtoand-return-suggestion-dto';
export { KeyValuePairOfReturnSuggestionDTOAndReturnSuggestionDTO } from './models/key-value-pair-of-return-suggestion-dtoand-return-suggestion-dto';
export { ReturnValueOfReturnSuggestionDTO } from './models/return-value-of-return-suggestion-dto';
export { KeyValuePairOfReturnSuggestionDTOAndInteger } from './models/key-value-pair-of-return-suggestion-dtoand-integer';
export { ResponseArgsOfQuerySettingsDTO } from './models/response-args-of-query-settings-dto';
export { QuerySettingsDTO } from './models/query-settings-dto';
export { InputGroupDTO } from './models/input-group-dto';
export { InputDTO } from './models/input-dto';
export { InputType } from './models/input-type';
export { InputOptionsDTO } from './models/input-options-dto';
export { OptionDTO } from './models/option-dto';
export { ResponseArgsOfIEnumerableOfInputDTO } from './models/response-args-of-ienumerable-of-input-dto';
export { ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString } from './models/response-args-of-ienumerable-of-key-value-dtoof-string-and-string';
export { ResponseArgsOfIEnumerableOfValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString } from './models/response-args-of-ienumerable-of-value-tuple-of-string-and-integer-and-integer-and-nullable-integer-and-string';
export { ValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString } from './models/value-tuple-of-string-and-integer-and-integer-and-nullable-integer-and-string';
export { CapacityRequest } from './models/capacity-request';
export { ResponseArgsOfReturnItemDTO } from './models/response-args-of-return-item-dto';
export { ResponseArgsOfReceiptDTO } from './models/response-args-of-receipt-dto';
export { ReceiptFinalizeValues } from './models/receipt-finalize-values';
export { ResponseArgsOfReturnDTO } from './models/response-args-of-return-dto';
export { ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTO } from './models/response-args-of-value-tuple-of-receipt-item-dtoand-return-item-dto';
export { ValueTupleOfReceiptItemDTOAndReturnItemDTO } from './models/value-tuple-of-receipt-item-dtoand-return-item-dto';
export { ReturnItemValues } from './models/return-item-values';
export { ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnSuggestionDTO } from './models/response-args-of-value-tuple-of-receipt-item-dtoand-return-suggestion-dto';
export { ValueTupleOfReceiptItemDTOAndReturnSuggestionDTO } from './models/value-tuple-of-receipt-item-dtoand-return-suggestion-dto';
export { ReturnSuggestionValues } from './models/return-suggestion-values';
export { ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO } from './models/response-args-of-value-tuple-of-receipt-item-dtoand-return-item-dtoand-return-suggestion-dtoand-return-suggestion-dto';
export { ValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO } from './models/value-tuple-of-receipt-item-dtoand-return-item-dtoand-return-suggestion-dtoand-return-suggestion-dto';
export { ResponseArgsOfIEnumerableOfReturnDTO } from './models/response-args-of-ienumerable-of-return-dto';
export { ImpedimentValues } from './models/impediment-values';
export { ResponseArgsOfReturnSuggestionDTO } from './models/response-args-of-return-suggestion-dto';
export { ListResponseArgsOfReturnDTO } from './models/list-response-args-of-return-dto';
export { ReturnQueryTokenDTO } from './models/return-query-token-dto';
export { ResponseArgsOfIEnumerableOfReceiptDTO } from './models/response-args-of-ienumerable-of-receipt-dto';
export { ResponseArgsOfReceiptItemDTO } from './models/response-args-of-receipt-item-dto';
export { ResponseArgsOfIEnumerableOfStockInfoDTO } from './models/response-args-of-ienumerable-of-stock-info-dto';
export { StockInfoDTO } from './models/stock-info-dto';
export { StockStatus } from './models/stock-status';
export { StocksRequestValues } from './models/stocks-request-values';
export { StockRequestValues } from './models/stock-request-values';
export { ResponseArgsOfIEnumerableOfReturnInfoDTO } from './models/response-args-of-ienumerable-of-return-info-dto';
export { ReturnInfoDTO } from './models/return-info-dto';
export { ResponseArgsOfDBHBranchExtensionDTO } from './models/response-args-of-dbhbranch-extension-dto';
export { DBHBranchExtensionDTO } from './models/dbhbranch-extension-dto';
export { BranchDTO2 } from './models/branch-dto2';
export { QuadrupelDTO } from './models/quadrupel-dto';
export { ResponseArgsOfBranchDTO } from './models/response-args-of-branch-dto';
export { ResponseArgsOfStockDTO } from './models/response-args-of-stock-dto';
export { ResponseArgsOfIEnumerableOfStockDTO } from './models/response-args-of-ienumerable-of-stock-dto';
export { ResponseArgsOfIEnumerableOfStockEntryDTO } from './models/response-args-of-ienumerable-of-stock-entry-dto';
export { ResponseArgsOfIEnumerableOfStockItemDTO } from './models/response-args-of-ienumerable-of-stock-item-dto';
export { ResponseArgsOfIEnumerableOfStockOrderDTO } from './models/response-args-of-ienumerable-of-stock-order-dto';
export { ResponseArgsOfIEnumerableOfStockReservationDTO } from './models/response-args-of-ienumerable-of-stock-reservation-dto';
export { ListResponseArgsOfSupplierDTO } from './models/list-response-args-of-supplier-dto';
export { ResponseArgsOfIEnumerableOfSupplierDTO } from './models/response-args-of-ienumerable-of-supplier-dto';
