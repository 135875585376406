import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { CrmCustomerService } from '@domain/crm';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'country',
  standalone: true,
  pure: false,
})
export class CountryPipe implements PipeTransform, OnDestroy {
  private result: string;
  private value$ = new BehaviorSubject<string>(undefined);

  countries$ = this.customerService.getCountries().pipe(map((res) => res.result));

  subscriptions = combineLatest([this.value$, this.countries$]).subscribe(([value, countries]) => {
    if (!!value && countries?.length > 0) {
      const country = countries.find((c) => c.isO3166_A_3 === value);

      if (country && this.result !== country.name) {
        this.result = String(country.name);
        this.cdr.markForCheck();
      }
    }
  });

  constructor(
    private customerService: CrmCustomerService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  transform(value: string): any {
    if (this.value$.value !== value) {
      this.value$.next(value);
    }
    return this.result;
  }
}
