import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProcessIdResolver {
  resolve(route: ActivatedRouteSnapshot): Observable<number> | Promise<number> | number {
    return route.params.processId;
  }
}

