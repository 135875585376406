import { AbstractControl } from '@angular/forms';
import { DE_DATE_REGEX, ISO_DATE_REGEX } from '../regex';
import moment from 'moment';

export function DateValidator(control: AbstractControl) {
  if (control.value) {
    let date: Date = null;

    if (control.value instanceof Date) {
      date = control.value;
    } else if (typeof control.value === 'string') {
      date = new Date(control.value);

      // 06.09.2023 Nino Righi --- Code Auskommentiert und abgeändert, da nicht richtig funktioniert -> Bugticket #4255 HSC // Neue Filteroption - Erscheinungsdatum

      // if (DE_DATE_REGEX.test(control.value)) {
      //   date = moment(control.value, 'L').toDate();
      // } else if (ISO_DATE_REGEX.test(control.value)) {
      //   date = new Date(control.value);
      // }
    }

    if (date?.toString() === 'Invalid Date' || date === null) {
      return { date: 'Datum ist ungültig' };
    }
  }

  return null;
}
