/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfVATDTO } from '../models/list-response-args-of-vatdto';
import { ResponseArgsOfVATDTO } from '../models/response-args-of-vatdto';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutVATService extends __BaseService {
  static readonly StoreCheckoutVATGetVATsPath = '/store/checkout/vat';
  static readonly StoreCheckoutVATGetVATPath = '/store/checkout/vat/{vatId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutVATService.StoreCheckoutVATGetVATsParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  StoreCheckoutVATGetVATsResponse(
    params: StoreCheckoutVATService.StoreCheckoutVATGetVATsParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfVATDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/store/checkout/vat`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfVATDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutVATService.StoreCheckoutVATGetVATsParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  StoreCheckoutVATGetVATs(params: StoreCheckoutVATService.StoreCheckoutVATGetVATsParams): __Observable<ListResponseArgsOfVATDTO> {
    return this.StoreCheckoutVATGetVATsResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfVATDTO));
  }

  /**
   * @param vatId undefined
   */
  StoreCheckoutVATGetVATResponse(vatId: number): __Observable<__StrictHttpResponse<ResponseArgsOfVATDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/store/checkout/vat/${encodeURIComponent(String(vatId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfVATDTO>;
      }),
    );
  }
  /**
   * @param vatId undefined
   */
  StoreCheckoutVATGetVAT(vatId: number): __Observable<ResponseArgsOfVATDTO> {
    return this.StoreCheckoutVATGetVATResponse(vatId).pipe(__map((_r) => _r.body as ResponseArgsOfVATDTO));
  }
}

module StoreCheckoutVATService {
  /**
   * Parameters for StoreCheckoutVATGetVATs
   */
  export interface StoreCheckoutVATGetVATsParams {
    take?: null | number;
    skip?: null | number;
  }
}

export { StoreCheckoutVATService };
