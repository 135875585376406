import { DestroyRef, inject, Injectable, InjectOptions, Provider } from '@angular/core';
import { fromEvent, Subject, Subscription } from 'rxjs';

@Injectable()
export class CancelSearchSubject extends Subject<void> {
  private readonly subscription = new Subscription();
  private readonly destroyRef = inject(DestroyRef);

  constructor() {
    super();

    this.subscription.add(
      fromEvent(document, 'keydown').subscribe((event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          this.next();
        }
      }),
    );

    this.destroyRef.onDestroy(() => this.destroy());
  }

  private destroy(): void {
    this.subscription.unsubscribe();
    this.complete();
  }

  emit() {
    this.next();
  }
}

export function provideCancelSearchSubject(): Provider[] {
  return [CancelSearchSubject];
}

export const injectCancelSearch = (injectOptions?: InjectOptions) => {
  const cancelSearch = inject(CancelSearchSubject, injectOptions);
  return () => {
    cancelSearch?.emit();
  };
};

export const injectCancelSearchSubject = (injectOptions?: InjectOptions) => inject(CancelSearchSubject, injectOptions);
