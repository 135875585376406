import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationProcess, ApplicationService } from '@core/application';
import { BreadcrumbService } from '@core/breadcrumb';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CanActivateGoodsOutWithProcessIdGuard {
  constructor(
    private readonly _applicationService: ApplicationService,
    private readonly _breadcrumbService: BreadcrumbService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const process = await this._applicationService.getProcessById$(+route.params.processId).pipe(first()).toPromise();

    if (!process) {
      // const processes = await this._applicationService.getProcesses$('customer').pipe(first()).toPromise();
      await this._applicationService.createProcess({
        id: +route.params.processId,
        type: 'goods-out',
        section: 'customer',
        name: `Warenausgabe`,
      });
    }

    await this.removeBreadcrumbWithSameProcessId(route);
    this._applicationService.activateProcess(+route.params.processId);
    return true;
  }

  // Fix #3292: Alle Breadcrumbs die nichts mit dem aktuellen Prozess zu tun haben, müssen removed werden
  async removeBreadcrumbWithSameProcessId(route: ActivatedRouteSnapshot) {
    const crumbs = await this._breadcrumbService.getBreadcrumbByKey$(+route.params.processId).pipe(first()).toPromise();

    // Entferne alle Crumbs die nichts mit der Warenausgabe zu tun haben
    if (crumbs.length > 1) {
      const crumbsToRemove = crumbs.filter((crumb) => crumb.tags.find((tag) => tag === 'goods-out') === undefined);
      for (const crumb of crumbsToRemove) {
        await this._breadcrumbService.removeBreadcrumb(crumb.id);
      }
    }
  }

  processNumber(processes: ApplicationProcess[]) {
    const processNumbers = processes?.map((process) => Number(process?.name?.replace(/\D/g, '')));
    return !!processNumbers && processNumbers?.length > 0 ? Math.max(...processNumbers) + 1 : 1;
  }
}
