import { ActionHandler } from '@core/command';
import { DomainOmsService } from '../oms.service';
import { OrderItemListItemDTO, StatusValues } from '@swagger/oms';
import { OrderItemsContext } from './order-items.context';

export abstract class ChangeOrderItemStatusBaseActionHandler extends ActionHandler<OrderItemsContext> {
  constructor(
    protected domainOmsService: DomainOmsService,
    protected actionName: string,
  ) {
    super(actionName);
  }

  async handler(context: OrderItemsContext): Promise<OrderItemsContext> {
    const updatedItems: OrderItemListItemDTO[] = [];
    for (const item of context.items) {
      const response = await this.domainOmsService
        .changeOrderItemStatus({
          orderId: item.orderId,
          orderItemId: item.orderItemId,
          orderItemSubsetId: item.orderItemSubsetId,
          data: this.getStatusValues(item, context),
        })
        .toPromise();

      const resItem = response.result.item1;

      context = { ...context, compartmentCode: resItem.compartmentCode };
      updatedItems.push({
        ...item,
        compartmentCode: resItem.compartmentCode,
        compartmentInfo: resItem.compartmentInfo,
        estimatedShippingDate: resItem.estimatedShippingDate,
        isPrebooked: resItem.isPrebooked,
        processingStatus: resItem.processingStatus,
        processingStatusDate: resItem.processingStatusDate,
        quantity: resItem.quantity,
        specialComment: resItem.specialComment,
        ssc: resItem.ssc,
        sscText: resItem.sscText,
      });
    }

    return { ...context, items: updatedItems };
  }

  abstract getStatusValues(orderItem: OrderItemListItemDTO, context: OrderItemsContext): StatusValues;
}
