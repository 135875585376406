import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomerDTO } from '@swagger/crm';
import { AddressFormBlockData } from '../../components/form-blocks';
import { NameFormBlockData } from '../../components/form-blocks/name/name-form-block-data';
import { OrganisationFormBlockData } from '../../components/form-blocks/organisation/organisation-form-block-data';
import { validateEmail } from '../../validators/email-validator';
import { AbstractCreateCustomer } from '../abstract-create-customer';
import { zipCodeValidator } from '../../validators/zip-code-validator';

@Component({
  selector: 'app-create-b2b-customer',
  templateUrl: 'create-b2b-customer.component.html',
  styleUrls: ['../create-customer.scss', 'create-b2b-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateB2BCustomerComponent extends AbstractCreateCustomer {
  customerType = 'b2b';

  validateAddress = true;

  validateShippingAddress = true;

  organisationFormBlockRequiredMarks: (keyof OrganisationFormBlockData)[] = ['name'];

  organisationFormBlockValidators: Record<string, ValidatorFn[]> = {
    name: [Validators.required],
  };

  addressRequiredMarks: (keyof AddressFormBlockData)[] = ['street', 'streetNumber', 'zipCode', 'city', 'country'];

  addressValidators: Record<string, ValidatorFn[]> = {
    street: [Validators.required],
    streetNumber: [Validators.required],
    zipCode: [Validators.required, zipCodeValidator()],
    city: [Validators.required],
    country: [Validators.required],
  };

  emailFormBlockValidators = [Validators.email, validateEmail];

  deviatingNameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  deviatingNameValidationFns: Record<string, ValidatorFn[]> = {
    gender: [Validators.required],
    firstName: [Validators.required],
    lastName: [Validators.required],
  };

  async saveCustomer(customer: CustomerDTO): Promise<CustomerDTO> {
    const res = await this.customerService.createB2BCustomer(customer);

    return res.result;
  }
}
