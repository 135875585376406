/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { WwsConfiguration as __Configuration } from '../wws-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ListResponseArgsOfPackageDTO2 } from '../models/list-response-args-of-package-dto2';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfPackageDetailResponseDTO } from '../models/response-args-of-package-detail-response-dto';
import { ResponseArgsOfPackageArrivalStatusDTO } from '../models/response-args-of-package-arrival-status-dto';
import { PackageArrivalStatusDTO } from '../models/package-arrival-status-dto';
@Injectable({
  providedIn: 'root',
})
class WareneingangService extends __BaseService {
  static readonly WareneingangQueryPackagesSettingsPath = '/wareneingang/packstuecke/s/settings';
  static readonly WareneingangQueryPackagesPath = '/wareneingang/packstuecke/s';
  static readonly WareneingangGetPackageDetailsPath = '/wareneingang/packstuecke/{packageId}';
  static readonly WareneingangChangePackageStatusPath = '/wareneingang/packstuecke/{packageId}/status/{modifier}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Settings
   */
  WareneingangQueryPackagesSettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/wareneingang/packstuecke/s/settings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      }),
    );
  }
  /**
   * Settings
   */
  WareneingangQueryPackagesSettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.WareneingangQueryPackagesSettingsResponse().pipe(__map((_r) => _r.body as ResponseArgsOfQuerySettingsDTO));
  }

  /**
   * Packstücke abfragen
   * @param queryToken undefined
   */
  WareneingangQueryPackagesResponse(queryToken: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfPackageDTO2>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/wareneingang/packstuecke/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfPackageDTO2>;
      }),
    );
  }
  /**
   * Packstücke abfragen
   * @param queryToken undefined
   */
  WareneingangQueryPackages(queryToken: QueryTokenDTO): __Observable<ListResponseArgsOfPackageDTO2> {
    return this.WareneingangQueryPackagesResponse(queryToken).pipe(__map((_r) => _r.body as ListResponseArgsOfPackageDTO2));
  }

  /**
   * Packstück-Details
   * @param packageId undefined
   */
  WareneingangGetPackageDetailsResponse(packageId: string): __Observable<__StrictHttpResponse<ResponseArgsOfPackageDetailResponseDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/wareneingang/packstuecke/${encodeURIComponent(String(packageId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPackageDetailResponseDTO>;
      }),
    );
  }
  /**
   * Packstück-Details
   * @param packageId undefined
   */
  WareneingangGetPackageDetails(packageId: string): __Observable<ResponseArgsOfPackageDetailResponseDTO> {
    return this.WareneingangGetPackageDetailsResponse(packageId).pipe(__map((_r) => _r.body as ResponseArgsOfPackageDetailResponseDTO));
  }

  /**
   * Packstückstatus ändern
   * @param params The `WareneingangService.WareneingangChangePackageStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `packageId`:
   *
   * - `modifier`:
   */
  WareneingangChangePackageStatusResponse(
    params: WareneingangService.WareneingangChangePackageStatusParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfPackageArrivalStatusDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/wareneingang/packstuecke/${encodeURIComponent(String(params.packageId))}/status/${encodeURIComponent(String(params.modifier))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPackageArrivalStatusDTO>;
      }),
    );
  }
  /**
   * Packstückstatus ändern
   * @param params The `WareneingangService.WareneingangChangePackageStatusParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `packageId`:
   *
   * - `modifier`:
   */
  WareneingangChangePackageStatus(
    params: WareneingangService.WareneingangChangePackageStatusParams,
  ): __Observable<ResponseArgsOfPackageArrivalStatusDTO> {
    return this.WareneingangChangePackageStatusResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfPackageArrivalStatusDTO));
  }
}

module WareneingangService {
  /**
   * Parameters for WareneingangChangePackageStatus
   */
  export interface WareneingangChangePackageStatusParams {
    payload: PackageArrivalStatusDTO;
    packageId: string;
    modifier: string;
  }
}

export { WareneingangService };
