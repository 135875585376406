<div class="flex items-start justify-between">
  <div
    class="isa-label font-bold grid grid-flow-col gap-2 items-center"
    [style.color]="customerLabelTextColor[label?.description]"
    [style.backgroundColor]="customerLabelColor[label?.description]"
  >
    <shared-icon [icon]="label?.description" [size]="20"></shared-icon>
    <span> {{ label?.description }}</span>
  </div>
  <div class="mr-20">
    {{ customer?.created | date: 'dd.MM.yy' }}
  </div>
</div>

<div class="grid grid-cols-2 gap-4">
  <span class="text-lg font-bold"> {{ customer | customerName }} </span>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-row items-center">
        <span class="w-32">PLZ und Ort</span>
        <span class="font-bold grow-1">{{ customer?.address?.zipCode }} {{ customer?.address?.city }}</span>
      </div>
      <div class="flex flex-row items-center">
        <span class="w-32">E-Mail</span>
        <span class="font-bold grow-1">{{ customer?.communicationDetails?.email }}</span>
      </div>
    </div>
  </div>
</div>
