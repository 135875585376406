import { Subject } from 'rxjs';
import { InputOptions } from './input-options';
import { InputType } from './input-type.enum';

export interface IOption {
  description?: string;
  enabled?: boolean;
  key?: string;
  label?: string;
  maxValue?: string;
  minValue?: string;
  placeholder?: string;
  selected?: boolean;
  value?: string;
  values?: Array<IOption>;
  expanded?: boolean;
}

export class Option implements IOption {
  //#region implements IUiFilterOptionDTO
  private _description?: string;
  get description() {
    return this._description;
  }

  private _enabled?: boolean;
  get enabled() {
    return this._enabled;
  }

  private _key?: string;
  get key() {
    return this._key;
  }

  private _label?: string;
  get label() {
    return this._label;
  }

  private _maxValue?: string;
  get maxValue() {
    return this._maxValue;
  }

  private _minValue?: string;
  get minValue() {
    return this._minValue;
  }

  private _placeholder?: string;
  get placeholder() {
    return this._placeholder;
  }

  private _selected?: boolean;
  get selected() {
    if (this.values?.length) {
      return this.values.filter((f) => f.selected).length === this.values.length;
    }

    if (this.getParentInput()?.type === InputType.DateRange) {
      return !!this.value;
    }

    return this._selected;
  }

  private _value?: string;
  get value() {
    return this._value;
  }

  private _values?: Array<Option>;
  get values() {
    return this._values;
  }

  private _expanded?: boolean;
  get expanded() {
    return this._expanded;
  }
  //#endregion

  readonly changes = new Subject<{ keys: (keyof IOption)[]; target: Option }>();

  constructor(public readonly parent?: Option | InputOptions) {}

  getParentInput() {
    return this.getParentInputOptions()?.parent;
  }

  getParentInputOptions() {
    let parent = this.parent;
    while (!!parent) {
      if (parent instanceof InputOptions) {
        return parent;
      }
      parent = parent.parent;
    }
  }

  hasChildren() {
    return !!this.values?.length;
  }

  getSelectedOptions(includeSelf: boolean = true): Option[] {
    const selected: Option[] = [];

    if (this.selected && includeSelf) {
      selected.push(this);
    }

    const selectedChildren = this.values?.map((f) => f.getSelectedOptions()).reduce((agg, options) => [...agg, ...options], []);

    if (selectedChildren?.length) {
      selected.push(...selectedChildren);
    }

    return selected;
  }

  getUnselectedOptions(): Option[] {
    if (this?.getParentInput()?.type === InputType.TriState && this.selected === false) {
      return [this];
    } else if (this.hasChildren()) {
      return this.values.map((f) => f.getUnselectedOptions()).reduce((agg, options) => [...agg, ...options], []);
    }

    return [];
  }

  setSelected(value: boolean, { emitEvent } = { emitEvent: true }) {
    this._selected = value;

    const type = this.getParentInput()?.type;
    if (!value && (type === InputType.DateRange || type === InputType.NumberRange || type === InputType.IntegerRange)) {
      this.setValue(undefined, { emitEvent: false });
    }

    this.values?.forEach((option) => option.setSelected(value));
    if (emitEvent) {
      this.changes.next({ keys: ['selected', 'value'], target: this });
    }
  }

  setExpanded(value: boolean, { emitEvent } = { emitEvent: true }) {
    if (this.expanded !== value) {
      this._expanded = value;
      if (emitEvent) {
        this.changes.next({ keys: ['expanded'], target: this });
      }
    }
  }

  setValue(value: string | Date, { emitEvent } = { emitEvent: true }) {
    if (value instanceof Date) {
      value = value?.toJSON();
    }

    if (this.value !== value) {
      this._value = value;

      this.setSelected(!!this.value, { emitEvent: false });

      if (emitEvent) {
        this.changes.next({ keys: ['value', 'selected'], target: this });
      }
    }
  }

  toStringValue() {
    switch (this.getParentInput()?.type) {
      case InputType.Bool:
      case InputType.InputSelector:
        return this.boolToStringValue();
    }
  }

  hasOptions() {
    return this._values?.length > 0 ?? false;
  }

  private boolToStringValue() {
    if (this.hasOptions()) {
      return this.getSelectedOptions(false)
        .map((options) => options.value ?? options.key)
        .join(';');
    }

    return this.selected ? String(this.value ?? this.key) : undefined;
  }

  trySetFromValue(value: string) {
    const type = this.getParentInput()?.type;

    switch (type) {
      case InputType.Bool:
      case InputType.InputSelector:
        this.trySetBoolFromValue(value);
        break;
      case InputType.TriState:
        this.trySetTriStateFromValue(value);
        break;
      case InputType.NumberRange:
      case InputType.IntegerRange:
        this.trySetFromNumberRange(value);
        break;
      case InputType.DateRange:
        this.trySetFromDateRange(value);
        break;
    }
  }

  trySetFromNumberRange(value: string) {
    const split = value?.split('-');
    if (this.key === 'start') {
      this.setValue(split[0]);
    } else if (this.key === 'stop') {
      this.setValue(split[1]);
    }
  }

  trySetFromDateRange(value: string) {
    let split: string[] = [];

    if (value) {
      if (value?.includes('"-"')) {
        split = value.split('"-"');
      } else if (value?.includes('-"')) {
        split = value.split('-"');
      } else if (value?.includes('"-')) {
        split = value.split('"-');
      }
    }

    if (this.key === 'start') {
      if (split[0]) {
        split[0] = split[0].replace(new RegExp('"', 'g'), '');
      }
      this.setValue(split[0]);
    } else if (this.key === 'stop') {
      if (split[1]) {
        split[1] = split[1].replace(new RegExp('"', 'g'), '');
      }
      this.setValue(split[1]);
    }
  }

  trySetBoolFromValue(value: string) {
    const valueSplits = value?.split(';');
    if (valueSplits.includes(this.value)) {
      this.setSelected(true);
    } else {
      this.setSelected(undefined);
      this.values?.forEach((option) => option?.trySetBoolFromValue(value));
    }
  }

  trySetTriStateFromValue(value: string) {
    const valueSplits = value?.split(';');
    if (valueSplits.includes(this.value)) {
      this.setSelected(true);
    } else if (valueSplits.includes(`!${this.value}`)) {
      this.setSelected(false);
    } else {
      this.setSelected(undefined);
    }
  }

  validate(): string | null {
    const input = this.getParentInput();

    switch (input?.type) {
      // NumberRange
      case InputType.NumberRange:
      case InputType.IntegerRange:
        return this.validateNumber() || this.validateNumberRange();
    }

    return null;
  }

  validateNumber(): string | null {
    if (!Number.isNaN(+this.value)) {
      if (!Number.isNaN(+this.minValue) && +this.value < +this.minValue) {
        return `Der Wert darf nicht kleiner als ${this.minValue} sein.`;
      } else if (!Number.isNaN(+this.maxValue) && +this.value > +this.maxValue) {
        return `Der Wert darf nicht größer als ${this.maxValue} sein.`;
      } else if (!!this.value && !/^[0-9]+$/.exec(this.value)) {
        return `Es werden nur ganzzahlige Werte akzeptiert.`;
      }
    }

    return null;
  }

  validateNumberRange(): string | null {
    if (!Number.isNaN(+this.value)) {
      const start = this.parent.values.find((v) => v.key === 'start');
      const stop = this.parent.values.find((v) => v.key === 'stop');

      if (!Number.isNaN(+start.value) && !Number.isNaN(+stop.value) && +start.value > +stop.value) {
        return `Der Wert "${start.value}" darf nicht gößer sein als "${stop.value}"`;
      }
    }

    return null;
  }

  toObject(): IOption {
    return {
      description: this.description,
      enabled: this.enabled,
      expanded: this.expanded,
      key: this.key,
      label: this.label,
      maxValue: this.maxValue,
      minValue: this.minValue,
      placeholder: this.placeholder,
      selected: this.selected,
      value: this.value,
      values: this.values?.map((value) => value.toObject()),
    };
  }

  static create(option: IOption, parent?: Option | InputOptions) {
    const target = new Option(parent);

    target._description = option?.description;
    target._enabled = option?.enabled;
    target._key = option?.key;
    target._label = option?.label;
    target._maxValue = option?.maxValue;
    target._minValue = option?.minValue;
    target._placeholder = option?.placeholder;
    target._selected = option?.selected;
    target._value = option?.value;
    target._values = option?.values?.map((value) => Option.create(value, target));
    target._expanded = option?.expanded;

    return target;
  }
}
