import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ShellService {
  private _fontSize$ = new BehaviorSubject<'small' | 'normal' | 'large'>('normal');

  fontSize$ = this._fontSize$.asObservable();

  get fontSize() {
    return this._fontSize$.value;
  }

  get scale() {
    if (this._fontSize$.value === 'small') {
      return 13 / 16;
    } else if (this._fontSize$.value === 'normal') {
      return 1;
    } else if (this._fontSize$.value === 'large') {
      return 19 / 16;
    } else {
      return 1;
    }
  }

  scale$ = this._fontSize$.pipe(
    map((size) => {
      if (size === 'small') {
        return 13 / 16;
      } else if (size === 'normal') {
        return 1;
      } else if (size === 'large') {
        return 19 / 16;
      } else {
        return 1;
      }
    }),
  );

  private _sideMenuOpen$ = new BehaviorSubject<boolean>(false);

  sideMenuOpen$ = this._sideMenuOpen$.asObservable();

  constructor(@Inject(DOCUMENT) private _document: Document) {
    this._fontSize$.next(this._getStoredFontSize());

    this._fontSize$.subscribe((size) => {
      if (size === 'small') {
        this._document.documentElement.style.setProperty('font-size', '13px');
      } else if (size === 'normal') {
        this._document.documentElement.style.setProperty('font-size', '16px');
      } else if (size === 'large') {
        this._document.documentElement.style.setProperty('font-size', '19px');
      }
    });
  }

  private _getStoredFontSize() {
    const stored = localStorage.getItem('isa-app-font-size');
    return stored ? (stored as 'small' | 'normal' | 'large') : 'normal';
  }

  setFontSize(size: 'small' | 'normal' | 'large') {
    this._fontSize$.next(size);
    localStorage.setItem('isa-app-font-size', size);
  }

  openSideMenu() {
    this._sideMenuOpen$.next(true);
  }

  closeSideMenu() {
    this._sideMenuOpen$.next(false);
  }

  toggleSideMenu() {
    this._sideMenuOpen$.next(!this._sideMenuOpen$.value);
  }
}
