<div class="customer-edit-header-actions flex flex-row justify-end pt-1 px-1">
  <a
    *ngIf="detailsRoute$ | async; let route"
    [routerLink]="route.path"
    [queryParams]="route.queryParams"
    [queryParamsHandling]="'merge'"
    class="btn btn-label"
  >
    <ui-icon [icon]="'close'"></ui-icon>
  </a>
</div>
<div class="header">
  <h1>Firmendetails</h1>
</div>

<form *ngIf="control" [formGroup]="control" (ngSubmit)="submit()">
  <ng-container formGroupName="organisation">
    <ui-form-control label="Firmenname" variant="inline">
      <input uiInput type="text" formControlName="name" tabindex="1" />
    </ui-form-control>
    <ui-form-control label="Abteilung" variant="inline">
      <input uiInput type="text" formControlName="department" tabindex="2" />
    </ui-form-control>
    <ui-form-control label="USt ID" variant="inline">
      <input uiInput type="text" formControlName="vatId" tabindex="3" />
    </ui-form-control>
  </ng-container>

  <ui-form-control [clearable]="true" label="Anrede" variant="inline">
    <ui-select formControlName="gender" tabindex="4">
      <ui-select-option *ngFor="let gender of genderSettings.genders" [value]="gender.value" [label]="gender.label"></ui-select-option>
    </ui-select>
  </ui-form-control>
  <ui-form-control [clearable]="true" label="Titel" variant="inline">
    <ui-select formControlName="title" tabindex="5">
      <ui-select-option value="Dipl.-Ing." label="Dipl.-Ing."></ui-select-option>
      <ui-select-option value="Dr." label="Dr."></ui-select-option>
      <ui-select-option value="Dr. med." label="Dr. med."></ui-select-option>
      <ui-select-option value="Prof." label="Prof."></ui-select-option>
      <ui-select-option value="Prof. Dr." label="Prof. Dr."></ui-select-option>
      <ui-select-option value="RA" label="RA"></ui-select-option>
    </ui-select>
  </ui-form-control>
  <ui-form-control label="Nachname" variant="inline">
    <input uiInput type="text" formControlName="lastName" tabindex="6" />
  </ui-form-control>
  <ui-form-control label="Vorname" variant="inline">
    <input uiInput type="text" formControlName="firstName" tabindex="7" />
  </ui-form-control>

  <ng-container formGroupName="communicationDetails">
    <ui-form-control label="E-Mail" variant="inline">
      <input uiInput type="mail" formControlName="email" tabindex="8" />
    </ui-form-control>

    <ui-form-control label="Festnetznummer" variant="inline">
      <input uiInput type="tel" formControlName="phone" tabindex="9" />
    </ui-form-control>
    <ui-form-control label="Mobilnummer" variant="inline">
      <input uiInput type="tel" formControlName="mobile" tabindex="10" />
    </ui-form-control>
  </ng-container>

  <ng-container formGroupName="address">
    <ui-form-control label="Straße" variant="inline">
      <input uiInput type="text" formControlName="street" tabindex="11" />
    </ui-form-control>
    <ui-form-control label="Hausnummer" variant="inline">
      <input uiInput type="text" formControlName="streetNumber" tabindex="12" />
    </ui-form-control>
    <ui-form-control label="PLZ" variant="inline">
      <input uiInput type="text" formControlName="zipCode" tabindex="13" />
    </ui-form-control>
    <ui-form-control label="Ort" variant="inline">
      <input uiInput type="text" formControlName="city" tabindex="14" />
    </ui-form-control>
    <ui-form-control label="Adresszusatz" variant="inline">
      <input uiInput type="text" formControlName="info" tabindex="15" />
    </ui-form-control>
    <ui-form-control [clearable]="true" label="Land" variant="inline">
      <ui-select formControlName="country" tabindex="16">
        <ui-select-option
          *ngFor="let country of countries$ | async"
          [label]="country.name"
          [value]="country.isO3166_A_3"
        ></ui-select-option>
      </ui-select>
    </ui-form-control>
  </ng-container>

  <div class="actions">
    <button class="btn-cancel" type="button" (click)="cancel()">Abbrechen</button>
    <button class="btn-save" type="submit" [disabled]="control.invalid || control.disabled" [ngSwitch]="control.enabled">
      <ng-container *ngSwitchCase="true"> Speichern </ng-container>
      <ui-icon class="spin" icon="loading" size="18px" *ngSwitchCase="false"></ui-icon>
    </button>
  </div>
</form>
