import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { AsyncValidatorFn, ValidatorFn, Validators } from '@angular/forms';
import { Result } from '@domain/defs';
import { CustomerDTO, KeyValueDTOOfStringAndString, PayerDTO } from '@swagger/crm';
import { AddressFormBlockData } from '../../components/form-blocks';
import { NameFormBlockData } from '../../components/form-blocks/name/name-form-block-data';
import { AbstractCreateCustomer } from '../abstract-create-customer';
import { CreateP4MCustomerComponent } from '../create-p4m-customer';

@Component({
  selector: 'page-update-p4m-webshop-customer',
  templateUrl: 'update-p4m-webshop-customer.component.html',
  styleUrls: ['../create-customer.scss', 'update-p4m-webshop-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdateP4MWebshopCustomerComponent extends AbstractCreateCustomer implements OnInit {
  customerType = 'webshop-p4m/update';

  validateAddress = true;

  validateShippingAddress = true;

  agbValidatorFns = [Validators.requiredTrue];

  birthDateValidatorFns = [Validators.required, this.minBirthDateValidator()];

  nameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  nameValidationFns: Record<keyof NameFormBlockData, ValidatorFn[]> = {
    firstName: [Validators.required],
    lastName: [Validators.required],
    gender: [Validators.required],
    title: [],
  };

  addressRequiredMarks: (keyof AddressFormBlockData)[] = ['street', 'streetNumber', 'zipCode', 'city', 'country'];

  addressValidatorFns: Record<string, ValidatorFn[]> = {
    street: [Validators.required],
    streetNumber: [Validators.required],
    zipCode: [Validators.required],
    city: [Validators.required],
    country: [Validators.required],
  };

  asyncLoyaltyCardValidatorFn: AsyncValidatorFn[] = [this.checkLoyalityCardValidator];

  get billingAddress(): PayerDTO | undefined {
    const payers = this.formData?._meta?.customerDto?.payers;

    if (!payers || payers.length === 0) {
      return undefined;
    }

    // the default payer is the payer with the latest isDefault(Date) value
    const defaultPayer = payers.reduce((prev, curr) => (new Date(prev.isDefault) > new Date(curr.isDefault) ? prev : curr));

    return defaultPayer.payer.data;
  }

  get shippingAddress() {
    const shippingAddresses = this.formData?._meta?.customerDto?.shippingAddresses;

    if (!shippingAddresses || shippingAddresses.length === 0) {
      return undefined;
    }

    // the default shipping address is the shipping address with the latest isDefault(Date) value
    const defaultShippingAddress = shippingAddresses.reduce((prev, curr) =>
      new Date(prev.data.isDefault) > new Date(curr.data.isDefault) ? prev : curr,
    );

    return defaultShippingAddress.data;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  getInterests(): KeyValueDTOOfStringAndString[] {
    const interests: KeyValueDTOOfStringAndString[] = [];

    for (const key in this.formData.interests) {
      if (this.formData.interests[key]) {
        interests.push({ key, group: 'KUBI_INTERESSEN' });
      }
    }

    return interests;
  }

  getNewsletter(): KeyValueDTOOfStringAndString | undefined {
    if (this.formData.newsletter) {
      return { key: 'kubi_newsletter', group: 'KUBI_NEWSLETTER' };
    }
  }

  async saveCustomer(customer: CustomerDTO): Promise<CustomerDTO> {
    let res: Result<CustomerDTO>;

    const { customerDto, customerInfoDto } = this.formData?._meta ?? {};

    if (customerDto) {
      customer = { ...customerDto, shippingAddresses: [], payers: [], ...customer };

      if (customerDto.shippingAddresses?.length) {
        customer.shippingAddresses.unshift(...customerDto.shippingAddresses);
      }
      if (customerDto.payers?.length) {
        customer.payers.unshift(...customerDto.payers);
      }
    } else if (customerInfoDto) {
      customer = { ...CreateP4MCustomerComponent.MapCustomerInfoDtoToCustomerDto(customerInfoDto), ...customer };
    }

    const p4mFeature = customer.features?.find((attr) => attr.key === 'p4mUser');
    if (p4mFeature) {
      p4mFeature.value = this.formData.p4m;
    } else {
      customer.features.push({
        key: 'p4mUser',
        value: this.formData.p4m,
      });
    }

    const interests = this.getInterests();

    if (interests.length > 0) {
      customer.features?.push(...interests);
      // TODO: Klärung wie Interessen zukünftig gespeichert werden
      // await this._loyaltyCardService
      //   .LoyaltyCardSaveInteressen({
      //     customerId: res.result.id,
      //     interessen: this.getInterests(),
      //   })
      //   .toPromise();
    }

    const newsletter = this.getNewsletter();

    if (newsletter) {
      customer.features.push(newsletter);
    } else {
      customer.features = customer.features.filter((feature) => feature.key !== 'kubi_newsletter' && feature.group !== 'KUBI_NEWSLETTER');
    }

    res = await this.customerService.updateToP4MOnlineCustomer(customer);

    return res.result;
  }
}
