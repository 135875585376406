import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FormBlockControl } from '../form-block';
import { ScanAdapterService } from '@adapter/scan';

@Component({
  selector: 'app-p4m-number-form-block',
  templateUrl: 'p4m-number-form-block.component.html',
  styleUrls: ['p4m-number-form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class P4mNumberFormBlockComponent extends FormBlockControl<string> {
  get tabIndexEnd() {
    return this.tabIndexStart;
  }

  constructor(
    private scanAdapter: ScanAdapterService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  updateValidators(): void {
    this.control.setValidators([...this.getValidatorFn()]);
    this.control.setAsyncValidators(this.getAsyncValidatorFn());
    this.control.updateValueAndValidity();
  }

  initializeControl(data?: string): void {
    this.control = new UntypedFormControl(data ?? '', [Validators.required], this.getAsyncValidatorFn());
  }

  _patchValue(update: { previous: string; current: string }): void {
    this.control.patchValue(update.current);
  }

  scan() {
    this.scanAdapter.scan().subscribe((result) => {
      this.control.patchValue(result);
      this.changeDetectorRef.markForCheck();
    });
  }

  canScan() {
    return this.scanAdapter.isReady();
  }
}
