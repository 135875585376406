import { inject } from '@angular/core';
import { UiModalRef, UiModalService } from '@ui/modal';
import { MessageModalData } from './message-modal.data';
import { MessageModalComponent } from './message-modal.component';

export const injectOpenMessageModal = () => {
  const modalService = inject(UiModalService);

  return (data: MessageModalData): UiModalRef<void, MessageModalComponent> =>
    modalService.open({ title: data.title, content: MessageModalComponent, data });
};
