<div class="wrapper text-center" [@cardFlip]="state" (@cardFlip.done)="flipAnimationDone($event)">
  <div *ngIf="cardDetails" class="card-main">
    <div class="icons text-brand">
      <button *ngIf="isCustomerCard && frontside" class="icon-barcode" (click)="flipCard()">
        <shared-icon [size]="35" icon="barcode-scanner"></shared-icon>
      </button>
      <button *ngIf="isCustomerCard && !frontside" class="icon-back" (click)="flipCard()">
        <shared-icon [size]="35" icon="refresh"></shared-icon>
      </button>
      <!-- <div *ngIf="!isCustomerCard" class="icon-delete"><ui-icon (click)="onDeletePartnerCard()" size="25px" icon="trash"></ui-icon></div> -->
    </div>
    <div class="headline">
      <p *ngIf="isCustomerCard && frontside">Ihre Lesepunkte</p>
      <p *ngIf="isCustomerCard && !frontside">Kartennummer</p>
      <p *ngIf="!isCustomerCard">Partnerkartennummer</p>
    </div>
    <div class="mt-2">
      <div *ngIf="!isCustomerCard || (isCustomerCard && !frontside)" class="card-number">{{ cardDetails.code }}</div>
      <div *ngIf="isCustomerCard && frontside" class="points">{{ cardDetails.totalPoints | number }}</div>
    </div>
    <div class="barcode-button">
      <div *ngIf="!isCustomerCard || (isCustomerCard && !frontside)" class="barcode-field">
        <img class="barcode" src="/assets/images/barcode.png" alt="Barcode" />
      </div>
      <div *ngIf="isCustomerCard && frontside">
        <button class="button" (click)="onRewardShop()">Zum Prämienshop</button>
      </div>
    </div>
  </div>
  <div class="card-bottom">
    <div *ngIf="!isCustomerCard || (isCustomerCard && !frontside)" class="customer-name">
      <p>{{ cardDetails.firstName }} {{ cardDetails.lastName }}</p>
    </div>
    <div *ngIf="isCustomerCard && frontside" class="logo ml-2">
      <img class="logo-picture" src="/assets/images/Hugendubel_Logo.png" alt="Hugendubel Logo" />
    </div>
  </div>
</div>
