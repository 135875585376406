/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { EisConfiguration as __Configuration } from '../eis-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfInfoDTO } from '../models/response-args-of-info-dto';
import { InfoDTO } from '../models/info-dto';
import { ListResponseArgsOfInfoDTO } from '../models/list-response-args-of-info-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
import { ListResponseArgsOfBranchDTO } from '../models/list-response-args-of-branch-dto';
import { ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-entity-key-value-dtoof-string-and-string';
@Injectable({
  providedIn: 'root',
})
class EISBackendService extends __BaseService {
  static readonly EISBackendCreateInfoPath = '/eis/info';
  static readonly EISBackendGetInfosPath = '/eis/info';
  static readonly EISBackendDeleteInfoPath = '/eis/delete/{infoId}';
  static readonly EISBackendApproveUpdatePath = '/eis/updateapproval/{infoId}';
  static readonly EISBackendRejectUpdatePath = '/eis/updatereject/{infoId}';
  static readonly EISBackendUpdateInfoPath = '/eis/info/{infoId}';
  static readonly EISBackendGetInfoByIdPath = '/eis/info/{infoId}';
  static readonly EISBackendApproveInfoPath = '/eis/info/approve/{allowsave}';
  static readonly EISBackendGetWordPath = '/eis/word/{datum}';
  static readonly EISBackendGetBranchesPath = '/eis/branch';
  static readonly EISBackendGetCategoriesPath = '/eis/category';
  static readonly EISBackendGetDisplayChannelsPath = '/eis/displaychannel';
  static readonly EISBackendGetFileContentPath = '/eis/file/{fileId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Info anlegen
   * @param params The `EISBackendService.EISBackendCreateInfoParams` containing the following parameters:
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendCreateInfoResponse(
    params: EISBackendService.EISBackendCreateInfoParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.infoDTO;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/eis/info`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Info anlegen
   * @param params The `EISBackendService.EISBackendCreateInfoParams` containing the following parameters:
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendCreateInfo(params: EISBackendService.EISBackendCreateInfoParams): __Observable<ResponseArgsOfInfoDTO> {
    return this.EISBackendCreateInfoResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInfoDTO));
  }

  /**
   * Infos
   * @param params The `EISBackendService.EISBackendGetInfosParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `sonderinfo`: SonderInfo Flag
   *
   * - `skip`:
   *
   * - `query`: Such-String
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`:
   *
   * - `archive`:
   */
  EISBackendGetInfosResponse(
    params: EISBackendService.EISBackendGetInfosParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.sonderinfo != null) __params = __params.set('sonderinfo', params.sonderinfo.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.query != null) __params = __params.set('query', params.query.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    if (params.archive != null) __params = __params.set('archive', params.archive.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/info`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Infos
   * @param params The `EISBackendService.EISBackendGetInfosParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `sonderinfo`: SonderInfo Flag
   *
   * - `skip`:
   *
   * - `query`: Such-String
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`:
   *
   * - `archive`:
   */
  EISBackendGetInfos(params: EISBackendService.EISBackendGetInfosParams): __Observable<ListResponseArgsOfInfoDTO> {
    return this.EISBackendGetInfosResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfInfoDTO));
  }

  /**
   * Löchen einere Info
   * @param infoId undefined
   */
  EISBackendDeleteInfoResponse(infoId: number): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/eis/delete/${encodeURIComponent(String(infoId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      }),
    );
  }
  /**
   * Löchen einere Info
   * @param infoId undefined
   */
  EISBackendDeleteInfo(infoId: number): __Observable<ResponseArgsOfBoolean> {
    return this.EISBackendDeleteInfoResponse(infoId).pipe(__map((_r) => _r.body as ResponseArgsOfBoolean));
  }

  /**
   * Freigabe eines Updates
   * @param params The `EISBackendService.EISBackendApproveUpdateParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendApproveUpdateResponse(
    params: EISBackendService.EISBackendApproveUpdateParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.infoDTO;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/eis/updateapproval/${encodeURIComponent(String(params.infoId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Freigabe eines Updates
   * @param params The `EISBackendService.EISBackendApproveUpdateParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendApproveUpdate(params: EISBackendService.EISBackendApproveUpdateParams): __Observable<ResponseArgsOfInfoDTO> {
    return this.EISBackendApproveUpdateResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInfoDTO));
  }

  /**
   * Ablehnen eines Updates
   * @param params The `EISBackendService.EISBackendRejectUpdateParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendRejectUpdateResponse(
    params: EISBackendService.EISBackendRejectUpdateParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.infoDTO;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/eis/updatereject/${encodeURIComponent(String(params.infoId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Ablehnen eines Updates
   * @param params The `EISBackendService.EISBackendRejectUpdateParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendRejectUpdate(params: EISBackendService.EISBackendRejectUpdateParams): __Observable<ResponseArgsOfInfoDTO> {
    return this.EISBackendRejectUpdateResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInfoDTO));
  }

  /**
   * Info aktualisieren
   * @param params The `EISBackendService.EISBackendUpdateInfoParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendUpdateInfoResponse(
    params: EISBackendService.EISBackendUpdateInfoParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.infoDTO;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/eis/info/${encodeURIComponent(String(params.infoId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Info aktualisieren
   * @param params The `EISBackendService.EISBackendUpdateInfoParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `infoDTO`: Daten
   *
   * - `locale`: Lokalisierung
   */
  EISBackendUpdateInfo(params: EISBackendService.EISBackendUpdateInfoParams): __Observable<ResponseArgsOfInfoDTO> {
    return this.EISBackendUpdateInfoResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInfoDTO));
  }

  /**
   * Info per ID
   * @param params The `EISBackendService.EISBackendGetInfoByIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`:
   */
  EISBackendGetInfoByIdResponse(
    params: EISBackendService.EISBackendGetInfoByIdParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/info/${encodeURIComponent(String(params.infoId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Info per ID
   * @param params The `EISBackendService.EISBackendGetInfoByIdParams` containing the following parameters:
   *
   * - `infoId`: Info PK
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`:
   */
  EISBackendGetInfoById(params: EISBackendService.EISBackendGetInfoByIdParams): __Observable<ResponseArgsOfInfoDTO> {
    return this.EISBackendGetInfoByIdResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInfoDTO));
  }

  /**
   * Info freigeben
   * @param params The `EISBackendService.EISBackendApproveInfoParams` containing the following parameters:
   *
   * - `infoDTO`: Info
   *
   * - `allowsave`: hat Änderungen oder ist Neu, dann darf gesichert werden
   *
   * - `locale`: Lokalisierung
   */
  EISBackendApproveInfoResponse(
    params: EISBackendService.EISBackendApproveInfoParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.infoDTO;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/eis/info/approve/${encodeURIComponent(String(params.allowsave))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Info freigeben
   * @param params The `EISBackendService.EISBackendApproveInfoParams` containing the following parameters:
   *
   * - `infoDTO`: Info
   *
   * - `allowsave`: hat Änderungen oder ist Neu, dann darf gesichert werden
   *
   * - `locale`: Lokalisierung
   */
  EISBackendApproveInfo(params: EISBackendService.EISBackendApproveInfoParams): __Observable<ResponseArgsOfInfoDTO> {
    return this.EISBackendApproveInfoResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInfoDTO));
  }

  /**
   * Infos
   * @param params The `EISBackendService.EISBackendGetWordParams` containing the following parameters:
   *
   * - `datum`:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`:
   */
  EISBackendGetWordResponse(
    params: EISBackendService.EISBackendGetWordParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/word/${encodeURIComponent(String(params.datum))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfInfoDTO>;
      }),
    );
  }
  /**
   * Infos
   * @param params The `EISBackendService.EISBackendGetWordParams` containing the following parameters:
   *
   * - `datum`:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   *
   * - `eagerLoading`:
   */
  EISBackendGetWord(params: EISBackendService.EISBackendGetWordParams): __Observable<ListResponseArgsOfInfoDTO> {
    return this.EISBackendGetWordResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfInfoDTO));
  }

  /**
   * Filialen
   * @param params The `EISBackendService.EISBackendGetBranchesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   *
   * - `labelKey`:
   */
  EISBackendGetBranchesResponse(
    params: EISBackendService.EISBackendGetBranchesParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.labelKey != null) __params = __params.set('labelKey', params.labelKey.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/branch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfBranchDTO>;
      }),
    );
  }
  /**
   * Filialen
   * @param params The `EISBackendService.EISBackendGetBranchesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   *
   * - `labelKey`:
   */
  EISBackendGetBranches(params: EISBackendService.EISBackendGetBranchesParams): __Observable<ListResponseArgsOfBranchDTO> {
    return this.EISBackendGetBranchesResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfBranchDTO));
  }

  /**
   * Kategorien/Bereiche
   * @param locale Lokalisierung (optional)
   */
  EISBackendGetCategoriesResponse(
    locale?: null | string,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/category`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Kategorien/Bereiche
   * @param locale Lokalisierung (optional)
   */
  EISBackendGetCategories(locale?: null | string): __Observable<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString> {
    return this.EISBackendGetCategoriesResponse(locale).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString),
    );
  }

  /**
   * Kategorien/Bereiche
   * @param locale Lokalisierung (optional)
   */
  EISBackendGetDisplayChannelsResponse(
    locale?: null | string,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/displaychannel`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Kategorien/Bereiche
   * @param locale Lokalisierung (optional)
   */
  EISBackendGetDisplayChannels(locale?: null | string): __Observable<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString> {
    return this.EISBackendGetDisplayChannelsResponse(locale).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString),
    );
  }

  /**
   * Datei-Inhalt
   * @param params The `EISBackendService.EISBackendGetFileContentParams` containing the following parameters:
   *
   * - `fileId`: Datei PK
   *
   * - `download`: Datei wird als Download gesendet (optional)
   *
   * @return Datei
   */
  EISBackendGetFileContentResponse(params: EISBackendService.EISBackendGetFileContentParams): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.download != null) __params = __params.set('download', params.download.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/eis/file/${encodeURIComponent(String(params.fileId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'blob',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      }),
    );
  }
  /**
   * Datei-Inhalt
   * @param params The `EISBackendService.EISBackendGetFileContentParams` containing the following parameters:
   *
   * - `fileId`: Datei PK
   *
   * - `download`: Datei wird als Download gesendet (optional)
   *
   * @return Datei
   */
  EISBackendGetFileContent(params: EISBackendService.EISBackendGetFileContentParams): __Observable<Blob> {
    return this.EISBackendGetFileContentResponse(params).pipe(__map((_r) => _r.body as Blob));
  }
}

module EISBackendService {
  /**
   * Parameters for EISBackendCreateInfo
   */
  export interface EISBackendCreateInfoParams {
    /**
     * Daten
     */
    infoDTO: InfoDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISBackendGetInfos
   */
  export interface EISBackendGetInfosParams {
    take?: null | number;

    /**
     * SonderInfo Flag
     */
    sonderinfo?: boolean;
    skip?: null | number;

    /**
     * Such-String
     */
    query?: null | string;

    /**
     * Lokalisierung
     */
    locale?: null | string;
    eagerLoading?: number;
    archive?: boolean;
  }

  /**
   * Parameters for EISBackendApproveUpdate
   */
  export interface EISBackendApproveUpdateParams {
    /**
     * Info PK
     */
    infoId: number;

    /**
     * Daten
     */
    infoDTO: InfoDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISBackendRejectUpdate
   */
  export interface EISBackendRejectUpdateParams {
    /**
     * Info PK
     */
    infoId: number;

    /**
     * Daten
     */
    infoDTO: InfoDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISBackendUpdateInfo
   */
  export interface EISBackendUpdateInfoParams {
    /**
     * Info PK
     */
    infoId: number;

    /**
     * Daten
     */
    infoDTO: InfoDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISBackendGetInfoById
   */
  export interface EISBackendGetInfoByIdParams {
    /**
     * Info PK
     */
    infoId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for EISBackendApproveInfo
   */
  export interface EISBackendApproveInfoParams {
    /**
     * Info
     */
    infoDTO: InfoDTO;

    /**
     * hat Änderungen oder ist Neu, dann darf gesichert werden
     */
    allowsave: boolean;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for EISBackendGetWord
   */
  export interface EISBackendGetWordParams {
    datum: string;
    take?: null | number;
    skip?: null | number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for EISBackendGetBranches
   */
  export interface EISBackendGetBranchesParams {
    take?: null | number;
    skip?: null | number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
    labelKey?: null | string;
  }

  /**
   * Parameters for EISBackendGetFileContent
   */
  export interface EISBackendGetFileContentParams {
    /**
     * Datei PK
     */
    fileId: number;

    /**
     * Datei wird als Download gesendet (optional)
     */
    download?: boolean;
  }
}

export { EISBackendService };
