<shared-loader [loading]="fetching$ | async" background="true" spinnerSize="32">
  <div class="overflow-scroll max-h-[calc(100vh-15rem)]">
    <div class="customer-details-header grid grid-flow-row pb-6">
      <div class="customer-details-header-actions flex flex-row justify-end pt-4 px-4">
        <page-customer-menu
          [customerId]="customerId$ | async"
          [processId]="processId$ | async"
          [hasCustomerCard]="hasKundenkarte$ | async"
          [showCustomerDetails]="false"
        ></page-customer-menu>
      </div>
      <div class="customer-details-header-body text-center -mt-3">
        <h1 class="text-[1.625rem] font-bold">
          {{ (isBusinessKonto$ | async) ? 'Firmendetails' : 'Kundendetails' }}
        </h1>
        <p>Sind Ihre Kundendaten korrekt?</p>
      </div>
    </div>
    <div class="customer-details-customer-type flex flex-row justify-between items-center bg-surface-2 text-surface-2-content h-14">
      <div class="pl-4 font-bold grid grid-flow-col justify-start items-center gap-2">
        <shared-icon [icon]="customerType$ | async"></shared-icon>
        <span>
          {{ customerType$ | async }}
        </span>
      </div>
      <ng-container *ngIf="showEditButton$ | async">
        <a
          *ngIf="editRoute$ | async; let editRoute"
          [routerLink]="editRoute.path"
          [queryParams]="editRoute.queryParams"
          [queryParamsHandling]="'merge'"
          class="btn btn-label font-bold text-brand"
        >
          Bearbeiten
        </a>
      </ng-container>
    </div>

    <div class="customer-details-customer-main-data px-5 py-3 grid grid-flow-row gap-3">
      <div class="flex flex-row">
        <div class="data-label">Erstellungsdatum</div>
        <div *ngIf="created$ | async; let created" class="data-value">
          {{ created | date: 'dd.MM.yyyy' }} | {{ created | date: 'HH:mm' }} Uhr
        </div>
      </div>
      <div class="flex flex-row">
        <div class="data-label">Kundennummer</div>
        <div class="data-value">{{ customerNumber$ | async }}</div>
      </div>
      <div class="flex flex-row" *ngIf="customerNumberDig$ | async; let customerNumberDig">
        <div class="data-label">Kundennummer-DIG</div>
        <div class="data-value">{{ customerNumberDig }}</div>
      </div>
      <div class="flex flex-row" *ngIf="customerNumberBeeline$ | async; let customerNumberBeeline">
        <div class="data-label">Kundennummer-BEELINE</div>
        <div class="data-value">{{ customerNumberBeeline }}</div>
      </div>
    </div>
    <ng-container *ngIf="isBusinessKonto$ | async">
      <div class="customer-details-customer-main-row">
        <div class="data-label">Firmenname</div>
        <div class="data-value">{{ organisationName$ | async }}</div>
      </div>
      <div class="customer-details-customer-main-row">
        <div class="data-label">Abteilung</div>
        <div class="data-value">{{ department$ | async }}</div>
      </div>
      <div class="customer-details-customer-main-row">
        <div class="data-label">USt-ID</div>
        <div class="data-value">{{ vatId$ | async }}</div>
      </div>
    </ng-container>

    <div class="customer-details-customer-main-row">
      <div class="data-label">Anrede</div>
      <div class="data-value">{{ gender$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Titel</div>
      <div class="data-value">{{ title$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Nachname</div>
      <div class="data-value">{{ lastName$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Vorname</div>
      <div class="data-value">{{ firstName$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">E-Mail</div>
      <div class="data-value">{{ email$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Straße</div>
      <div class="data-value">{{ street$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Hausnr.</div>
      <div class="data-value">{{ streetNumber$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">PLZ</div>
      <div class="data-value">{{ zipCode$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Ort</div>
      <div class="data-value">{{ city$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Adresszusatz</div>
      <div class="data-value">{{ info$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Land</div>
      <div *ngIf="country$ | async; let country" class="data-value">{{ country | country }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Festnetznr.</div>
      <div class="data-value">{{ landline$ | async }}</div>
    </div>
    <div class="customer-details-customer-main-row">
      <div class="data-label">Mobilnr.</div>
      <div class="data-value">{{ mobile$ | async }}</div>
    </div>

    <ng-container *ngIf="!(isBusinessKonto$ | async)">
      <div class="customer-details-customer-main-row">
        <div class="data-label">Geburtstag</div>
        <div class="data-value">{{ dateOfBirth$ | async | date: 'dd.MM.yyyy' }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="!(isBusinessKonto$ | async) && (organisationName$ | async)">
      <div class="customer-details-customer-main-row">
        <div class="data-label">Firmenname</div>
        <div class="data-value">{{ organisationName$ | async }}</div>
      </div>
      <ng-container *ngIf="!(isOnlineOrCustomerCardUser$ | async)">
        <div class="customer-details-customer-main-row">
          <div class="data-label">Abteilung</div>
          <div class="data-value">{{ department$ | async }}</div>
        </div>
        <div class="customer-details-customer-main-row">
          <div class="data-label">USt-ID</div>
          <div class="data-value">{{ vatId$ | async }}</div>
        </div>
      </ng-container>
    </ng-container>

    <page-details-main-view-billing-addresses></page-details-main-view-billing-addresses>
    <page-details-main-view-delivery-addresses></page-details-main-view-delivery-addresses>
    <div class="h-24"></div>
  </div>
</shared-loader>

<button
  *ngIf="shoppingCartHasNoItems$ | async"
  type="button"
  (click)="continue()"
  class="text-white text-lg bg-brand rounded-full px-5 py-3 absolute top-[calc(100vh-19.375rem)] left-1/2 -translate-x-1/2 font-bold disabled:bg-inactive-branch"
  [disabled]="showLoader$ | async"
>
  <shared-loader [loading]="showLoader$ | async" spinnerSize="32"> Weiter zur Artikelsuche </shared-loader>
</button>

<button
  *ngIf="shoppingCartHasItems$ | async"
  type="button"
  (click)="continue()"
  class="text-white text-lg bg-brand rounded-full px-5 py-3 absolute top-[calc(100vh-19.375rem)] left-1/2 -translate-x-1/2 font-bold disabled:bg-inactive-branch"
  [disabled]="showLoader$ | async"
>
  <shared-loader [loading]="showLoader$ | async" spinnerSize="32"> Weiter zum Warenkorb </shared-loader>
</button>
