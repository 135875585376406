/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfBranchDTO } from '../models/response-args-of-ienumerable-of-branch-dto';
@Injectable({
  providedIn: 'root',
})
class BranchService extends __BaseService {
  static readonly BranchGetBranchesPath = '/order/branches';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Liste aller Filialen
   * @param params The `BranchService.BranchGetBranchesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   *
   * - `labelOnly`: Einschränkung auf Label
   */
  BranchGetBranchesResponse(
    params: BranchService.BranchGetBranchesParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.labelOnly != null) __params = __params.set('labelOnly', params.labelOnly.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/branches`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfBranchDTO>;
      }),
    );
  }
  /**
   * Liste aller Filialen
   * @param params The `BranchService.BranchGetBranchesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`: Lokalisierung
   *
   * - `labelOnly`: Einschränkung auf Label
   */
  BranchGetBranches(params: BranchService.BranchGetBranchesParams): __Observable<ResponseArgsOfIEnumerableOfBranchDTO> {
    return this.BranchGetBranchesResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfBranchDTO));
  }
}

module BranchService {
  /**
   * Parameters for BranchGetBranches
   */
  export interface BranchGetBranchesParams {
    take?: null | number;
    skip?: null | number;

    /**
     * Lokalisierung
     */
    locale?: null | string;

    /**
     * Einschränkung auf Label
     */
    labelOnly?: boolean;
  }
}

export { BranchService };
