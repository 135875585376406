<div class="ui-searchbox-input-wrapper">
  <div class="hint-wrapper">
    <input
      id="searchbox"
      class="ui-searchbox-input"
      autocomplete="off"
      #input
      type="text"
      [placeholder]="placeholder"
      [(ngModel)]="query"
      (ngModelChange)="setQuery($event, true, true)"
      (focus)="clearHint(); focused.emit(true)"
      (blur)="focused.emit(false)"
      (keyup)="onKeyup($event)"
    />
    <div *ngIf="showHint" class="hint" (click)="focus()">{{ hint }}</div>
  </div>
  <button type="button" tabindex="1" class="clear-button" *ngIf="canClear" (click)="clear(); focus()">
    <ui-icon icon="close" size="22px"></ui-icon>
  </button>

  <ng-container *ngIf="!loading">
    <button tabindex="0" class="search-button search" *ngIf="!canScan" (click)="emitSearch()" [disabled]="completeValue !== query">
      <ui-icon icon="search" size="28px"></ui-icon>
    </button>
    <button tabindex="0" class="search-button scan" *ngIf="canScan" (click)="startScan()">
      <shared-icon icon="barcode-scan" [size]="32"></shared-icon>
    </button>
  </ng-container>

  <div *ngIf="loading" class="load-indicator">
    <ui-icon icon="spinner" size="32px"></ui-icon>
  </div>
</div>
<ng-content select="ui-autocomplete"></ng-content>
