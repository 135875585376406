import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavigationService } from './navigation.service';

@Injectable({ providedIn: 'root' })
export class CustomerOrdersNavigationService extends NavigationService {
  constructor(_router: Router) {
    super(_router);
  }

  getCustomerOrdersBasePath(processId?: number): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = ['/kunde', processId, 'order', { outlets: { primary: ['filter'], side: ['search'] } }].filter((x) => !!x);
    return { path, navigate: this._createNavigationFn(path) };
  }

  getCustomerOrdersResultsPath(
    processId?: number,
    extras?: NavigationExtras,
  ): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = ['/kunde', processId, 'order', { outlets: { primary: ['results'], side: null } }].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getCustomerOrdersResultsAndFilterPath({
    processId,
    processingStatus,
    compartmentCode,
    orderId,
    extras,
  }: {
    processId?: number;
    processingStatus?: number;
    compartmentCode?: string;
    orderId?: number;
    extras?: NavigationExtras;
  }): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = [
      '/kunde',
      processId,
      'order',
      { outlets: { primary: ['filter', orderId, compartmentCode, processingStatus].filter((x) => !!x), side: ['results'] } },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getCustomerOrdersDetailsPath({
    processId,
    processingStatus,
    compartmentCode,
    orderId,
    extras,
  }: {
    processId?: number;
    processingStatus?: number;
    compartmentCode?: string;
    orderId?: number;
    extras?: NavigationExtras;
  }): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = [
      '/kunde',
      processId,
      'order',
      {
        outlets: {
          primary: ['details', orderId, compartmentCode, processingStatus].filter((x) => !!x),
          side: ['results', orderId, compartmentCode, processingStatus].filter((x) => !!x),
        },
      },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getCustomerOrdersEditPath({
    processId,
    processingStatus,
    compartmentCode,
    orderId,
    extras,
  }: {
    processId?: number;
    processingStatus?: number;
    compartmentCode?: string;
    orderId?: number;
    extras?: NavigationExtras;
  }): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = [
      '/kunde',
      processId,
      'order',
      {
        outlets: {
          primary: ['details', orderId, compartmentCode, processingStatus, 'edit'].filter((x) => !!x),
          side: ['results', orderId, compartmentCode, processingStatus].filter((x) => !!x),
        },
      },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }

  getCustomerOrdersHistoryPath({
    processId,
    processingStatus,
    compartmentCode,
    orderId,
    extras,
  }: {
    processId?: number;
    processingStatus?: number;
    compartmentCode?: string;
    orderId?: number;
    extras?: NavigationExtras;
  }): { path: any[]; extras?: NavigationExtras; navigate: () => Promise<boolean> } {
    const path = [
      '/kunde',
      processId,
      'order',
      {
        outlets: {
          primary: ['details', orderId, compartmentCode, processingStatus, 'history'].filter((x) => !!x),
          side: ['results', orderId, compartmentCode, processingStatus].filter((x) => !!x),
        },
      },
    ].filter((x) => !!x);
    return {
      path,
      navigate: this._createNavigationFn(path, extras),
    };
  }
}
