<div class="notification-list scroll-bar">
  <ng-container *ngFor="let notification of notifications">
    <modal-notifications-list-item [item]="notification" (itemSelected)="itemSelected($event)"></modal-notifications-list-item>
    <hr />
  </ng-container>
</div>

<div class="actions">
  <a class="cta-primary" [routerLink]="['/filiale/remission/create']" (click)="navigated.emit()"> Zur Remission </a>
</div>
