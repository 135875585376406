<div class="text-center pt-10 px-8 rounded-card side-view-shadow grow">
  <h1 class="text-[1.625rem] font-bold">Kundensuche</h1>
  <p class="text-lg mt-2 mb-6">Haben Sie ein Konto bei uns?</p>
  <shared-filter-input-group-main
    *ngIf="filter$ | async; let filter"
    [inputGroup]="filter?.input | group: 'main'"
    (search)="search(filter)"
    [loading]="fetching$ | async"
    [hint]="message$ | async"
  ></shared-filter-input-group-main>
  <p class="mt-6">
    Kunde nicht gefunden?
    <a class="text-brand" *ngIf="createRoute$ | async; let route" [routerLink]="route.path" [queryParams]="route.queryParams">
      Neue Kundendaten erfassen
    </a>
  </p>
</div>
