<div class="wrapper">
  <div class="header">
    <h1>Wählen Sie einen Drucker aus</h1>

    <span *ngIf="error" class="error-message">{{ errorMessage }}</span>
  </div>

  <div class="body">
    <ui-spinner [show]="!loaded">
      <ui-select class="select" [(ngModel)]="selectedPrinterKey">
        <ui-select-option *ngFor="let printer of printers$ | async" [label]="printer.description" [value]="printer.key"></ui-select-option>
      </ui-select>
    </ui-spinner>
  </div>

  <div class="footer">
    <button class="print-btn" (click)="print()" [disabled]="!loaded">Drucken</button>
  </div>
</div>
