import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationService } from '@core/application';
import { CheckoutNavigationService } from '@shared/services/navigation';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CanActivateCartGuard {
  constructor(
    private readonly _applicationService: ApplicationService,
    private _checkoutNavigationService: CheckoutNavigationService,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const processes = await this._applicationService.getProcesses$('customer').pipe(first()).toPromise();
    let lastActivatedProcessId = (
      await this._applicationService
        .getLastActivatedProcessWithSectionAndType$('customer', 'cart')
        .pipe(first())
        .toPromise()
    )?.id;
    if (!lastActivatedProcessId) {
      lastActivatedProcessId = Date.now();
      await this._applicationService.createProcess({
        id: lastActivatedProcessId,
        type: 'cart',
        section: 'customer',
        name: `Vorgang ${processes.length + 1}`,
      });
    }
    await this._checkoutNavigationService.getCheckoutReviewPath(lastActivatedProcessId).path;
    return false;
  }
}
