/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CrmConfiguration as __Configuration } from '../crm-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfShippingAddressDTO } from '../models/response-args-of-shipping-address-dto';
import { ShippingAddressDTO } from '../models/shipping-address-dto';
import { ListResponseArgsOfShippingAddressDTO } from '../models/list-response-args-of-shipping-address-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
import { ResponseArgsOfIEnumerableOfHistoryDTO } from '../models/response-args-of-ienumerable-of-history-dto';
@Injectable({
  providedIn: 'root',
})
class ShippingAddressService extends __BaseService {
  static readonly ShippingAddressCreateShippingAddressPath = '/customer/{customerId}/shippingaddress';
  static readonly ShippingAddressGetShippingAddressesPath = '/customer/{customerId}/shippingaddress';
  static readonly ShippingAddressUpdateShippingAddressPath = '/customer/{customerId}/shippingaddress/{shippingAddressId}';
  static readonly ShippingAddressDeleteShippingAddressPath = '/customer/{customerId}/shippingaddress/{shippingAddressId}';
  static readonly ShippingAddressGetShippingaddressPath = '/customer/shippingaddress/{shippingaddressId}';
  static readonly ShippingAddressModifyShippingAddressFlagPath =
    '/customer/{customerId}/shippingaddress/{shippingAddressId}/modifydefaultflag';
  static readonly ShippingAddressGetShippingAddressHistoryPath = '/customer/{customerId}/shippingaddress/{shippingAddressId}/history';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Anlage einer neuen Lieferadresse
   * @param params The `ShippingAddressService.ShippingAddressCreateShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddress`: Adressdaten
   *
   * - `customerId`: Kunde PK
   */
  ShippingAddressCreateShippingAddressResponse(
    params: ShippingAddressService.ShippingAddressCreateShippingAddressParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.shippingAddress;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/shippingaddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * Anlage einer neuen Lieferadresse
   * @param params The `ShippingAddressService.ShippingAddressCreateShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddress`: Adressdaten
   *
   * - `customerId`: Kunde PK
   */
  ShippingAddressCreateShippingAddress(
    params: ShippingAddressService.ShippingAddressCreateShippingAddressParams,
  ): __Observable<ResponseArgsOfShippingAddressDTO> {
    return this.ShippingAddressCreateShippingAddressResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfShippingAddressDTO));
  }

  /**
   * @param params The `ShippingAddressService.ShippingAddressGetShippingAddressesParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `take`:
   *
   * - `skip`:
   */
  ShippingAddressGetShippingAddressesResponse(
    params: ShippingAddressService.ShippingAddressGetShippingAddressesParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/shippingaddress`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * @param params The `ShippingAddressService.ShippingAddressGetShippingAddressesParams` containing the following parameters:
   *
   * - `customerId`:
   *
   * - `take`:
   *
   * - `skip`:
   */
  ShippingAddressGetShippingAddresses(
    params: ShippingAddressService.ShippingAddressGetShippingAddressesParams,
  ): __Observable<ListResponseArgsOfShippingAddressDTO> {
    return this.ShippingAddressGetShippingAddressesResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfShippingAddressDTO));
  }

  /**
   * Änderung einer bestehenden Lieferadresse
   * @param params The `ShippingAddressService.ShippingAddressUpdateShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddressId`: Lieferadresse PK
   *
   * - `shippingAddress`: Lieferadresse
   *
   * - `customerId`: Kunde PK
   */
  ShippingAddressUpdateShippingAddressResponse(
    params: ShippingAddressService.ShippingAddressUpdateShippingAddressParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.shippingAddress;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/customer/${encodeURIComponent(String(params.customerId))}/shippingaddress/${encodeURIComponent(String(params.shippingAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * Änderung einer bestehenden Lieferadresse
   * @param params The `ShippingAddressService.ShippingAddressUpdateShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddressId`: Lieferadresse PK
   *
   * - `shippingAddress`: Lieferadresse
   *
   * - `customerId`: Kunde PK
   */
  ShippingAddressUpdateShippingAddress(
    params: ShippingAddressService.ShippingAddressUpdateShippingAddressParams,
  ): __Observable<ResponseArgsOfShippingAddressDTO> {
    return this.ShippingAddressUpdateShippingAddressResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfShippingAddressDTO));
  }

  /**
   * Löschen einer bestehenden Lieferadresse
   * @param params The `ShippingAddressService.ShippingAddressDeleteShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddressId`: Lieferadresse PK
   *
   * - `customerId`: Kunde PK
   *
   * - `deletionComment`: Bemerkung zur Löschung
   */
  ShippingAddressDeleteShippingAddressResponse(
    params: ShippingAddressService.ShippingAddressDeleteShippingAddressParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deletionComment != null) __params = __params.set('deletionComment', params.deletionComment.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/customer/${encodeURIComponent(String(params.customerId))}/shippingaddress/${encodeURIComponent(String(params.shippingAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      }),
    );
  }
  /**
   * Löschen einer bestehenden Lieferadresse
   * @param params The `ShippingAddressService.ShippingAddressDeleteShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddressId`: Lieferadresse PK
   *
   * - `customerId`: Kunde PK
   *
   * - `deletionComment`: Bemerkung zur Löschung
   */
  ShippingAddressDeleteShippingAddress(
    params: ShippingAddressService.ShippingAddressDeleteShippingAddressParams,
  ): __Observable<ResponseArgsOfBoolean> {
    return this.ShippingAddressDeleteShippingAddressResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfBoolean));
  }

  /**
   * @param shippingaddressId undefined
   */
  ShippingAddressGetShippingaddressResponse(
    shippingaddressId: number,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/customer/shippingaddress/${encodeURIComponent(String(shippingaddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * @param shippingaddressId undefined
   */
  ShippingAddressGetShippingaddress(shippingaddressId: number): __Observable<ResponseArgsOfShippingAddressDTO> {
    return this.ShippingAddressGetShippingaddressResponse(shippingaddressId).pipe(
      __map((_r) => _r.body as ResponseArgsOfShippingAddressDTO),
    );
  }

  /**
   * @param params The `ShippingAddressService.ShippingAddressModifyShippingAddressFlagParams` containing the following parameters:
   *
   * - `shippingAddressId`:
   *
   * - `customerId`:
   *
   * - `isDefault`:
   */
  ShippingAddressModifyShippingAddressFlagResponse(
    params: ShippingAddressService.ShippingAddressModifyShippingAddressFlagParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.isDefault != null) __params = __params.set('isDefault', params.isDefault.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/customer/${encodeURIComponent(String(params.customerId))}/shippingaddress/${encodeURIComponent(String(params.shippingAddressId))}/modifydefaultflag`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * @param params The `ShippingAddressService.ShippingAddressModifyShippingAddressFlagParams` containing the following parameters:
   *
   * - `shippingAddressId`:
   *
   * - `customerId`:
   *
   * - `isDefault`:
   */
  ShippingAddressModifyShippingAddressFlag(
    params: ShippingAddressService.ShippingAddressModifyShippingAddressFlagParams,
  ): __Observable<ResponseArgsOfShippingAddressDTO> {
    return this.ShippingAddressModifyShippingAddressFlagResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfShippingAddressDTO));
  }

  /**
   * @param params The `ShippingAddressService.ShippingAddressGetShippingAddressHistoryParams` containing the following parameters:
   *
   * - `shippingAddressId`:
   *
   * - `customerId`:
   *
   * - `locale`:
   */
  ShippingAddressGetShippingAddressHistoryResponse(
    params: ShippingAddressService.ShippingAddressGetShippingAddressHistoryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/customer/${encodeURIComponent(String(params.customerId))}/shippingaddress/${encodeURIComponent(String(params.shippingAddressId))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>;
      }),
    );
  }
  /**
   * @param params The `ShippingAddressService.ShippingAddressGetShippingAddressHistoryParams` containing the following parameters:
   *
   * - `shippingAddressId`:
   *
   * - `customerId`:
   *
   * - `locale`:
   */
  ShippingAddressGetShippingAddressHistory(
    params: ShippingAddressService.ShippingAddressGetShippingAddressHistoryParams,
  ): __Observable<ResponseArgsOfIEnumerableOfHistoryDTO> {
    return this.ShippingAddressGetShippingAddressHistoryResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfHistoryDTO),
    );
  }
}

module ShippingAddressService {
  /**
   * Parameters for ShippingAddressCreateShippingAddress
   */
  export interface ShippingAddressCreateShippingAddressParams {
    /**
     * Adressdaten
     */
    shippingAddress: ShippingAddressDTO;

    /**
     * Kunde PK
     */
    customerId: number;
  }

  /**
   * Parameters for ShippingAddressGetShippingAddresses
   */
  export interface ShippingAddressGetShippingAddressesParams {
    customerId: number;
    take?: null | number;
    skip?: null | number;
  }

  /**
   * Parameters for ShippingAddressUpdateShippingAddress
   */
  export interface ShippingAddressUpdateShippingAddressParams {
    /**
     * Lieferadresse PK
     */
    shippingAddressId: number;

    /**
     * Lieferadresse
     */
    shippingAddress: ShippingAddressDTO;

    /**
     * Kunde PK
     */
    customerId: number;
  }

  /**
   * Parameters for ShippingAddressDeleteShippingAddress
   */
  export interface ShippingAddressDeleteShippingAddressParams {
    /**
     * Lieferadresse PK
     */
    shippingAddressId: number;

    /**
     * Kunde PK
     */
    customerId: number;

    /**
     * Bemerkung zur Löschung
     */
    deletionComment?: null | string;
  }

  /**
   * Parameters for ShippingAddressModifyShippingAddressFlag
   */
  export interface ShippingAddressModifyShippingAddressFlagParams {
    shippingAddressId: number;
    customerId: number;
    isDefault?: boolean;
  }

  /**
   * Parameters for ShippingAddressGetShippingAddressHistory
   */
  export interface ShippingAddressGetShippingAddressHistoryParams {
    shippingAddressId: number;
    customerId: string;
    locale?: null | string;
  }
}

export { ShippingAddressService };
