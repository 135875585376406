import { Injectable, Injector } from '@angular/core';
import { LogLevel, LogProvider } from '@core/logger';
import { UserStateService } from '@generated/swagger/isa-api';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class IsaLogProvider implements LogProvider {
  static InfoService: UserStateService | undefined;

  constructor() {}

  log(logLevel: LogLevel, message: string, error: Error, ...optionalParams: any[]): void {
    if (!environment.production && (logLevel === LogLevel.WARN || logLevel === LogLevel.ERROR)) {
      IsaLogProvider.InfoService?.UserStateSaveLog({
        logType: logLevel,
        message: message,
        content: JSON.stringify({
          error: error?.name,
          message: error?.message,
          stack: error?.stack,
          data: optionalParams,
        }),
      })
        .toPromise()
        .catch(() => {});
    }
  }
}
