/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { PrintConfiguration as __Configuration } from '../print-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgs } from '../models/response-args';
import { PrintRequestOfIEnumerableOfItemDTO } from '../models/print-request-of-ienumerable-of-item-dto';
@Injectable({
  providedIn: 'root',
})
class CatalogPrintService extends __BaseService {
  static readonly CatalogPrintArtikelDetailPath = '/print/article/detail';
  static readonly CatalogPrintArtikelListePath = '/print/article/list';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Druckt die Artikelstammdaten inklusive Details
   * @param data Artikel
   */
  CatalogPrintArtikelDetailResponse(data: PrintRequestOfIEnumerableOfItemDTO): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/article/detail`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Druckt die Artikelstammdaten inklusive Details
   * @param data Artikel
   */
  CatalogPrintArtikelDetail(data: PrintRequestOfIEnumerableOfItemDTO): __Observable<ResponseArgs> {
    return this.CatalogPrintArtikelDetailResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Druckt die Artikelstammdaten
   * @param data Artikel
   */
  CatalogPrintArtikelListeResponse(data: PrintRequestOfIEnumerableOfItemDTO): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/article/list`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Druckt die Artikelstammdaten
   * @param data Artikel
   */
  CatalogPrintArtikelListe(data: PrintRequestOfIEnumerableOfItemDTO): __Observable<ResponseArgs> {
    return this.CatalogPrintArtikelListeResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }
}

module CatalogPrintService {}

export { CatalogPrintService };
