import { Platform, PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BranchSelectorComponent } from '@shared/components/branch-selector';
import { BranchDTO } from '@generated/swagger/checkout-api';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-preview',
  templateUrl: 'preview.component.html',
  styleUrls: ['preview.component.css'],
  imports: [CommonModule, BranchSelectorComponent, PlatformModule],
})
export class PreviewComponent implements OnInit {
  selectedBranch$ = new BehaviorSubject<BranchDTO>({});

  get appVersion() {
    return 'App Version: ' + (window.navigator as any).appVersion;
  }

  get userAgent() {
    return 'User Agent: ' + (window.navigator as any).userAgent;
  }

  get navigator() {
    const nav = {};
    for (let i in window.navigator) nav[i] = navigator[i];
    return nav;
  }

  get platform() {
    return this._platform;
  }

  get device() {
    const isIpadNative = this._platform.IOS && !this._platform.SAFARI;
    const isIpadMini6Native = window?.navigator?.userAgent?.includes('Macintosh') && !this._platform.SAFARI;
    const isNative = isIpadNative || isIpadMini6Native;
    const isPWA = this._platform.IOS && this._platform.SAFARI;
    const isDesktop = !isNative && !isPWA;
    if (isNative) {
      if (isIpadMini6Native) {
        return 'IPAD mini 6 Native App';
      } else if (isIpadNative) {
        return 'IPAD mini 2 Native App or IPAD mini 5 Native App';
      }
    } else if (isPWA) {
      return 'IPAD Safari PWA';
    } else if (isDesktop) return 'Desktop or Macintosh';
  }

  constructor(private readonly _platform: Platform) {}

  ngOnInit() {}

  setNewBranch(branch: BranchDTO) {
    this.selectedBranch$.next(branch);
  }
}
