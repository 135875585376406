<ng-container sharedInputControlOutlet #indicatorOutlet> </ng-container>
<div class="shared-input-control-wrapper">
  <ng-content select="shared-input-control-prefix"></ng-content>

  <ng-content select="[sharedInputControlInput]"></ng-content>

  <ng-content select="shared-input-control-suffix"></ng-content>
</div>

<ng-container sharedInputControlOutlet #errorOutlet> </ng-container>
