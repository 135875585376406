import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterInputGroupMainComponent } from './filter-input-group-main.component';
import { UiAutocompleteModule } from '@ui/autocomplete';
import { UiCommonModule } from '@ui/common';
import { SearchboxModule } from '@shared/components/searchbox';
import { UiTooltipModule } from '@ui/tooltip';

@NgModule({
  imports: [CommonModule, UiCommonModule, SearchboxModule, UiAutocompleteModule, UiTooltipModule],
  exports: [FilterInputGroupMainComponent],
  declarations: [FilterInputGroupMainComponent],
})
export class FilterInputGroupMainModule {}
