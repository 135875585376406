<div class="grid grid-flow-col items-center justify-between px-4 py-2 border-t-2 border-solid border-surface-2">
  <h3 class="font-bold text-xl">Lieferadresse</h3>
  <a
    *ngIf="addShippingAddressRoute$ | async; let addRoute"
    type="button"
    class="text-brand font-bold"
    [routerLink]="addRoute.path"
    [queryParams]="addRoute.queryParams"
    [queryParamsHandling]="'merge'"
  >
    Hinzufügen
  </a>
</div>

<div class="grid grid-flow-row">
  <label
    class="px-4 py-3 font-bold border-t-2 border-solid border-surface-2 cursor-pointer flex flex-row justify-start items-center"
    *ngIf="showCustomerAddress$ | async"
  >
    <input name="shipping-address" type="radio" [checked]="!(selectedShippingAddress$ | async)" (change)="selectCustomerAddress()" />
    <div class="ml-2 flex flex-row justify-between items-start grow">
      <span class="mr-4">
        {{ customer$ | async | address }}
      </span>
      <a
        *ngIf="editRoute$ | async; let editRoute"
        [routerLink]="editRoute.path"
        [queryParams]="editRoute.queryParams"
        [queryParamsHandling]="'merge'"
        class="text-brand font-bold"
        type="button"
      >
        Bearbeiten
      </a>
    </div>
  </label>
  <label
    class="px-4 py-3 font-bold border-t-2 border-solid border-surface-2 cursor-pointer flex flex-row justify-start items-center"
    *ngFor="let shippingAddress of shippingAddresses$ | async"
  >
    <input
      name="shipping-address"
      type="radio"
      [value]="shippingAddress"
      [checked]="(selectedShippingAddress$ | async)?.id === shippingAddress.id"
      (change)="selectShippingAddress(shippingAddress)"
    />
    <div class="ml-2 flex flex-row justify-between items-start grow">
      <span class="mr-4">
        {{ shippingAddress | address }}
      </span>
      <a
        *ngIf="editShippingAddressRoute$(shippingAddress.id) | async; let route"
        class="text-brand font-bold"
        type="button"
        [routerLink]="route?.path"
        [queryParams]="route?.queryParams"
        [queryParamsHandling]="'merge'"
      >
        Bearbeiten
      </a>
    </div>
  </label>
</div>
