{
  "name": "hima",
  "version": "3.4.195",
  "scripts": {
    "ng": "ng",
    "start": "nx serve isa-app --ssl",
    "test": "npx nx run-many -t test --exclude isa-app",
    "ci": "npx nx run-many -t test --exclude isa-app -c ci",
    "build": "nx build isa-app --configuration=development",
    "build-prod": "nx build isa-app --configuration=production",
    "lint": "nx lint",
    "e2e": "nx e2e",
    "generate:swagger": "nx run-many -t generate -p tag:generated,swagger",
    "fix:files:swagger": "node ./tools/fix-files.js generated/swagger",
    "prettier": "prettier --write .",
    "pretty-quick": "pretty-quick --staged",
    "prepare": "husky",
    "storybook": "npx nx run isa-app:storybook"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "19.1.7",
    "@angular/cdk": "19.1.5",
    "@angular/common": "19.1.7",
    "@angular/compiler": "19.1.7",
    "@angular/core": "19.1.7",
    "@angular/forms": "19.1.7",
    "@angular/localize": "19.1.7",
    "@angular/platform-browser": "19.1.7",
    "@angular/platform-browser-dynamic": "19.1.7",
    "@angular/router": "19.1.7",
    "@angular/service-worker": "19.1.7",
    "@microsoft/signalr": "^8.0.7",
    "@ng-icons/core": "^29.5.1",
    "@ng-icons/material-icons": "^29.5.1",
    "@ngrx/component-store": "19.1.0",
    "@ngrx/effects": "19.1.0",
    "@ngrx/entity": "19.1.0",
    "@ngrx/operators": "19.1.0",
    "@ngrx/signals": "19.1.0",
    "@ngrx/store": "19.1.0",
    "@ngrx/store-devtools": "19.1.0",
    "angular-oauth2-oidc": "^17.0.2",
    "angular-oauth2-oidc-jwks": "^17.0.2",
    "date-fns": "^4.1.0",
    "lodash": "^4.17.21",
    "moment": "^2.30.1",
    "ng2-pdf-viewer": "^10.4.0",
    "ngx-matomo-client": "^7.0.1",
    "parse-duration": "^2.1.3",
    "rxjs": "~7.8.2",
    "scandit-web-datacapture-barcode": "^6.28.1",
    "scandit-web-datacapture-core": "^6.28.1",
    "tslib": "^2.3.0",
    "uuid": "^8.3.2",
    "zod": "^3.24.2",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "19.2.6",
    "@angular-devkit/core": "19.2.6",
    "@angular-devkit/schematics": "19.2.6",
    "@angular/cli": "19.2.6",
    "@angular/compiler-cli": "19.1.7",
    "@angular/language-service": "19.1.7",
    "@angular/pwa": "^19.2.0",
    "@eslint/js": "^9.8.0",
    "@ngneat/spectator": "^19.0.0",
    "@nx/angular": "20.4.6",
    "@nx/eslint": "20.4.6",
    "@nx/eslint-plugin": "20.4.6",
    "@nx/jest": "20.4.6",
    "@nx/js": "20.4.6",
    "@nx/storybook": "^20.4.6",
    "@nx/web": "20.4.6",
    "@nx/workspace": "20.4.6",
    "@schematics/angular": "^19.1.8",
    "@softarc/eslint-plugin-sheriff": "^0.18.0",
    "@softarc/sheriff-core": "^0.18.0",
    "@storybook/addon-essentials": "^8.4.6",
    "@storybook/addon-interactions": "^8.4.6",
    "@storybook/angular": "^8.4.6",
    "@storybook/core-server": "^8.4.6",
    "@storybook/jest": "^0.2.3",
    "@storybook/test-runner": "^0.19.0",
    "@storybook/testing-library": "^0.2.2",
    "@swc-node/register": "~1.9.1",
    "@swc/core": "~1.5.7",
    "@swc/helpers": "~0.5.11",
    "@types/jest": "^29.5.12",
    "@types/lodash": "^4.17.16",
    "@types/node": "18.16.9",
    "@types/uuid": "^10.0.0",
    "@typescript-eslint/utils": "^8.19.0",
    "angular-eslint": "^19.1.0",
    "autoprefixer": "^10.4.20",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-prettier": "^5.2.3",
    "husky": "^9.1.7",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-environment-node": "^29.7.0",
    "jest-preset-angular": "~14.4.0",
    "ng-mocks": "^14.13.4",
    "ng-swagger-gen": "^2.3.1",
    "nx": "20.4.6",
    "postcss": "^8.5.3",
    "prettier": "^3.5.2",
    "pretty-quick": "~4.0.0",
    "storybook": "^8.4.6",
    "tailwindcss": "^3.4.14",
    "ts-jest": "^29.1.0",
    "ts-node": "10.9.1",
    "typescript": "~5.6.0",
    "typescript-eslint": "^8.19.0"
  },
  "engines": {
    "node": ">=22.13.0",
    "npm": ">=11.0.0"
  }
}
