<div>
  <div class="inputs overflow-y-auto">
    <button
      [id]="'filter-group-filter-input-' + input?.label | lowercase"
      class="ui-input"
      type="button"
      *ngFor="let input of uiInputGroup?.input"
      [class.active]="activeInput === input"
      [class.has-options]="input?.hasSelectedOptions() || input?.hasUnselectedOptions() || input?.selected"
      (click)="setActiveInput(input)"
    >
      <div class="grow">
        {{ input?.label }}
      </div>
      <ui-icon icon="arrow_head" size="1rem"></ui-icon>
    </button>
  </div>
</div>

<ui-filter-input-options
  *ngIf="activeInput?.options?.values?.length"
  [class.remove-rounded-top-left]="isFirstInputSelected"
  class="options"
  [inputOptions]="activeInput?.options"
>
</ui-filter-input-options>

<div *ngIf="!activeInput?.options?.values?.length" class="bg-white p-4 rounded" [ngSwitch]="activeInput?.type">
  <p *ngIf="activeInput?.description" class="font-bold">{{ activeInput?.description }}</p>

  <ng-container *ngIf="inputTemplate">
    <ng-container *ngTemplateOutlet="inputTemplate; context: { $implicit: activeInput }"> </ng-container>
  </ng-container>
  <ng-container *ngIf="!inputTemplate">
    <ui-filter-input-text *ngSwitchCase="1" [input]="activeInput"></ui-filter-input-text>
  </ng-container>
</div>
