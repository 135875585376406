import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '@swagger/oms';

@Pipe({ name: 'orderDestination', standalone: true, pure: true })
export class OrderDestinationPipe implements PipeTransform {
  transform(order: { orderType?: OrderType; targetBranchName?: string }): any {
    if (order.orderType === 2) {
      return 'Versand';
    }

    if (order.targetBranchName) {
      return order.targetBranchName;
    }

    return '';
  }
}
