/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfRequiredData } from '../models/response-args-of-ienumerable-of-required-data';
import { ResponseArgsOfCheckoutDTO } from '../models/response-args-of-checkout-dto';
import { ResponseArgsOfCheckoutStep } from '../models/response-args-of-checkout-step';
import { ResponseArgsOfNullableBoolean } from '../models/response-args-of-nullable-boolean';
import { UserAccountDTO } from '../models/user-account-dto';
import { ResponseArgsOfDestinationDTO } from '../models/response-args-of-destination-dto';
import { DestinationDTO } from '../models/destination-dto';
import { ResponseArgsOfIEnumerableOfCheckoutDeliveryDTO } from '../models/response-args-of-ienumerable-of-checkout-delivery-dto';
import { ResponseArgsOfCheckoutDeliveryDTO } from '../models/response-args-of-checkout-delivery-dto';
import { DeliveryContainer } from '../models/delivery-container';
import { KeyValuePairOfEntityDTOContainerOfCheckoutItemDTOAndNullableDecimal } from '../models/key-value-pair-of-entity-dtocontainer-of-checkout-item-dtoand-nullable-decimal';
import { NotificationChannel } from '../models/notification-channel';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutService extends __BaseService {
  static readonly StoreCheckoutRequiredDataForDeliveryPath = '/store/checkout/{checkoutId}/destination/{destinationId}/requireddata';
  static readonly StoreCheckoutCreateOrRefreshCheckoutPath = '/store/checkout/shoppingcart/{shoppingCartId}';
  static readonly StoreCheckoutDiscardCheckoutPath = '/store/checkout/{checkoutId}';
  static readonly StoreCheckoutGetCheckoutPath = '/store/checkout/{checkoutId}';
  static readonly StoreCheckoutResetCheckoutPath = '/store/checkout/{checkoutId}/reset';
  static readonly StoreCheckoutCurrentCheckoutStepPath = '/store/checkout/{checkoutId}/requiredstep';
  static readonly StoreCheckoutEmailExistsPath = '/store/checkout/{checkoutPId}/emailexists';
  static readonly StoreCheckoutSetAccountValuesPath = '/store/checkout/{checkoutId}/account';
  static readonly StoreCheckoutCreateDestinationPath = '/store/checkout/{checkoutId}/destination';
  static readonly StoreCheckoutUpdateDestinationPath = '/store/checkout/{checkoutId}/destination/{destinationId}';
  static readonly StoreCheckoutDeleteDestinationPath = '/store/checkout/{checkoutId}/destination/{destinationId}';
  static readonly StoreCheckoutGetDeliveriesPath = '/store/checkout/{checkoutId}/deliveries';
  static readonly StoreCheckoutCreateDeliveryPath = '/store/checkout/{checkoutId}/deliveries';
  static readonly StoreCheckoutUpdateDeliveryPath = '/store/checkout/{checkoutId}/deliveries/{deliveryId}';
  static readonly StoreCheckoutUpdateCheckoutItemPath = '/store/checkout/{checkoutId}/items/{checkoutItemId}';
  static readonly StoreCheckoutDeleteCheckoutItemPath = '/store/checkout/{checkoutId}/items/{checkoutItemId}';
  static readonly StoreCheckoutSetDeliveryPath = '/store/checkout/{checkoutId}/items';
  static readonly StoreCheckoutSetNotificationChannelsPath = '/store/checkout/{checkoutId}/notificationchannel';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutRequiredDataForDeliveryParams` containing the following parameters:
   *
   * - `destinationId`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutRequiredDataForDeliveryResponse(
    params: StoreCheckoutService.StoreCheckoutRequiredDataForDeliveryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfRequiredData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/destination/${encodeURIComponent(String(params.destinationId))}/requireddata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfRequiredData>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutRequiredDataForDeliveryParams` containing the following parameters:
   *
   * - `destinationId`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutRequiredDataForDelivery(
    params: StoreCheckoutService.StoreCheckoutRequiredDataForDeliveryParams,
  ): __Observable<ResponseArgsOfIEnumerableOfRequiredData> {
    return this.StoreCheckoutRequiredDataForDeliveryResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfRequiredData),
    );
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCreateOrRefreshCheckoutParams` containing the following parameters:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutCreateOrRefreshCheckoutResponse(
    params: StoreCheckoutService.StoreCheckoutCreateOrRefreshCheckoutParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/checkout/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCreateOrRefreshCheckoutParams` containing the following parameters:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutCreateOrRefreshCheckout(
    params: StoreCheckoutService.StoreCheckoutCreateOrRefreshCheckoutParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutCreateOrRefreshCheckoutResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutDiscardCheckoutParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutDiscardCheckoutResponse(
    params: StoreCheckoutService.StoreCheckoutDiscardCheckoutParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutDiscardCheckoutParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutDiscardCheckout(params: StoreCheckoutService.StoreCheckoutDiscardCheckoutParams): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutDiscardCheckoutResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutGetCheckoutParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutGetCheckoutResponse(
    params: StoreCheckoutService.StoreCheckoutGetCheckoutParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutGetCheckoutParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutGetCheckout(params: StoreCheckoutService.StoreCheckoutGetCheckoutParams): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutGetCheckoutResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutResetCheckoutParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutResetCheckoutResponse(
    params: StoreCheckoutService.StoreCheckoutResetCheckoutParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/reset`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutResetCheckoutParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutResetCheckout(params: StoreCheckoutService.StoreCheckoutResetCheckoutParams): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutResetCheckoutResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCurrentCheckoutStepParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutCurrentCheckoutStepResponse(
    params: StoreCheckoutService.StoreCheckoutCurrentCheckoutStepParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutStep>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/requiredstep`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutStep>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCurrentCheckoutStepParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutCurrentCheckoutStep(
    params: StoreCheckoutService.StoreCheckoutCurrentCheckoutStepParams,
  ): __Observable<ResponseArgsOfCheckoutStep> {
    return this.StoreCheckoutCurrentCheckoutStepResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutStep));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutEmailExistsParams` containing the following parameters:
   *
   * - `checkoutPId`:
   *
   * - `locale`:
   *
   * - `email`:
   *
   * - `checkoutId`:
   */
  StoreCheckoutEmailExistsResponse(
    params: StoreCheckoutService.StoreCheckoutEmailExistsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfNullableBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.email != null) __params = __params.set('email', params.email.toString());
    if (params.checkoutId != null) __params = __params.set('checkoutId', params.checkoutId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutPId))}/emailexists`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfNullableBoolean>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutEmailExistsParams` containing the following parameters:
   *
   * - `checkoutPId`:
   *
   * - `locale`:
   *
   * - `email`:
   *
   * - `checkoutId`:
   */
  StoreCheckoutEmailExists(params: StoreCheckoutService.StoreCheckoutEmailExistsParams): __Observable<ResponseArgsOfNullableBoolean> {
    return this.StoreCheckoutEmailExistsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfNullableBoolean));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutSetAccountValuesParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `accountValuesDTO`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutSetAccountValuesResponse(
    params: StoreCheckoutService.StoreCheckoutSetAccountValuesParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.accountValuesDTO;
    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/account`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutSetAccountValuesParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `accountValuesDTO`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutSetAccountValues(params: StoreCheckoutService.StoreCheckoutSetAccountValuesParams): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutSetAccountValuesResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCreateDestinationParams` containing the following parameters:
   *
   * - `destinationDTO`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutCreateDestinationResponse(
    params: StoreCheckoutService.StoreCheckoutCreateDestinationParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfDestinationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.destinationDTO;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/destination`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfDestinationDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCreateDestinationParams` containing the following parameters:
   *
   * - `destinationDTO`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutCreateDestination(
    params: StoreCheckoutService.StoreCheckoutCreateDestinationParams,
  ): __Observable<ResponseArgsOfDestinationDTO> {
    return this.StoreCheckoutCreateDestinationResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfDestinationDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutUpdateDestinationParams` containing the following parameters:
   *
   * - `destinationId`:
   *
   * - `destinationDTO`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutUpdateDestinationResponse(
    params: StoreCheckoutService.StoreCheckoutUpdateDestinationParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfDestinationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.destinationDTO;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/destination/${encodeURIComponent(String(params.destinationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfDestinationDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutUpdateDestinationParams` containing the following parameters:
   *
   * - `destinationId`:
   *
   * - `destinationDTO`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutUpdateDestination(
    params: StoreCheckoutService.StoreCheckoutUpdateDestinationParams,
  ): __Observable<ResponseArgsOfDestinationDTO> {
    return this.StoreCheckoutUpdateDestinationResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfDestinationDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutDeleteDestinationParams` containing the following parameters:
   *
   * - `destinationId`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutDeleteDestinationResponse(
    params: StoreCheckoutService.StoreCheckoutDeleteDestinationParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/destination/${encodeURIComponent(String(params.destinationId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutDeleteDestinationParams` containing the following parameters:
   *
   * - `destinationId`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutDeleteDestination(
    params: StoreCheckoutService.StoreCheckoutDeleteDestinationParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutDeleteDestinationResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutGetDeliveriesParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutGetDeliveriesResponse(
    params: StoreCheckoutService.StoreCheckoutGetDeliveriesParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfCheckoutDeliveryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/deliveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfCheckoutDeliveryDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutGetDeliveriesParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutGetDeliveries(
    params: StoreCheckoutService.StoreCheckoutGetDeliveriesParams,
  ): __Observable<ResponseArgsOfIEnumerableOfCheckoutDeliveryDTO> {
    return this.StoreCheckoutGetDeliveriesResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfCheckoutDeliveryDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCreateDeliveryParams` containing the following parameters:
   *
   * - `deliveryContainer`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutCreateDeliveryResponse(
    params: StoreCheckoutService.StoreCheckoutCreateDeliveryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDeliveryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.deliveryContainer;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/deliveries`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDeliveryDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutCreateDeliveryParams` containing the following parameters:
   *
   * - `deliveryContainer`:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutCreateDelivery(
    params: StoreCheckoutService.StoreCheckoutCreateDeliveryParams,
  ): __Observable<ResponseArgsOfCheckoutDeliveryDTO> {
    return this.StoreCheckoutCreateDeliveryResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDeliveryDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutUpdateDeliveryParams` containing the following parameters:
   *
   * - `deliveryId`:
   *
   * - `deliveryContainer`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutUpdateDeliveryResponse(
    params: StoreCheckoutService.StoreCheckoutUpdateDeliveryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.deliveryContainer;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/deliveries/${encodeURIComponent(String(params.deliveryId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutUpdateDeliveryParams` containing the following parameters:
   *
   * - `deliveryId`:
   *
   * - `deliveryContainer`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutUpdateDelivery(params: StoreCheckoutService.StoreCheckoutUpdateDeliveryParams): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutUpdateDeliveryResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutUpdateCheckoutItemParams` containing the following parameters:
   *
   * - `checkoutItemId`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `quantity`:
   *
   * - `locale`:
   *
   * - `deliveryId`:
   */
  StoreCheckoutUpdateCheckoutItemResponse(
    params: StoreCheckoutService.StoreCheckoutUpdateCheckoutItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.quantity != null) __params = __params.set('quantity', params.quantity.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.deliveryId != null) __params = __params.set('deliveryId', params.deliveryId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/items/${encodeURIComponent(String(params.checkoutItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutUpdateCheckoutItemParams` containing the following parameters:
   *
   * - `checkoutItemId`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `quantity`:
   *
   * - `locale`:
   *
   * - `deliveryId`:
   */
  StoreCheckoutUpdateCheckoutItem(
    params: StoreCheckoutService.StoreCheckoutUpdateCheckoutItemParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutUpdateCheckoutItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutDeleteCheckoutItemParams` containing the following parameters:
   *
   * - `checkoutItemId`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutDeleteCheckoutItemResponse(
    params: StoreCheckoutService.StoreCheckoutDeleteCheckoutItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/items/${encodeURIComponent(String(params.checkoutItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutDeleteCheckoutItemParams` containing the following parameters:
   *
   * - `checkoutItemId`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutDeleteCheckoutItem(
    params: StoreCheckoutService.StoreCheckoutDeleteCheckoutItemParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutDeleteCheckoutItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutSetDeliveryParams` containing the following parameters:
   *
   * - `items`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   *
   * - `deliveryId`:
   */
  StoreCheckoutSetDeliveryResponse(
    params: StoreCheckoutService.StoreCheckoutSetDeliveryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.items;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.deliveryId != null) __params = __params.set('deliveryId', params.deliveryId.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutSetDeliveryParams` containing the following parameters:
   *
   * - `items`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   *
   * - `deliveryId`:
   */
  StoreCheckoutSetDelivery(params: StoreCheckoutService.StoreCheckoutSetDeliveryParams): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutSetDeliveryResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutService.StoreCheckoutSetNotificationChannelsParams` containing the following parameters:
   *
   * - `notificationChannel`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutSetNotificationChannelsResponse(
    params: StoreCheckoutService.StoreCheckoutSetNotificationChannelsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.notificationChannel;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/notificationchannel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutService.StoreCheckoutSetNotificationChannelsParams` containing the following parameters:
   *
   * - `notificationChannel`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutSetNotificationChannels(
    params: StoreCheckoutService.StoreCheckoutSetNotificationChannelsParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutSetNotificationChannelsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }
}

module StoreCheckoutService {
  /**
   * Parameters for StoreCheckoutRequiredDataForDelivery
   */
  export interface StoreCheckoutRequiredDataForDeliveryParams {
    destinationId: number;
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutCreateOrRefreshCheckout
   */
  export interface StoreCheckoutCreateOrRefreshCheckoutParams {
    shoppingCartId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutDiscardCheckout
   */
  export interface StoreCheckoutDiscardCheckoutParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutGetCheckout
   */
  export interface StoreCheckoutGetCheckoutParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutResetCheckout
   */
  export interface StoreCheckoutResetCheckoutParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutCurrentCheckoutStep
   */
  export interface StoreCheckoutCurrentCheckoutStepParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutEmailExists
   */
  export interface StoreCheckoutEmailExistsParams {
    checkoutPId: string;
    locale?: null | string;
    email?: null | string;
    checkoutId?: number;
  }

  /**
   * Parameters for StoreCheckoutSetAccountValues
   */
  export interface StoreCheckoutSetAccountValuesParams {
    checkoutId: number;
    accountValuesDTO: UserAccountDTO;
    returnCheckout?: boolean;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutCreateDestination
   */
  export interface StoreCheckoutCreateDestinationParams {
    destinationDTO: DestinationDTO;
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutUpdateDestination
   */
  export interface StoreCheckoutUpdateDestinationParams {
    destinationId: number;
    destinationDTO: DestinationDTO;
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutDeleteDestination
   */
  export interface StoreCheckoutDeleteDestinationParams {
    destinationId: number;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutGetDeliveries
   */
  export interface StoreCheckoutGetDeliveriesParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutCreateDelivery
   */
  export interface StoreCheckoutCreateDeliveryParams {
    deliveryContainer: DeliveryContainer;
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutUpdateDelivery
   */
  export interface StoreCheckoutUpdateDeliveryParams {
    deliveryId: number;
    deliveryContainer: DeliveryContainer;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutUpdateCheckoutItem
   */
  export interface StoreCheckoutUpdateCheckoutItemParams {
    checkoutItemId: number;
    checkoutId: number;
    returnCheckout?: boolean;
    quantity?: null | number;
    locale?: null | string;
    deliveryId?: number;
  }

  /**
   * Parameters for StoreCheckoutDeleteCheckoutItem
   */
  export interface StoreCheckoutDeleteCheckoutItemParams {
    checkoutItemId: number;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutSetDelivery
   */
  export interface StoreCheckoutSetDeliveryParams {
    items: Array<KeyValuePairOfEntityDTOContainerOfCheckoutItemDTOAndNullableDecimal>;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
    deliveryId?: number;
  }

  /**
   * Parameters for StoreCheckoutSetNotificationChannels
   */
  export interface StoreCheckoutSetNotificationChannelsParams {
    notificationChannel: NotificationChannel;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
  }
}

export { StoreCheckoutService };
