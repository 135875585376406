import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterInputChipComponent } from './filter-input-chip.component';

@NgModule({
  imports: [CommonModule],
  exports: [FilterInputChipComponent],
  declarations: [FilterInputChipComponent],
})
export class FilterInputChipModule {}
