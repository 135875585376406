import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterInputOptionsComponent } from './filter-input-options.component';
import { FilterInputOptionBoolModule } from './filter-input-option-bool';
import { UiIconModule } from '@ui/icon';
import { FilterInputOptionDateRangeModule } from './filter-input-option-date-range';
import { InputOptionTriStateModule } from './filter-input-option-tri-state';
import { InputOptionNumberRangeModule } from './filter-input-option-number-range';

@NgModule({
  imports: [
    CommonModule,
    UiIconModule,
    FilterInputOptionBoolModule,
    FilterInputOptionDateRangeModule,
    InputOptionTriStateModule,
    InputOptionNumberRangeModule,
  ],
  exports: [FilterInputOptionsComponent],
  declarations: [FilterInputOptionsComponent],
})
export class FilterInputOptionsModule {}
