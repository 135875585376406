import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu.component';
import { MenuItemDirective } from './menu-item.directive';
import { MenuTemplateComponent } from './menu-template.component';
import { MenuTriggerDirective } from './menu-trigger.directive';

@NgModule({
  imports: [CommonModule],
  exports: [MenuComponent, MenuItemDirective, MenuTemplateComponent, MenuTriggerDirective],
  declarations: [MenuComponent, MenuItemDirective, MenuTemplateComponent, MenuTriggerDirective],
})
export class SharedMenuModule {}
