/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { RemiConfiguration as __Configuration } from '../remi-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfPackageDTO } from '../models/list-response-args-of-package-dto';
import { ResponseArgsOfPackageDTO } from '../models/response-args-of-package-dto';
import { PackageDTO } from '../models/package-dto';
import { ResponseArgsOfNullableBoolean } from '../models/response-args-of-nullable-boolean';
@Injectable({
  providedIn: 'root',
})
class PackageService extends __BaseService {
  static readonly PackageGetPackagesPath = '/inventory/package';
  static readonly PackageCreatePackagePath = '/inventory/package';
  static readonly PackageUpdatePackagePath = '/inventory/package/{packageId}';
  static readonly PackagePatchPackagePath = '/inventory/package/{packageId}';
  static readonly PackageDeletePackagePath = '/inventory/package/{packageId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `PackageService.PackageGetPackagesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`:
   *
   * - `labelKey`:
   */
  PackageGetPackagesResponse(
    params: PackageService.PackageGetPackagesParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfPackageDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.labelKey != null) __params = __params.set('labelKey', params.labelKey.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/package`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfPackageDTO>;
      }),
    );
  }
  /**
   * @param params The `PackageService.PackageGetPackagesParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`:
   *
   * - `labelKey`:
   */
  PackageGetPackages(params: PackageService.PackageGetPackagesParams): __Observable<ListResponseArgsOfPackageDTO> {
    return this.PackageGetPackagesResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfPackageDTO));
  }

  /**
   * @param params The `PackageService.PackageCreatePackageParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  PackageCreatePackageResponse(
    params: PackageService.PackageCreatePackageParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfPackageDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/package`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPackageDTO>;
      }),
    );
  }
  /**
   * @param params The `PackageService.PackageCreatePackageParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  PackageCreatePackage(params: PackageService.PackageCreatePackageParams): __Observable<ResponseArgsOfPackageDTO> {
    return this.PackageCreatePackageResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfPackageDTO));
  }

  /**
   * @param params The `PackageService.PackageUpdatePackageParams` containing the following parameters:
   *
   * - `packageId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  PackageUpdatePackageResponse(
    params: PackageService.PackageUpdatePackageParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfPackageDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/inventory/package/${encodeURIComponent(String(params.packageId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPackageDTO>;
      }),
    );
  }
  /**
   * @param params The `PackageService.PackageUpdatePackageParams` containing the following parameters:
   *
   * - `packageId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  PackageUpdatePackage(params: PackageService.PackageUpdatePackageParams): __Observable<ResponseArgsOfPackageDTO> {
    return this.PackageUpdatePackageResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfPackageDTO));
  }

  /**
   * @param params The `PackageService.PackagePatchPackageParams` containing the following parameters:
   *
   * - `packageId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  PackagePatchPackageResponse(
    params: PackageService.PackagePatchPackageParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfPackageDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/inventory/package/${encodeURIComponent(String(params.packageId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPackageDTO>;
      }),
    );
  }
  /**
   * @param params The `PackageService.PackagePatchPackageParams` containing the following parameters:
   *
   * - `packageId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  PackagePatchPackage(params: PackageService.PackagePatchPackageParams): __Observable<ResponseArgsOfPackageDTO> {
    return this.PackagePatchPackageResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfPackageDTO));
  }

  /**
   * @param params The `PackageService.PackageDeletePackageParams` containing the following parameters:
   *
   * - `packageId`:
   *
   * - `locale`:
   */
  PackageDeletePackageResponse(
    params: PackageService.PackageDeletePackageParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfNullableBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/inventory/package/${encodeURIComponent(String(params.packageId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfNullableBoolean>;
      }),
    );
  }
  /**
   * @param params The `PackageService.PackageDeletePackageParams` containing the following parameters:
   *
   * - `packageId`:
   *
   * - `locale`:
   */
  PackageDeletePackage(params: PackageService.PackageDeletePackageParams): __Observable<ResponseArgsOfNullableBoolean> {
    return this.PackageDeletePackageResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfNullableBoolean));
  }
}

module PackageService {
  /**
   * Parameters for PackageGetPackages
   */
  export interface PackageGetPackagesParams {
    take?: null | number;
    skip?: null | number;
    locale?: null | string;
    labelKey?: null | string;
  }

  /**
   * Parameters for PackageCreatePackage
   */
  export interface PackageCreatePackageParams {
    data: PackageDTO;
    locale?: null | string;
  }

  /**
   * Parameters for PackageUpdatePackage
   */
  export interface PackageUpdatePackageParams {
    packageId: number;
    data: PackageDTO;
    locale?: null | string;
  }

  /**
   * Parameters for PackagePatchPackage
   */
  export interface PackagePatchPackageParams {
    packageId: number;
    data: PackageDTO;
    locale?: null | string;
  }

  /**
   * Parameters for PackageDeletePackage
   */
  export interface PackageDeletePackageParams {
    packageId: number;
    locale?: null | string;
  }
}

export { PackageService };
