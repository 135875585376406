import { inject, Injectable } from '@angular/core';
import { PromptModalData, UiModalService, UiPromptModalComponent } from '@ui/modal';
import { Observable } from 'rxjs';
import { ScanAdapter } from './scan-adapter';
import { Config } from '@core/config';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Injectable()
export class DevScanAdapter implements ScanAdapter {
  readonly name = 'Dev';

  private _modal = inject(UiModalService);

  private _config = inject(Config);

  async init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      resolve(coerceBooleanProperty(this._config.get('dev-scanner')));
    });
  }

  scan(): Observable<string> {
    return new Observable((observer) => {
      const modalRef = this._modal.open({
        content: UiPromptModalComponent,
        title: 'Scannen',
        data: {
          message: 'Diese Eingabemaske dient nur zu Entwicklungs und Testzwecken.',
          placeholder: 'Scan Code',
          confirmText: 'weiter',
          cancelText: 'abbrechen',
        } as PromptModalData,
      });

      const sub = modalRef.afterClosed$.subscribe((result) => {
        observer.next(result.data);
        observer.complete();
      });

      return () => {
        modalRef.close();
        sub.unsubscribe();
      };
    });
  }
}
