import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, inject } from '@angular/core';
import { CustomerSearchStore } from '../store';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Subject, combineLatest } from 'rxjs';
import { AsyncPipe, CurrencyPipe, DatePipe, NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { OrderProcessingStatusPipe } from '@shared/pipes/order';
import { BranchNamePipe, ResolveBranchPipe } from '@shared/pipes/branch';
import { AddressPipe } from '@shared/pipes/customer';
import { IconComponent } from '@shared/components/icon';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { CustomerOrderItemListItemComponent } from './order-item-list-item/order-item-list-item.component';
import { GroupByPipe, groupBy } from '@ui/common';
import { EnvironmentService } from '@core/environment';

@Component({
  selector: 'page-customer-order-details-main-view',
  templateUrl: 'order-details-main-view.component.html',
  styleUrls: ['order-details-main-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-order-details-main-view' },
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    DatePipe,
    OrderProcessingStatusPipe,
    ResolveBranchPipe,
    BranchNamePipe,
    CurrencyPipe,
    IconComponent,
    RouterLink,
    CustomerOrderItemListItemComponent,
    NgFor,
    GroupByPipe,
    NgSwitch,
    NgSwitchCase,
    NgSwitchDefault,
    AddressPipe,
  ],
})
export class OrderDetailsMainViewComponent implements OnInit, OnDestroy {
  private _activateRoute = inject(ActivatedRoute);
  private _store = inject(CustomerSearchStore);
  private _navigation = inject(CustomerSearchNavigation);
  private _env = inject(EnvironmentService);

  orderId$ = this._activateRoute.params.pipe(map((params) => Number(params.orderId)));

  order$ = this._store.order$;

  orderTargetBranch$ = this.order$.pipe(map((order) => order?.targetBranch?.id));

  orderShippingTarget$ = this.order$.pipe(map((order) => order?.shipping?.data));

  customerId$ = this._activateRoute.params.pipe(map((params) => Number(params.customerId)));

  customer$ = this._store.customer$;

  accountType$ = this.customer$.pipe(map((customer) => customer?.features?.find((feature) => feature.group === 'd-customertype')));

  accountTypeKey$ = this.accountType$.pipe(map((accountType) => accountType?.key));

  accountTypeDescription$ = this.accountType$.pipe(map((accountType) => accountType?.description));

  orderItemId$ = this._activateRoute.params.pipe(map((params) => Number(params.orderItemId)));

  orderItems$ = this.order$.pipe(map((order) => order?.items?.map((i) => i?.data)));

  selectedOrderItem$ = this._store.selectedOrderItem$;

  selectedOrderItemOrderType$ = this.selectedOrderItem$.pipe(map((orderItem) => orderItem?.features?.orderType));

  private _onDestroy = new Subject<void>();

  ordersRoute$ = combineLatest([this.customerId$, this._store.processId$]).pipe(
    map(([customerId, processId]) => this._navigation.ordersRoute({ processId, customerId })),
  );

  orderDetailsHistoryRoute$ = combineLatest([this.customerId$, this._store.processId$, this.orderId$, this.orderItemId$]).pipe(
    map(([customerId, processId, orderId, orderItemId]) =>
      this._navigation.orderDetailsHistoryRoute({ processId, customerId, orderId, orderItemId }),
    ),
  );

  groupedOrderItemsByOrderType$ = this.orderItems$.pipe(
    map((orderItems) => groupBy(orderItems, (orderItem) => orderItem?.features?.orderType)),
    tap((groupedOrderItems) => console.log(groupedOrderItems)),
  );

  showSelectedItem$ = this._env.matchDesktopXLarge$;

  showItemList$ = this.showSelectedItem$.pipe(map((showSelectedItem) => !showSelectedItem));

  ngOnInit(): void {
    this.orderId$.pipe(takeUntil(this._onDestroy)).subscribe((orderId) => {
      this._store.selectOrder(orderId);
    });

    this.customerId$.pipe(takeUntil(this._onDestroy)).subscribe((customerId) => {
      this._store.selectCustomer({ customerId });
    });
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
