/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Oms services
 */
@Injectable({
  providedIn: 'root',
})
export class OmsConfiguration {
  rootUrl: string = 'https://isa-test.paragon-data.net/oms/v6';
}

export interface OmsConfigurationInterface {
  rootUrl?: string;
}
