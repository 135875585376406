import { enableProdMode, isDevMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CONFIG_DATA } from '@isa/core/config';
import * as moment from 'moment';

moment.locale('de');

import { AppModule } from './app/app.module';

if (!isDevMode()) {
  enableProdMode();
}

async function bootstrap() {
  const configRes = await fetch('/config/config.json');

  const config = await configRes.json();

  platformBrowserDynamic([{ provide: CONFIG_DATA, useValue: config }]).bootstrapModule(AppModule);
}

try {
  bootstrap();
} catch (error) {
  console.error(error);
}

