import { Component, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { CustomerDTO } from '@swagger/crm';
import { DeviatingAddressFormBlockComponent } from '../../components/form-blocks';
import { AddressFormBlockComponent, AddressFormBlockData } from '../../components/form-blocks/address';
import { NameFormBlockData } from '../../components/form-blocks/name/name-form-block-data';
import { validateEmail } from '../../validators/email-validator';
import { AbstractCreateCustomer } from '../abstract-create-customer';
import { zipCodeValidator } from '../../validators/zip-code-validator';

@Component({
  selector: 'app-create-guest-customer',
  templateUrl: 'create-guest-customer.component.html',
  styleUrls: ['../create-customer.scss', 'create-guest-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateGuestCustomerComponent extends AbstractCreateCustomer {
  customerType = 'guest';

  validateAddress = true;

  validateShippingAddress = true;

  nameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  nameValidationFns: Record<keyof NameFormBlockData, ValidatorFn[]> = {
    firstName: [Validators.required],
    lastName: [Validators.required],
    gender: [Validators.required],
    title: [],
  };

  addressRequiredMarks: (keyof AddressFormBlockData)[] = ['street', 'streetNumber', 'zipCode', 'city', 'country'];

  addressValidators: Record<string, ValidatorFn[]> = {
    street: [Validators.required],
    streetNumber: [Validators.required],
    zipCode: [Validators.required, zipCodeValidator()],
    city: [Validators.required],
    country: [Validators.required],
  };

  emailFormBlockValidators = [Validators.email, validateEmail, Validators.required];

  deviatingNameRequiredMarks: (keyof NameFormBlockData)[] = ['gender', 'firstName', 'lastName'];

  deviatingNameValidationFns: Record<string, ValidatorFn[]> = {
    gender: [Validators.required],
    firstName: [Validators.required],
    lastName: [Validators.required],
  };

  @ViewChild(AddressFormBlockComponent, { static: false })
  addressFormBlock: AddressFormBlockComponent;

  @ViewChild(DeviatingAddressFormBlockComponent, { static: false })
  deviatingDeliveryAddressFormBlock: DeviatingAddressFormBlockComponent;

  async saveCustomer(customer: CustomerDTO): Promise<CustomerDTO> {
    const res = await this.customerService.createGuestCustomer(customer);

    return res.result;
  }
}
