import { Subject } from 'rxjs';
import { FilterInput } from './input';
import { IOption, Option } from './option';

export interface IInputOptions {
  readonly max?: number;
  readonly values?: Array<IOption>;
}

export class InputOptions implements IInputOptions {
  //#region implements IUiFilterInputOptions
  private _max?: number;
  get max() {
    return this._max;
  }

  private _values?: Array<Option>;
  get values() {
    return this._values;
  }
  //#endregion

  readonly changes = new Subject<{ keys: (keyof IInputOptions)[]; inputOptions: InputOptions }>();

  constructor(public readonly parent?: FilterInput) {}

  getSelectedOptions(): Option[] {
    return this.values?.map((o) => o.getSelectedOptions()).reduce((agg, options) => [...agg, ...options], []) || [];
  }

  getUnselectedOptions(): Option[] {
    return this.values?.map((o) => o.getUnselectedOptions()).reduce((agg, options) => [...agg, ...options], []) || [];
  }

  toObject(): IInputOptions {
    return {
      max: this.max,
      values: this.values?.map((value) => value.toObject()),
    };
  }

  static create(inputOptions: IInputOptions, parent?: FilterInput) {
    const target = new InputOptions(parent);

    target._max = inputOptions?.max;
    target._values = inputOptions?.values?.map((value) => Option.create(value, target));

    return target;
  }
}
