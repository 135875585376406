/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfCheckoutDTO } from '../models/response-args-of-checkout-dto';
import { BuyerDTO } from '../models/buyer-dto';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutBuyerService extends __BaseService {
  static readonly StoreCheckoutBuyerSetBuyerPOSTPath = '/store/checkout/{checkoutId}/buyer';
  static readonly StoreCheckoutBuyerSetBuyerPUTPath = '/store/checkout/{checkoutId}/buyer';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPOSTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `buyerDTO`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutBuyerSetBuyerPOSTResponse(
    params: StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPOSTParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.buyerDTO;
    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/buyer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPOSTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `buyerDTO`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutBuyerSetBuyerPOST(
    params: StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPOSTParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutBuyerSetBuyerPOSTResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPUTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `buyerDTO`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutBuyerSetBuyerPUTResponse(
    params: StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPUTParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.buyerDTO;
    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/buyer`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPUTParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `buyerDTO`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutBuyerSetBuyerPUT(
    params: StoreCheckoutBuyerService.StoreCheckoutBuyerSetBuyerPUTParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutBuyerSetBuyerPUTResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }
}

module StoreCheckoutBuyerService {
  /**
   * Parameters for StoreCheckoutBuyerSetBuyerPOST
   */
  export interface StoreCheckoutBuyerSetBuyerPOSTParams {
    checkoutId: number;
    buyerDTO: BuyerDTO;
    returnCheckout?: boolean;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutBuyerSetBuyerPUT
   */
  export interface StoreCheckoutBuyerSetBuyerPUTParams {
    checkoutId: number;
    buyerDTO: BuyerDTO;
    returnCheckout?: boolean;
    locale?: null | string;
  }
}

export { StoreCheckoutBuyerService };
