<ng-container *ngIf="orderByKeys">
  <div class="group w-full flex flex-row justify-between" *ngFor="let group of groupedOrderByKeys">
    <button
      *ngFor="let label of group.keys"
      [attr.data-label]="label"
      class="order-by-filter-button"
      type="button"
      (click)="setActive(label)"
    >
      <span>
        {{ label }}
      </span>
      <ui-icon
        [class.asc]="label === activeOrderBy?.label && !activeOrderBy.desc"
        [class.desc]="label === activeOrderBy?.label && activeOrderBy.desc"
        icon="arrow"
        size="10px"
      ></ui-icon>
    </button>
  </div>
</ng-container>
