import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ConfigLoader } from './config-loader';
import { CORE_JSON_CONFIG_LOADER_URL } from '../tokens';

@Injectable()
export class JsonConfigLoader implements ConfigLoader {
  constructor(
    @Inject(CORE_JSON_CONFIG_LOADER_URL) private url: string,
    private http: HttpClient,
  ) {}

  load(): Promise<any> {
    return this.http.get(this.url).toPromise();
  }
}
