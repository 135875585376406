import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedHistoryListModule } from '@shared/components/history';

import { CustomerHistoryMainViewComponent } from './history-main-view.component';
import { UiIconModule } from '@ui/icon';
import { RouterModule } from '@angular/router';
import { LoaderModule } from '@shared/components/loader';
import { CustomerMenuComponent } from '../../components/customer-menu';

@NgModule({
  imports: [CommonModule, RouterModule, SharedHistoryListModule, UiIconModule, LoaderModule, CustomerMenuComponent],
  exports: [CustomerHistoryMainViewComponent],
  declarations: [CustomerHistoryMainViewComponent],
})
export class CustomerHistoryMainViewModule {}
