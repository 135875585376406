/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { RemiConfiguration as __Configuration } from '../remi-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfSupplierDTO } from '../models/list-response-args-of-supplier-dto';
import { ResponseArgsOfIEnumerableOfSupplierDTO } from '../models/response-args-of-ienumerable-of-supplier-dto';
@Injectable({
  providedIn: 'root',
})
class SupplierService extends __BaseService {
  static readonly SupplierGetSuppliersPath = '/inventory/stock/{stockId}/supplier';
  static readonly SupplierGetSuppliers2Path = '/inventory/supplier';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `SupplierService.SupplierGetSuppliersParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`:
   */
  SupplierGetSuppliersResponse(
    params: SupplierService.SupplierGetSuppliersParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfSupplierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/supplier`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfSupplierDTO>;
      }),
    );
  }
  /**
   * @param params The `SupplierService.SupplierGetSuppliersParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`:
   */
  SupplierGetSuppliers(params: SupplierService.SupplierGetSuppliersParams): __Observable<ListResponseArgsOfSupplierDTO> {
    return this.SupplierGetSuppliersResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfSupplierDTO));
  }
  SupplierGetSuppliers2Response(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfSupplierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/supplier`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfSupplierDTO>;
      }),
    );
  }
  SupplierGetSuppliers2(): __Observable<ResponseArgsOfIEnumerableOfSupplierDTO> {
    return this.SupplierGetSuppliers2Response().pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfSupplierDTO));
  }
}

module SupplierService {
  /**
   * Parameters for SupplierGetSuppliers
   */
  export interface SupplierGetSuppliersParams {
    stockId: number;
    take?: null | number;
    skip?: null | number;
    locale?: null | string;
  }
}

export { SupplierService };
