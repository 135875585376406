<shared-filter-filter-group-main [inputGroup]="uiFilter?.filter | group: 'main'"></shared-filter-filter-group-main>
<shared-filter-input-group-main
  *ngIf="uiFilter?.input | group: 'main'; let inputGroupMain"
  [inputGroup]="inputGroupMain"
  (search)="emitSearch($event)"
  [loading]="loading"
  [hint]="hint"
  [scanner]="scanner"
></shared-filter-input-group-main>
<shared-filter-filter-group-filter [inputGroup]="uiFilter?.filter | group: 'filter'"></shared-filter-filter-group-filter>
