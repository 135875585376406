/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { AvConfiguration as __Configuration } from '../av-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfWebshopAvailabilityDTO } from '../models/response-args-of-ienumerable-of-webshop-availability-dto';
import { WebshopAvailabilityRequestDTO } from '../models/webshop-availability-request-dto';
@Injectable({
  providedIn: 'root',
})
class WebshopAvailabilityService extends __BaseService {
  static readonly WebshopAvailabilityWebshopAvailabilityPath = '/availability/webshop';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Verfügbarkeit für Webshop
   * @param payload undefined
   */
  WebshopAvailabilityWebshopAvailabilityResponse(
    payload: WebshopAvailabilityRequestDTO,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfWebshopAvailabilityDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/availability/webshop`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfWebshopAvailabilityDTO>;
      }),
    );
  }
  /**
   * Verfügbarkeit für Webshop
   * @param payload undefined
   */
  WebshopAvailabilityWebshopAvailability(
    payload: WebshopAvailabilityRequestDTO,
  ): __Observable<ResponseArgsOfIEnumerableOfWebshopAvailabilityDTO> {
    return this.WebshopAvailabilityWebshopAvailabilityResponse(payload).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfWebshopAvailabilityDTO),
    );
  }
}

module WebshopAvailabilityService {}

export { WebshopAvailabilityService };
