import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterComponent } from './filter.component';
import { InputGroupSelectorPipe } from './pipe';
import { FilterInputGroupMainModule } from './filter-group/filter-input-group-main';
import { FilterFilterGroupMainModule } from './filter-group/filter-filter-group-main';
import { FilterFilterGroupFilterModule } from './filter-group/filter-filter-group-filter';
import { FilterInputModule } from './shared/filter-input';

@NgModule({
  imports: [CommonModule, FilterInputGroupMainModule, FilterFilterGroupMainModule, FilterFilterGroupFilterModule, FilterInputModule],
  exports: [
    FilterComponent,
    InputGroupSelectorPipe,
    FilterInputGroupMainModule,
    FilterFilterGroupMainModule,
    FilterFilterGroupFilterModule,
    FilterInputModule,
  ],
  declarations: [FilterComponent, InputGroupSelectorPipe],
})
export class FilterModule {}
