import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';

import { SearchboxModule } from '@shared/components/searchbox';

import { CustomerResultListComponent } from './customer-result-list.component';
import { CustomerResultListItemFullComponent } from './customer-result-list-item-full/customer-result-list-item-full.component';
import { CustomerResultListItemComponent } from './customer-result-list-item/customer-result-list-item.component';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon';
import { CustomerNamePipe } from '@shared/pipes/customer';

@NgModule({
  imports: [IconComponent, CommonModule, SearchboxModule, ScrollingModule, RouterModule, CustomerNamePipe],
  exports: [CustomerResultListComponent, CustomerResultListComponent, CustomerResultListItemFullComponent, CustomerResultListItemComponent],
  declarations: [
    CustomerResultListComponent,
    CustomerResultListComponent,
    CustomerResultListItemFullComponent,
    CustomerResultListItemComponent,
  ],
})
export class CustomerResultListModule {}
