import { animate, state, style, transition, trigger } from '@angular/animations';
import { DecimalPipe, NgIf } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { IconComponent } from '@shared/components/icon';
import { BonusCardInfoDTO } from '@swagger/crm';

@Component({
  selector: 'page-customer-kundenkarte',
  templateUrl: 'kundenkarte.component.html',
  styleUrls: ['kundenkarte.component.scss'],
  standalone: true,
  imports: [IconComponent, NgIf, DecimalPipe],
  animations: [
    trigger('cardFlip', [
      state(
        'front',
        style({
          transform: 'rotateY(0deg)',
        }),
      ),
      state(
        'back',
        style({
          transform: 'rotateY(0deg)',
        }),
      ),
      state(
        'flip',
        style({
          transform: 'rotateY(90deg)',
        }),
      ),
      transition('front <=> flip', [animate('400ms')]),
      transition('back <=> flip', [animate('400ms')]),
    ]),
  ],
})
export class KundenkarteComponent implements OnInit {
  @Input() cardDetails: BonusCardInfoDTO;
  @Input() isCustomerCard: boolean;
  frontside: boolean;
  state: 'front' | 'flip' | 'back' = 'front';

  constructor() {}

  ngOnInit() {
    this.frontside = true;
  }

  onRewardShop(): void {}

  onDeletePartnerCard(): void {}

  flipCard() {
    this.state = 'flip';
  }

  flipAnimationDone(event: any) {
    if (event.fromState === 'front') {
      this.frontside = false;
      this.state = 'back';
    } else if (event.fromState === 'back') {
      this.frontside = true;
      this.state = 'front';
    }
  }
}
