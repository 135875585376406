<cdk-virtual-scroll-viewport
  itemSize="100"
  class="h-[calc(100vh-20.125rem)] desktop-small:h-[calc(100vh-18.625rem)]"
  *ngIf="!compact"
  (scrolledIndexChange)="scrolledIndexChange($event)"
>
  <a
    *cdkVirtualFor="let customer of customers; trackBy: trackByFn; let index = index"
    [routerLink]="customerSearchNavigation.detailsRoute({ processId: processId, customerId: customer.id })?.path"
    [queryParams]="customerSearchNavigation.detailsRoute({ processId: processId, customerId: customer.id })?.queryParams"
    [queryParamsHandling]="'merge'"
    (click)="scrolledIndexChange(index)"
    routerLinkActive
    #rla="routerLinkActive"
  >
    <page-customer-result-list-item-full [class.active]="rla.isActive" [customer]="customer"></page-customer-result-list-item-full>
  </a>
  <div class="h-[6.125rem] bg-white rounded px-4 py-3" *ngIf="hits === customers?.length && !fetching">
    <ng-container *ngTemplateOutlet="customerNotFound"></ng-container>
  </div>
</cdk-virtual-scroll-viewport>

<cdk-virtual-scroll-viewport
  itemSize="191"
  class="h-[calc(100vh-20.75rem)]"
  *ngIf="compact"
  (scrolledIndexChange)="scrolledIndexChange($event)"
>
  <a
    *cdkVirtualFor="let customer of customers; trackBy: trackByFn; let index = index"
    [routerLink]="customerSearchNavigation.detailsRoute({ processId: processId, customerId: customer.id })?.path"
    [queryParams]="customerSearchNavigation.detailsRoute({ processId: processId, customerId: customer.id })?.queryParams"
    [queryParamsHandling]="'merge'"
    (click)="scrolledIndexChange(index)"
    routerLinkActive
    #rla="routerLinkActive"
  >
    <page-customer-result-list-item [class.active]="rla.isActive" [customer]="customer"></page-customer-result-list-item>
  </a>
  <div class="h-[11.3125rem] bg-white rounded px-4 py-3" *ngIf="hits === customers?.length && !fetching">
    <ng-container *ngTemplateOutlet="customerNotFound"></ng-container>
  </div>
</cdk-virtual-scroll-viewport>
<ng-template #customerNotFound>
  <div class="text-sm">
    Hinweis: Aus Datenschutzgründen werden nur Teilinformationen dargestellt. Tab auf einen Kunden um mehr zu erfahren.
  </div>
  <div class="font-bold text-lg mt-3">
    <span> Kunden nicht gefunden? </span>
    <a
      *ngIf="customerCreateNavigation.defaultRoute({ processId: processId }); let route"
      [routerLink]="route.path"
      [queryParams]="route.queryParams"
      class="text-brand"
      >Neue Kundendaten erfassen</a
    >
  </div>
</ng-template>
