/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfSupplierDTO } from '../models/response-args-of-ienumerable-of-supplier-dto';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutSupplierService extends __BaseService {
  static readonly StoreCheckoutSupplierGetSuppliersPath = '/store/suppliers';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutSupplierService.StoreCheckoutSupplierGetSuppliersParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`:
   */
  StoreCheckoutSupplierGetSuppliersResponse(
    params: StoreCheckoutSupplierService.StoreCheckoutSupplierGetSuppliersParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfSupplierDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/store/suppliers`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfSupplierDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutSupplierService.StoreCheckoutSupplierGetSuppliersParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `locale`:
   */
  StoreCheckoutSupplierGetSuppliers(
    params: StoreCheckoutSupplierService.StoreCheckoutSupplierGetSuppliersParams,
  ): __Observable<ResponseArgsOfIEnumerableOfSupplierDTO> {
    return this.StoreCheckoutSupplierGetSuppliersResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfSupplierDTO));
  }
}

module StoreCheckoutSupplierService {
  /**
   * Parameters for StoreCheckoutSupplierGetSuppliers
   */
  export interface StoreCheckoutSupplierGetSuppliersParams {
    take?: null | number;
    skip?: null | number;
    locale?: null | string;
  }
}

export { StoreCheckoutSupplierService };
