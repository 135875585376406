import { inject, Injectable } from '@angular/core';
import { removeProcess } from '@core/application';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { delay, map } from 'rxjs';
import { removeCheckoutWithProcessId } from './domain-checkout.actions';

@Injectable()
export class DomainCheckoutEffects {
  readonly actions = inject(Actions);

  removeprocess$ = createEffect(() =>
    this.actions.pipe(
      ofType(removeProcess),
      delay(250), // Nicht entfernen, wenn der Prozess zu schnell entfernt wird erstellt eine vorhande Subscription einen neuen Prozess
      map((action) => removeCheckoutWithProcessId({ processId: action.processId })),
    ),
  );
}
