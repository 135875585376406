import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  ContentChildren,
  QueryList,
} from '@angular/core';
import { FilterInputGroupMainComponent } from './filter-group/filter-input-group-main';
import { FilterCustomInputDirective } from './shared/filter-input';
import { IFilter, Filter } from './tree/filter';

@Component({
  selector: 'shared-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterComponent implements OnChanges {
  @Input()
  loading: boolean;

  @Input()
  hint: string;

  @Output()
  search = new EventEmitter<string>();

  @Input()
  filter: IFilter;

  @Input()
  scanner = false;

  @ViewChild(FilterInputGroupMainComponent)
  filterInputGroupMainComponent: FilterInputGroupMainComponent;

  @ContentChildren(FilterCustomInputDirective)
  customInputs: QueryList<FilterCustomInputDirective>;

  get uiFilter() {
    return this.filter instanceof Filter && this.filter;
  }

  constructor() {}

  ngOnChanges({ filter }: SimpleChanges): void {
    if (filter) {
      if (!(this.filter instanceof Filter)) {
        this.filter = Filter.create(this.filter);
      }
    }
  }

  emitSearch(query: string) {
    setTimeout(() => {
      this.search.emit(query);
    }, 1);
  }

  cancelAutocomplete() {
    this.filterInputGroupMainComponent?.cancelAutocomplete();
  }
}
