/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for Remi services
 */
@Injectable({
  providedIn: 'root',
})
export class RemiConfiguration {
  rootUrl: string = 'https://isa-test.paragon-data.net/inv/v6';
}

export interface RemiConfigurationInterface {
  rootUrl?: string;
}
