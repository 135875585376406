/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfOrderDTO } from '../models/response-args-of-order-dto';
import { OrderDTO } from '../models/order-dto';
import { ResponseArgsOfBoolean } from '../models/response-args-of-boolean';
import { ResponseArgsOfOrderItemDTO } from '../models/response-args-of-order-item-dto';
import { OrderItemSubsetDTO } from '../models/order-item-subset-dto';
import { ResponseArgsOfOrderItemSubsetDTO } from '../models/response-args-of-order-item-subset-dto';
import { ResponseArgsOfIEnumerableOfOrderDTO } from '../models/response-args-of-ienumerable-of-order-dto';
import { ListResponseArgsOfOrderListItemDTO } from '../models/list-response-args-of-order-list-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfIEnumerableOfOrderItemSubsetTaskDTO } from '../models/response-args-of-ienumerable-of-order-item-subset-task-dto';
import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ResponseArgsOfIEnumerableOfAutocompleteDTO } from '../models/response-args-of-ienumerable-of-autocomplete-dto';
import { AutocompleteTokenDTO } from '../models/autocomplete-token-dto';
import { ListResponseArgsOfDBHOrderItemListItemDTO } from '../models/list-response-args-of-dbhorder-item-list-item-dto';
import { ListResponseArgsOfOrderItemListItemDTO } from '../models/list-response-args-of-order-item-list-item-dto';
import { ResponseArgsOfIEnumerableOfOrderItemDTO } from '../models/response-args-of-ienumerable-of-order-item-dto';
import { OrderItemDTO } from '../models/order-item-dto';
import { ResponseArgsOfIEnumerableOfHistoryDTO } from '../models/response-args-of-ienumerable-of-history-dto';
import { ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO } from '../models/response-args-of-value-tuple-of-order-item-subset-dtoand-order-item-subset-dto';
import { StatusValues } from '../models/status-values';
import { ResponseArgsOfIEnumerableOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO } from '../models/response-args-of-ienumerable-of-value-tuple-of-order-item-subset-dtoand-order-item-subset-dto';
import { ChangeStockStatusCodeValues } from '../models/change-stock-status-code-values';
import { ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO } from '../models/response-args-of-ienumerable-of-entity-dtocontainer-of-receipt-dto';
import { ResponseArgsOfIEnumerableOfOrderItemSubsetDTO } from '../models/response-args-of-ienumerable-of-order-item-subset-dto';
import { ListResponseArgsOfOrderItemSubsetTaskListItemDTO } from '../models/list-response-args-of-order-item-subset-task-list-item-dto';
import { ResponseArgsOfInteger } from '../models/response-args-of-integer';
import { ResponseArgsOfPayerDTO } from '../models/response-args-of-payer-dto';
import { PayerDTO } from '../models/payer-dto';
import { ResponseArgsOfShippingAddressDTO } from '../models/response-args-of-shipping-address-dto';
import { ShippingAddressDTO } from '../models/shipping-address-dto';
@Injectable({
  providedIn: 'root',
})
class OrderService extends __BaseService {
  static readonly OrderGetOrderPath = '/order/{orderId}';
  static readonly OrderUpdateOrderPath = '/order/{orderId}';
  static readonly OrderPatchOrderPath = '/order/{orderId}';
  static readonly OrderDeleteOrderPath = '/order/{orderId}';
  static readonly OrderUpdateOrderItemSubsetPath = '/order/{orderId}/orderitem/{orderItemId}/orderitemsubset/{orderItemSubsetId}';
  static readonly OrderPatchOrderItemSubsetPath = '/order/{orderId}/orderitem/{orderItemId}/orderitemsubset/{orderItemSubsetId}';
  static readonly OrderGetOrdersByCompartmentPath = '/order/compartment';
  static readonly OrderGetOrdersByBuyerNumberPath = '/buyer/order';
  static readonly OrderQueryOrdersPath = '/order/s';
  static readonly OrderOrderConfirmationTaskPath = '/order/{orderId}/confirmationtask';
  static readonly OrderKundenbestellungenSettingsPath = '/kundenbestellungen/s/settings';
  static readonly OrderKundenbestellungenAutocompletePath = '/kundenbestellungen/s/complete';
  static readonly OrderKundenbestellungenPath = '/kundenbestellungen/s';
  static readonly OrderQueryOrderItemPath = '/order/item/s';
  static readonly OrderQueryOrderItemAutocompletePath = '/order/item/s/complete';
  static readonly OrderGetOrderItemPath = '/order/orderitem/{orderItemId}';
  static readonly OrderGetOrderItemsPath = '/order/orderitem';
  static readonly OrderUpdateOrderItemPath = '/order/{orderId}/orderitem/{orderItemId}';
  static readonly OrderPatchOrderItemPath = '/order/{orderId}/orderitem/{orderItemId}';
  static readonly OrderGetOrderItemHistoryPath = '/order/orderitem/{orderItemId}/history';
  static readonly OrderGetOrderItemSubsetPath = '/order/orderitem/orderitemsubset/{orderItemSubsetId}';
  static readonly OrderGetOrderItemStatusHistoryPath = '/order/orderitem/orderitemsubset/{orderItemSubsetId}/history';
  static readonly OrderChangeStatusPath = '/order/{orderId}/orderitem/{orderItemId}/orderitemsubset/{orderItemSubsetId}/changestatus';
  static readonly OrderChangeStockStatusCodePath = '/order/orderitem/orderitemsubset/changestockstatuscode';
  static readonly OrderCollectOnDeliveryNotePath = '/order/orderitem/orderitemsubset/collectondeliverynote';
  static readonly OrderCollectWithSmallAmountInvoicePath = '/order/orderitem/orderitemsubset/collectwithsmallamountinvoice';
  static readonly OrderSetPreferredPickUpDatePath = '/order/orderitem/orderitemsubset/setpreferredpickupdate';
  static readonly OrderQueryOrderItemSubsetTasksPath = '/order/item/subset/task/s';
  static readonly OrderGetOrderItemSubsetTasksPath = '/order/{orderId}/item/{orderItemId}/subset/{orderItemSubsetId}/task';
  static readonly OrderRegenerateOrderItemStatusTasksPath = '/order/{orderId}/orderItem/orderItemSubset/task/regenerate';
  static readonly OrderGetPayerPath = '/order/payer/{payerId}';
  static readonly OrderUpdatePayerPath = '/order/{orderId}/payer/{payerId}';
  static readonly OrderGetShippingAddressPath = '/order/shippingaddress/{shippingAddressId}';
  static readonly OrderUpdateShippingAddressPath = '/order/{orderId}/shippingaddress/{shippingAddressId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param orderId undefined
   */
  OrderGetOrderResponse(orderId: number): __Observable<__StrictHttpResponse<ResponseArgsOfOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/${encodeURIComponent(String(orderId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderDTO>;
      }),
    );
  }
  /**
   * @param orderId undefined
   */
  OrderGetOrder(orderId: number): __Observable<ResponseArgsOfOrderDTO> {
    return this.OrderGetOrderResponse(orderId).pipe(__map((_r) => _r.body as ResponseArgsOfOrderDTO));
  }

  /**
   * Änderung einer bestehenden Bestellung
   *
   * Anlage einer neuen Bestellung inkl. Bestellposten, Lieferadresse, Regulierer/Rechnungsadresse
   * @param params The `OrderService.OrderUpdateOrderParams` containing the following parameters:
   *
   * - `orderId`: PK Bestellung
   *
   * - `order`: Bestellung
   */
  OrderUpdateOrderResponse(params: OrderService.OrderUpdateOrderParams): __Observable<__StrictHttpResponse<ResponseArgsOfOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.order;
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderDTO>;
      }),
    );
  }
  /**
   * Änderung einer bestehenden Bestellung
   *
   * Anlage einer neuen Bestellung inkl. Bestellposten, Lieferadresse, Regulierer/Rechnungsadresse
   * @param params The `OrderService.OrderUpdateOrderParams` containing the following parameters:
   *
   * - `orderId`: PK Bestellung
   *
   * - `order`: Bestellung
   */
  OrderUpdateOrder(params: OrderService.OrderUpdateOrderParams): __Observable<ResponseArgsOfOrderDTO> {
    return this.OrderUpdateOrderResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderDTO));
  }

  /**
   * Bestellung - Änderung einzelner Eigenschaften
   * @param params The `OrderService.OrderPatchOrderParams` containing the following parameters:
   *
   * - `orderId`: PK Bestellung
   *
   * - `order`: Bestellung
   *
   * - `eagerLoading`: eagerLoading
   */
  OrderPatchOrderResponse(params: OrderService.OrderPatchOrderParams): __Observable<__StrictHttpResponse<ResponseArgsOfOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.order;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('PATCH', this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderDTO>;
      }),
    );
  }
  /**
   * Bestellung - Änderung einzelner Eigenschaften
   * @param params The `OrderService.OrderPatchOrderParams` containing the following parameters:
   *
   * - `orderId`: PK Bestellung
   *
   * - `order`: Bestellung
   *
   * - `eagerLoading`: eagerLoading
   */
  OrderPatchOrder(params: OrderService.OrderPatchOrderParams): __Observable<ResponseArgsOfOrderDTO> {
    return this.OrderPatchOrderResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderDTO));
  }

  /**
   * Löschen einer bestehenden Bestellung
   *
   * Setzt den Datensatz auf gelöscht und anonymisiert benutzerbezogene Daten. Außerdem wird die Bestellnummer OrderNumber verändert.
   * @param params The `OrderService.OrderDeleteOrderParams` containing the following parameters:
   *
   * - `orderId`: PK Bestellung
   *
   * - `deletionComment`: Grund des Löschens
   */
  OrderDeleteOrderResponse(params: OrderService.OrderDeleteOrderParams): __Observable<__StrictHttpResponse<ResponseArgsOfBoolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.deletionComment != null) __params = __params.set('deletionComment', params.deletionComment.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBoolean>;
      }),
    );
  }
  /**
   * Löschen einer bestehenden Bestellung
   *
   * Setzt den Datensatz auf gelöscht und anonymisiert benutzerbezogene Daten. Außerdem wird die Bestellnummer OrderNumber verändert.
   * @param params The `OrderService.OrderDeleteOrderParams` containing the following parameters:
   *
   * - `orderId`: PK Bestellung
   *
   * - `deletionComment`: Grund des Löschens
   */
  OrderDeleteOrder(params: OrderService.OrderDeleteOrderParams): __Observable<ResponseArgsOfBoolean> {
    return this.OrderDeleteOrderResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfBoolean));
  }

  /**
   * Änderung einer bestehenden Bestellpostenteilmenge
   *
   * Falls die Menge/Stückzahl kleiner der ursprünglichen Menge/Stückzahl ist, wird eine neue Bestellpostenteilmenge erzeugt.
   * @param params The `OrderService.OrderUpdateOrderItemSubsetParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemSubset`: Bestellposten
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdateOrderItemSubsetResponse(
    params: OrderService.OrderUpdateOrderItemSubsetParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfOrderItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.orderItemSubset;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}/orderitemsubset/${encodeURIComponent(String(params.orderItemSubsetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderItemDTO>;
      }),
    );
  }
  /**
   * Änderung einer bestehenden Bestellpostenteilmenge
   *
   * Falls die Menge/Stückzahl kleiner der ursprünglichen Menge/Stückzahl ist, wird eine neue Bestellpostenteilmenge erzeugt.
   * @param params The `OrderService.OrderUpdateOrderItemSubsetParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemSubset`: Bestellposten
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdateOrderItemSubset(params: OrderService.OrderUpdateOrderItemSubsetParams): __Observable<ResponseArgsOfOrderItemDTO> {
    return this.OrderUpdateOrderItemSubsetResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderItemDTO));
  }

  /**
   * Änderung einzelner Eigenschaften
   * @param params The `OrderService.OrderPatchOrderItemSubsetParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemSubset`: Bestellposten
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderPatchOrderItemSubsetResponse(
    params: OrderService.OrderPatchOrderItemSubsetParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfOrderItemSubsetDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.orderItemSubset;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}/orderitemsubset/${encodeURIComponent(String(params.orderItemSubsetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderItemSubsetDTO>;
      }),
    );
  }
  /**
   * Änderung einzelner Eigenschaften
   * @param params The `OrderService.OrderPatchOrderItemSubsetParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemSubset`: Bestellposten
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderPatchOrderItemSubset(params: OrderService.OrderPatchOrderItemSubsetParams): __Observable<ResponseArgsOfOrderItemSubsetDTO> {
    return this.OrderPatchOrderItemSubsetResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderItemSubsetDTO));
  }

  /**
   * @param compartmentCodes undefined
   */
  OrderGetOrdersByCompartmentResponse(
    compartmentCodes: Array<string>,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = compartmentCodes;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/compartment`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderDTO>;
      }),
    );
  }
  /**
   * @param compartmentCodes undefined
   */
  OrderGetOrdersByCompartment(compartmentCodes: Array<string>): __Observable<ResponseArgsOfIEnumerableOfOrderDTO> {
    return this.OrderGetOrdersByCompartmentResponse(compartmentCodes).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfOrderDTO));
  }

  /**
   * @param params The `OrderService.OrderGetOrdersByBuyerNumberParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `buyerNumber`:
   */
  OrderGetOrdersByBuyerNumberResponse(
    params: OrderService.OrderGetOrdersByBuyerNumberParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.buyerNumber != null) __params = __params.set('buyerNumber', params.buyerNumber.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/buyer/order`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderListItemDTO>;
      }),
    );
  }
  /**
   * @param params The `OrderService.OrderGetOrdersByBuyerNumberParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   *
   * - `buyerNumber`:
   */
  OrderGetOrdersByBuyerNumber(params: OrderService.OrderGetOrdersByBuyerNumberParams): __Observable<ListResponseArgsOfOrderListItemDTO> {
    return this.OrderGetOrdersByBuyerNumberResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfOrderListItemDTO));
  }

  /**
   * @param queryToken undefined
   */
  OrderQueryOrdersResponse(queryToken: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderListItemDTO>;
      }),
    );
  }
  /**
   * @param queryToken undefined
   */
  OrderQueryOrders(queryToken: QueryTokenDTO): __Observable<ListResponseArgsOfOrderListItemDTO> {
    return this.OrderQueryOrdersResponse(queryToken).pipe(__map((_r) => _r.body as ListResponseArgsOfOrderListItemDTO));
  }

  /**
   * Bestellbestätigungs-Task erzeugen
   * @param orderId Order PK
   */
  OrderOrderConfirmationTaskResponse(
    orderId: number,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderItemSubsetTaskDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/${encodeURIComponent(String(orderId))}/confirmationtask`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderItemSubsetTaskDTO>;
      }),
    );
  }
  /**
   * Bestellbestätigungs-Task erzeugen
   * @param orderId Order PK
   */
  OrderOrderConfirmationTask(orderId: number): __Observable<ResponseArgsOfIEnumerableOfOrderItemSubsetTaskDTO> {
    return this.OrderOrderConfirmationTaskResponse(orderId).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfOrderItemSubsetTaskDTO),
    );
  }

  /**
   * Bestellposten-Filter
   */
  OrderKundenbestellungenSettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/kundenbestellungen/s/settings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      }),
    );
  }
  /**
   * Bestellposten-Filter
   */
  OrderKundenbestellungenSettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.OrderKundenbestellungenSettingsResponse().pipe(__map((_r) => _r.body as ResponseArgsOfQuerySettingsDTO));
  }

  /**
   * Autocomplete für Bestellposten
   * @param payload Suchkriterien
   */
  OrderKundenbestellungenAutocompleteResponse(
    payload?: AutocompleteTokenDTO,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/kundenbestellungen/s/complete`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>;
      }),
    );
  }
  /**
   * Autocomplete für Bestellposten
   * @param payload Suchkriterien
   */
  OrderKundenbestellungenAutocomplete(payload?: AutocompleteTokenDTO): __Observable<ResponseArgsOfIEnumerableOfAutocompleteDTO> {
    return this.OrderKundenbestellungenAutocompleteResponse(payload).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfAutocompleteDTO),
    );
  }

  /**
   * Suche nach Bestellposten
   * @param queryToken Suchkriterien
   */
  OrderKundenbestellungenResponse(
    queryToken: QueryTokenDTO,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfDBHOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/kundenbestellungen/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfDBHOrderItemListItemDTO>;
      }),
    );
  }
  /**
   * Suche nach Bestellposten
   * @param queryToken Suchkriterien
   */
  OrderKundenbestellungen(queryToken: QueryTokenDTO): __Observable<ListResponseArgsOfDBHOrderItemListItemDTO> {
    return this.OrderKundenbestellungenResponse(queryToken).pipe(__map((_r) => _r.body as ListResponseArgsOfDBHOrderItemListItemDTO));
  }

  /**
   * Suche nach Bestellposten
   * @param queryToken Suchkriterien
   */
  OrderQueryOrderItemResponse(queryToken: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/item/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemListItemDTO>;
      }),
    );
  }
  /**
   * Suche nach Bestellposten
   * @param queryToken Suchkriterien
   */
  OrderQueryOrderItem(queryToken: QueryTokenDTO): __Observable<ListResponseArgsOfOrderItemListItemDTO> {
    return this.OrderQueryOrderItemResponse(queryToken).pipe(__map((_r) => _r.body as ListResponseArgsOfOrderItemListItemDTO));
  }

  /**
   * Autocomplete für Bestellposten
   * @param payload Suchkriterien
   */
  OrderQueryOrderItemAutocompleteResponse(
    payload?: AutocompleteTokenDTO,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/item/s/complete`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfAutocompleteDTO>;
      }),
    );
  }
  /**
   * Autocomplete für Bestellposten
   * @param payload Suchkriterien
   */
  OrderQueryOrderItemAutocomplete(payload?: AutocompleteTokenDTO): __Observable<ResponseArgsOfIEnumerableOfAutocompleteDTO> {
    return this.OrderQueryOrderItemAutocompleteResponse(payload).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfAutocompleteDTO));
  }

  /**
   * Bestellposten
   * @param orderItemId PK Bestellposten
   */
  OrderGetOrderItemResponse(orderItemId: number): __Observable<__StrictHttpResponse<ResponseArgsOfOrderItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/orderitem/${encodeURIComponent(String(orderItemId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderItemDTO>;
      }),
    );
  }
  /**
   * Bestellposten
   * @param orderItemId PK Bestellposten
   */
  OrderGetOrderItem(orderItemId: number): __Observable<ResponseArgsOfOrderItemDTO> {
    return this.OrderGetOrderItemResponse(orderItemId).pipe(__map((_r) => _r.body as ResponseArgsOfOrderItemDTO));
  }

  /**
   * Bestellposten
   * @param orderItemIds PKs Bestellposten
   */
  OrderGetOrderItemsResponse(
    orderItemIds?: null | Array<number>,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    (orderItemIds || []).forEach((val) => {
      if (val != null) __params = __params.append('orderItemIds', val.toString());
    });
    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/orderitem`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderItemDTO>;
      }),
    );
  }
  /**
   * Bestellposten
   * @param orderItemIds PKs Bestellposten
   */
  OrderGetOrderItems(orderItemIds?: null | Array<number>): __Observable<ResponseArgsOfIEnumerableOfOrderItemDTO> {
    return this.OrderGetOrderItemsResponse(orderItemIds).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfOrderItemDTO));
  }

  /**
   * Änderung eines bestehenden Bestellpostens
   * @param params The `OrderService.OrderUpdateOrderItemParams` containing the following parameters:
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderItem`: Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdateOrderItemResponse(
    params: OrderService.OrderUpdateOrderItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfOrderItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.orderItem;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderItemDTO>;
      }),
    );
  }
  /**
   * Änderung eines bestehenden Bestellpostens
   * @param params The `OrderService.OrderUpdateOrderItemParams` containing the following parameters:
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderItem`: Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdateOrderItem(params: OrderService.OrderUpdateOrderItemParams): __Observable<ResponseArgsOfOrderItemDTO> {
    return this.OrderUpdateOrderItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderItemDTO));
  }

  /**
   * Änderung einzelner Eigenschaften
   * @param params The `OrderService.OrderPatchOrderItemParams` containing the following parameters:
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderItem`: Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderPatchOrderItemResponse(
    params: OrderService.OrderPatchOrderItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfOrderItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.orderItem;

    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderItemDTO>;
      }),
    );
  }
  /**
   * Änderung einzelner Eigenschaften
   * @param params The `OrderService.OrderPatchOrderItemParams` containing the following parameters:
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderItem`: Bestellposten
   *
   * - `orderId`: PK Bestellung
   */
  OrderPatchOrderItem(params: OrderService.OrderPatchOrderItemParams): __Observable<ResponseArgsOfOrderItemDTO> {
    return this.OrderPatchOrderItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderItemDTO));
  }

  /**
   * Änderungsverlauf der Bestellposten
   * @param params The `OrderService.OrderGetOrderItemHistoryParams` containing the following parameters:
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `locale`: Lokalisierung
   */
  OrderGetOrderItemHistoryResponse(
    params: OrderService.OrderGetOrderItemHistoryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/orderitem/${encodeURIComponent(String(params.orderItemId))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>;
      }),
    );
  }
  /**
   * Änderungsverlauf der Bestellposten
   * @param params The `OrderService.OrderGetOrderItemHistoryParams` containing the following parameters:
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `locale`: Lokalisierung
   */
  OrderGetOrderItemHistory(params: OrderService.OrderGetOrderItemHistoryParams): __Observable<ResponseArgsOfIEnumerableOfHistoryDTO> {
    return this.OrderGetOrderItemHistoryResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfHistoryDTO));
  }

  /**
   * Bestellpostenteilmenge
   * @param orderItemSubsetId PK Bestellpostenteilmenge
   */
  OrderGetOrderItemSubsetResponse(orderItemSubsetId: number): __Observable<__StrictHttpResponse<ResponseArgsOfOrderItemSubsetDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/orderitem/orderitemsubset/${encodeURIComponent(String(orderItemSubsetId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderItemSubsetDTO>;
      }),
    );
  }
  /**
   * Bestellpostenteilmenge
   * @param orderItemSubsetId PK Bestellpostenteilmenge
   */
  OrderGetOrderItemSubset(orderItemSubsetId: number): __Observable<ResponseArgsOfOrderItemSubsetDTO> {
    return this.OrderGetOrderItemSubsetResponse(orderItemSubsetId).pipe(__map((_r) => _r.body as ResponseArgsOfOrderItemSubsetDTO));
  }

  /**
   * Änderungsverlauf der Bestellpostenteilmenge
   * @param params The `OrderService.OrderGetOrderItemStatusHistoryParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `locale`: Lokalisierung
   */
  OrderGetOrderItemStatusHistoryResponse(
    params: OrderService.OrderGetOrderItemStatusHistoryParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/orderitem/orderitemsubset/${encodeURIComponent(String(params.orderItemSubsetId))}/history`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfHistoryDTO>;
      }),
    );
  }
  /**
   * Änderungsverlauf der Bestellpostenteilmenge
   * @param params The `OrderService.OrderGetOrderItemStatusHistoryParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `locale`: Lokalisierung
   */
  OrderGetOrderItemStatusHistory(
    params: OrderService.OrderGetOrderItemStatusHistoryParams,
  ): __Observable<ResponseArgsOfIEnumerableOfHistoryDTO> {
    return this.OrderGetOrderItemStatusHistoryResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfHistoryDTO));
  }

  /**
   * Änderung des Bearbeitungsstatus
   * Falls die Menge/Stückzahl kleiner der ursprünglichen Menge/Stückzahl ist, wird eine neue Bestellpostenteilmenge erzeugt.
   * @param params The `OrderService.OrderChangeStatusParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   *
   * - `data`: Daten zur Änderung des Bearbeitungsstatus
   */
  OrderChangeStatusResponse(
    params: OrderService.OrderChangeStatusParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/order/${encodeURIComponent(String(params.orderId))}/orderitem/${encodeURIComponent(String(params.orderItemId))}/orderitemsubset/${encodeURIComponent(String(params.orderItemSubsetId))}/changestatus`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO>;
      }),
    );
  }
  /**
   * Änderung des Bearbeitungsstatus
   * Falls die Menge/Stückzahl kleiner der ursprünglichen Menge/Stückzahl ist, wird eine neue Bestellpostenteilmenge erzeugt.
   * @param params The `OrderService.OrderChangeStatusParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: PK Bestellpostenteilmenge
   *
   * - `orderItemId`: PK Bestellposten
   *
   * - `orderId`: PK Bestellung
   *
   * - `data`: Daten zur Änderung des Bearbeitungsstatus
   */
  OrderChangeStatus(
    params: OrderService.OrderChangeStatusParams,
  ): __Observable<ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO> {
    return this.OrderChangeStatusResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO),
    );
  }

  /**
   * Meldenummer ändern
   * Falls die Menge/Stückzahl kleiner der ursprünglichen Menge/Stückzahl ist, wird eine neue Bestellpostenteilmenge erzeugt.
   * @param data Daten zur Änderung des Bearbeitungsstatus
   */
  OrderChangeStockStatusCodeResponse(
    data: Array<ChangeStockStatusCodeValues>,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/orderitem/orderitemsubset/changestockstatuscode`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO>;
      }),
    );
  }
  /**
   * Meldenummer ändern
   * Falls die Menge/Stückzahl kleiner der ursprünglichen Menge/Stückzahl ist, wird eine neue Bestellpostenteilmenge erzeugt.
   * @param data Daten zur Änderung des Bearbeitungsstatus
   */
  OrderChangeStockStatusCode(
    data: Array<ChangeStockStatusCodeValues>,
  ): __Observable<ResponseArgsOfIEnumerableOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO> {
    return this.OrderChangeStockStatusCodeResponse(data).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfValueTupleOfOrderItemSubsetDTOAndOrderItemSubsetDTO),
    );
  }

  /**
   * Abholung auf Lieferschein
   * @param params The `OrderService.OrderCollectOnDeliveryNoteParams` containing the following parameters:
   *
   * - `data`: Bestellpostenteilmenge PKs
   *
   * - `eagerLoading`:
   */
  OrderCollectOnDeliveryNoteResponse(
    params: OrderService.OrderCollectOnDeliveryNoteParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/orderitem/orderitemsubset/collectondeliverynote`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO>;
      }),
    );
  }
  /**
   * Abholung auf Lieferschein
   * @param params The `OrderService.OrderCollectOnDeliveryNoteParams` containing the following parameters:
   *
   * - `data`: Bestellpostenteilmenge PKs
   *
   * - `eagerLoading`:
   */
  OrderCollectOnDeliveryNote(
    params: OrderService.OrderCollectOnDeliveryNoteParams,
  ): __Observable<ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO> {
    return this.OrderCollectOnDeliveryNoteResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO),
    );
  }

  /**
   * Abholung mit Kleinbetragsrechnung
   * @param params The `OrderService.OrderCollectWithSmallAmountInvoiceParams` containing the following parameters:
   *
   * - `data`: Bestellpostenteilmenge PKs
   *
   * - `eagerLoading`:
   */
  OrderCollectWithSmallAmountInvoiceResponse(
    params: OrderService.OrderCollectWithSmallAmountInvoiceParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/orderitem/orderitemsubset/collectwithsmallamountinvoice`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO>;
      }),
    );
  }
  /**
   * Abholung mit Kleinbetragsrechnung
   * @param params The `OrderService.OrderCollectWithSmallAmountInvoiceParams` containing the following parameters:
   *
   * - `data`: Bestellpostenteilmenge PKs
   *
   * - `eagerLoading`:
   */
  OrderCollectWithSmallAmountInvoice(
    params: OrderService.OrderCollectWithSmallAmountInvoiceParams,
  ): __Observable<ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO> {
    return this.OrderCollectWithSmallAmountInvoiceResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfEntityDTOContainerOfReceiptDTO),
    );
  }

  /**
   * Gewünschtes Abholdatum setzen
   * @param params The `OrderService.OrderSetPreferredPickUpDateParams` containing the following parameters:
   *
   * - `data`: Bestellpostenteilmenge PKs
   *
   * - `eagerLoading`:
   */
  OrderSetPreferredPickUpDateResponse(
    params: OrderService.OrderSetPreferredPickUpDateParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderItemSubsetDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/orderitem/orderitemsubset/setpreferredpickupdate`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfOrderItemSubsetDTO>;
      }),
    );
  }
  /**
   * Gewünschtes Abholdatum setzen
   * @param params The `OrderService.OrderSetPreferredPickUpDateParams` containing the following parameters:
   *
   * - `data`: Bestellpostenteilmenge PKs
   *
   * - `eagerLoading`:
   */
  OrderSetPreferredPickUpDate(
    params: OrderService.OrderSetPreferredPickUpDateParams,
  ): __Observable<ResponseArgsOfIEnumerableOfOrderItemSubsetDTO> {
    return this.OrderSetPreferredPickUpDateResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfOrderItemSubsetDTO));
  }

  /**
   * Suche nach Bestellpostenstatus-Aufgaben
   * @param queryToken Suchkriterien
   */
  OrderQueryOrderItemSubsetTasksResponse(
    queryToken: QueryTokenDTO,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemSubsetTaskListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryToken;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/order/item/subset/task/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemSubsetTaskListItemDTO>;
      }),
    );
  }
  /**
   * Suche nach Bestellpostenstatus-Aufgaben
   * @param queryToken Suchkriterien
   */
  OrderQueryOrderItemSubsetTasks(queryToken: QueryTokenDTO): __Observable<ListResponseArgsOfOrderItemSubsetTaskListItemDTO> {
    return this.OrderQueryOrderItemSubsetTasksResponse(queryToken).pipe(
      __map((_r) => _r.body as ListResponseArgsOfOrderItemSubsetTaskListItemDTO),
    );
  }

  /**
   * Bestellpostenstatus-Aufgaben
   * @param params The `OrderService.OrderGetOrderItemSubsetTasksParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: Bestellpostenteilmenge PK
   *
   * - `orderItemId`: Bestellposten PK
   *
   * - `orderId`: Bestellung PK
   *
   * - `take`: takse (optional)
   *
   * - `skip`: skip (optional)
   *
   * - `completed`: abgeschlossen (optional)
   */
  OrderGetOrderItemSubsetTasksResponse(
    params: OrderService.OrderGetOrderItemSubsetTasksParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfOrderItemSubsetTaskListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    if (params.completed != null) __params = __params.set('completed', params.completed.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/order/${encodeURIComponent(String(params.orderId))}/item/${encodeURIComponent(String(params.orderItemId))}/subset/${encodeURIComponent(String(params.orderItemSubsetId))}/task`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfOrderItemSubsetTaskListItemDTO>;
      }),
    );
  }
  /**
   * Bestellpostenstatus-Aufgaben
   * @param params The `OrderService.OrderGetOrderItemSubsetTasksParams` containing the following parameters:
   *
   * - `orderItemSubsetId`: Bestellpostenteilmenge PK
   *
   * - `orderItemId`: Bestellposten PK
   *
   * - `orderId`: Bestellung PK
   *
   * - `take`: takse (optional)
   *
   * - `skip`: skip (optional)
   *
   * - `completed`: abgeschlossen (optional)
   */
  OrderGetOrderItemSubsetTasks(
    params: OrderService.OrderGetOrderItemSubsetTasksParams,
  ): __Observable<ListResponseArgsOfOrderItemSubsetTaskListItemDTO> {
    return this.OrderGetOrderItemSubsetTasksResponse(params).pipe(
      __map((_r) => _r.body as ListResponseArgsOfOrderItemSubsetTaskListItemDTO),
    );
  }

  /**
   * Letzte Aufgaben erneut erzeugen
   * @param params The `OrderService.OrderRegenerateOrderItemStatusTasksParams` containing the following parameters:
   *
   * - `orderId`: Bestellung PK
   *
   * - `taskTypes`: Aufgaben-Arten
   */
  OrderRegenerateOrderItemStatusTasksResponse(
    params: OrderService.OrderRegenerateOrderItemStatusTasksParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.taskTypes;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}/orderItem/orderItemSubset/task/regenerate`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInteger>;
      }),
    );
  }
  /**
   * Letzte Aufgaben erneut erzeugen
   * @param params The `OrderService.OrderRegenerateOrderItemStatusTasksParams` containing the following parameters:
   *
   * - `orderId`: Bestellung PK
   *
   * - `taskTypes`: Aufgaben-Arten
   */
  OrderRegenerateOrderItemStatusTasks(params: OrderService.OrderRegenerateOrderItemStatusTasksParams): __Observable<ResponseArgsOfInteger> {
    return this.OrderRegenerateOrderItemStatusTasksResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInteger));
  }

  /**
   * Regulierer/Rechnungsadresse
   * @param payerId PK Regulierer/Rechnungsadresse
   */
  OrderGetPayerResponse(payerId: number): __Observable<__StrictHttpResponse<ResponseArgsOfPayerDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/payer/${encodeURIComponent(String(payerId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfPayerDTO>;
      }),
    );
  }
  /**
   * Regulierer/Rechnungsadresse
   * @param payerId PK Regulierer/Rechnungsadresse
   */
  OrderGetPayer(payerId: number): __Observable<ResponseArgsOfPayerDTO> {
    return this.OrderGetPayerResponse(payerId).pipe(__map((_r) => _r.body as ResponseArgsOfPayerDTO));
  }

  /**
   * Änderung eines/einer bestehenden Regulierers/Rechnungsadresse
   * @param params The `OrderService.OrderUpdatePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `payer`: Regulierer
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdatePayerResponse(params: OrderService.OrderUpdatePayerParams): __Observable<__StrictHttpResponse<ResponseArgsOfOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payer;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/order/${encodeURIComponent(String(params.orderId))}/payer/${encodeURIComponent(String(params.payerId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfOrderDTO>;
      }),
    );
  }
  /**
   * Änderung eines/einer bestehenden Regulierers/Rechnungsadresse
   * @param params The `OrderService.OrderUpdatePayerParams` containing the following parameters:
   *
   * - `payerId`: PK Regulierer/Rechnungsadresse
   *
   * - `payer`: Regulierer
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdatePayer(params: OrderService.OrderUpdatePayerParams): __Observable<ResponseArgsOfOrderDTO> {
    return this.OrderUpdatePayerResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfOrderDTO));
  }

  /**
   * Lieferadresse
   * @param shippingAddressId PK Lieferadresse
   */
  OrderGetShippingAddressResponse(shippingAddressId: number): __Observable<__StrictHttpResponse<ResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/order/shippingaddress/${encodeURIComponent(String(shippingAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * Lieferadresse
   * @param shippingAddressId PK Lieferadresse
   */
  OrderGetShippingAddress(shippingAddressId: number): __Observable<ResponseArgsOfShippingAddressDTO> {
    return this.OrderGetShippingAddressResponse(shippingAddressId).pipe(__map((_r) => _r.body as ResponseArgsOfShippingAddressDTO));
  }

  /**
   * Änderung einer bestehenden Lieferadresse
   * @param params The `OrderService.OrderUpdateShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddressId`: PK Lieferadresse
   *
   * - `shippingAddress`: Lieferadressse
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdateShippingAddressResponse(
    params: OrderService.OrderUpdateShippingAddressParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShippingAddressDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.shippingAddress;

    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/order/${encodeURIComponent(String(params.orderId))}/shippingaddress/${encodeURIComponent(String(params.shippingAddressId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShippingAddressDTO>;
      }),
    );
  }
  /**
   * Änderung einer bestehenden Lieferadresse
   * @param params The `OrderService.OrderUpdateShippingAddressParams` containing the following parameters:
   *
   * - `shippingAddressId`: PK Lieferadresse
   *
   * - `shippingAddress`: Lieferadressse
   *
   * - `orderId`: PK Bestellung
   */
  OrderUpdateShippingAddress(params: OrderService.OrderUpdateShippingAddressParams): __Observable<ResponseArgsOfShippingAddressDTO> {
    return this.OrderUpdateShippingAddressResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfShippingAddressDTO));
  }
}

module OrderService {
  /**
   * Parameters for OrderUpdateOrder
   */
  export interface OrderUpdateOrderParams {
    /**
     * PK Bestellung
     */
    orderId: number;

    /**
     * Bestellung
     */
    order: OrderDTO;
  }

  /**
   * Parameters for OrderPatchOrder
   */
  export interface OrderPatchOrderParams {
    /**
     * PK Bestellung
     */
    orderId: number;

    /**
     * Bestellung
     */
    order: OrderDTO;

    /**
     * eagerLoading
     */
    eagerLoading?: number;
  }

  /**
   * Parameters for OrderDeleteOrder
   */
  export interface OrderDeleteOrderParams {
    /**
     * PK Bestellung
     */
    orderId: number;

    /**
     * Grund des Löschens
     */
    deletionComment?: null | string;
  }

  /**
   * Parameters for OrderUpdateOrderItemSubset
   */
  export interface OrderUpdateOrderItemSubsetParams {
    /**
     * PK Bestellpostenteilmenge
     */
    orderItemSubsetId: number;

    /**
     * Bestellposten
     */
    orderItemSubset: OrderItemSubsetDTO;

    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * PK Bestellung
     */
    orderId: number;
  }

  /**
   * Parameters for OrderPatchOrderItemSubset
   */
  export interface OrderPatchOrderItemSubsetParams {
    /**
     * PK Bestellpostenteilmenge
     */
    orderItemSubsetId: number;

    /**
     * Bestellposten
     */
    orderItemSubset: OrderItemSubsetDTO;

    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * PK Bestellung
     */
    orderId: number;
  }

  /**
   * Parameters for OrderGetOrdersByBuyerNumber
   */
  export interface OrderGetOrdersByBuyerNumberParams {
    take?: null | number;
    skip?: null | number;
    buyerNumber?: null | string;
  }

  /**
   * Parameters for OrderUpdateOrderItem
   */
  export interface OrderUpdateOrderItemParams {
    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * Bestellposten
     */
    orderItem: OrderItemDTO;

    /**
     * PK Bestellung
     */
    orderId: number;
  }

  /**
   * Parameters for OrderPatchOrderItem
   */
  export interface OrderPatchOrderItemParams {
    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * Bestellposten
     */
    orderItem: OrderItemDTO;

    /**
     * PK Bestellung
     */
    orderId: number;
  }

  /**
   * Parameters for OrderGetOrderItemHistory
   */
  export interface OrderGetOrderItemHistoryParams {
    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for OrderGetOrderItemStatusHistory
   */
  export interface OrderGetOrderItemStatusHistoryParams {
    /**
     * PK Bestellpostenteilmenge
     */
    orderItemSubsetId: number;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for OrderChangeStatus
   */
  export interface OrderChangeStatusParams {
    /**
     * PK Bestellpostenteilmenge
     */
    orderItemSubsetId: number;

    /**
     * PK Bestellposten
     */
    orderItemId: number;

    /**
     * PK Bestellung
     */
    orderId: number;

    /**
     * Daten zur Änderung des Bearbeitungsstatus
     */
    data: StatusValues;
  }

  /**
   * Parameters for OrderCollectOnDeliveryNote
   */
  export interface OrderCollectOnDeliveryNoteParams {
    /**
     * Bestellpostenteilmenge PKs
     */
    data: { [key: string]: number };
    eagerLoading?: number;
  }

  /**
   * Parameters for OrderCollectWithSmallAmountInvoice
   */
  export interface OrderCollectWithSmallAmountInvoiceParams {
    /**
     * Bestellpostenteilmenge PKs
     */
    data: { [key: string]: number };
    eagerLoading?: number;
  }

  /**
   * Parameters for OrderSetPreferredPickUpDate
   */
  export interface OrderSetPreferredPickUpDateParams {
    /**
     * Bestellpostenteilmenge PKs
     */
    data: { [key: string]: string };
    eagerLoading?: number;
  }

  /**
   * Parameters for OrderGetOrderItemSubsetTasks
   */
  export interface OrderGetOrderItemSubsetTasksParams {
    /**
     * Bestellpostenteilmenge PK
     */
    orderItemSubsetId: number;

    /**
     * Bestellposten PK
     */
    orderItemId: number;

    /**
     * Bestellung PK
     */
    orderId: number;

    /**
     * takse (optional)
     */
    take?: null | number;

    /**
     * skip (optional)
     */
    skip?: null | number;

    /**
     * abgeschlossen (optional)
     */
    completed?: null | string;
  }

  /**
   * Parameters for OrderRegenerateOrderItemStatusTasks
   */
  export interface OrderRegenerateOrderItemStatusTasksParams {
    /**
     * Bestellung PK
     */
    orderId: number;

    /**
     * Aufgaben-Arten
     */
    taskTypes?: Array<string>;
  }

  /**
   * Parameters for OrderUpdatePayer
   */
  export interface OrderUpdatePayerParams {
    /**
     * PK Regulierer/Rechnungsadresse
     */
    payerId: number;

    /**
     * Regulierer
     */
    payer: PayerDTO;

    /**
     * PK Bestellung
     */
    orderId: number;
  }

  /**
   * Parameters for OrderUpdateShippingAddress
   */
  export interface OrderUpdateShippingAddressParams {
    /**
     * PK Lieferadresse
     */
    shippingAddressId: number;

    /**
     * Lieferadressse
     */
    shippingAddress: ShippingAddressDTO;

    /**
     * PK Bestellung
     */
    orderId: number;
  }
}

export { OrderService };
