<div class="grid grid-flow-col items-center justify-start gap-4" [formGroup]="formGroup">
  <shared-form-control
    [attr.data-label]="uiStartOption?.label"
    [attr.data-value]="uiStartOption?.value"
    [attr.data-key]="uiStartOption?.key"
    [attr.data-selected]="uiStartOption?.selected"
  >
    <shared-input-control>
      <input
        placeholder="TT.MM.JJJJ"
        sharedInputControlInput
        sharedDateInput
        type="text"
        formControlName="start"
        (blur)="setStratValue($event.target['value'])"
      />
      <shared-input-control-suffix>
        <button
          type="button"
          class="grid items-center justify-center h-10 w-14 my-2 border-l solid border-[#AEB7C1] text-[#596470]"
          [uiOverlayTrigger]="dpStart"
          #dpStartTrigger="uiOverlayTrigger"
        >
          <shared-icon icon="calendar-today"></shared-icon>
        </button>
      </shared-input-control-suffix>
    </shared-input-control>
  </shared-form-control>
  <div class="font-bold -mt-4">bis</div>
  <shared-form-control
    [attr.data-label]="uiStopOption?.label"
    [attr.data-value]="uiStopOption?.value"
    [attr.data-key]="uiStopOption?.key"
    [attr.data-selected]="uiStopOption?.selected"
  >
    <shared-input-control>
      <input
        placeholder="TT.MM.JJJJ"
        sharedInputControlInput
        sharedDateInput
        type="text"
        formControlName="stop"
        (blur)="setStopValue($event.target['value'])"
      />
      <shared-input-control-suffix>
        <button
          type="button"
          class="grid items-center justify-center h-10 w-14 my-2 border-l solid border-[#AEB7C1] text-[#596470]"
          [uiOverlayTrigger]="dpStop"
          #dpStartTrigger="uiOverlayTrigger"
        >
          <shared-icon icon="calendar-today"></shared-icon>
        </button>
      </shared-input-control-suffix>
    </shared-input-control>
  </shared-form-control>

  <ui-datepicker
    class="dp-left"
    #dpStart
    yPosition="below"
    xPosition="after"
    formControlName="start"
    [max]="maxDate"
    saveLabel="Übernehmen"
    (save)="setStratValue($event)"
  >
  </ui-datepicker>
  <ui-datepicker
    class="dp-right"
    yPosition="below"
    xPosition="after"
    #dpStop
    [min]="minDate"
    formControlName="stop"
    (save)="setStopValue($event)"
    saveLabel="Übernehmen"
  >
  </ui-datepicker>
</div>
