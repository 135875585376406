import { NgModule } from '@angular/core';
import { FilterCustomInputDirective } from './custom-input.directive';
import { FilterInputTextModule } from './input-text';

@NgModule({
  imports: [FilterInputTextModule],
  declarations: [FilterCustomInputDirective],
  exports: [FilterInputTextModule, FilterCustomInputDirective],
})
export class FilterInputModule {}
