import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  forwardRef,
  OnDestroy,
  Input,
  ElementRef,
  ViewChild,
  TemplateRef,
  ContentChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Datepicker } from './datepicker';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DateAdapter, UiOverlayTrigger } from '@ui/common';
import { DatepickerPositionX, DatepickerPositionY } from './datepicker-positions';
import { isDate } from 'lodash';

@Component({
  selector: 'ui-datepicker',
  templateUrl: 'datepicker.component.html',
  styleUrls: ['datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: Datepicker, useExisting: forwardRef(() => UiDatepickerComponent) },
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => UiDatepickerComponent), multi: true },
  ],
  exportAs: 'uiDatepicker',
})
export class UiDatepickerComponent extends Datepicker implements UiOverlayTrigger, ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;

  @ContentChild('content') content: ElementRef;

  @Input()
  xPosition: DatepickerPositionX;

  @Input()
  yPosition: DatepickerPositionY;

  @Input()
  xOffset = 0;

  @Input()
  yOffset = 0;

  @Input()
  saveLabel: string = 'Speichern';

  get classList() {
    return ['dp', `x-position-${this.xPosition}`, `y-position-${this.yPosition}`];
  }

  private subscription = new Subscription();

  onChange = (date: Date) => {};

  close = () => {};

  onTouched = () => {};

  constructor(
    dateAdapter: DateAdapter,
    private _cdr: ChangeDetectorRef,
  ) {
    super(dateAdapter);
    const sub = this.selectedChange.subscribe((date) => {
      this.onChange(date);
      this.onTouched();
    });
    this.subscription.add(sub);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
    super.onDestroy();
  }

  writeValue(obj: Date | string): void {
    let date = undefined;

    if (obj) {
      date = new Date(obj);
    }

    this.setSelected(date, { emit: false });

    this.setDisplayed(date ?? new Date(), { emit: false });

    this._cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onSave() {
    this.save.emit(this.selectedDate);
    this.onChange(this.selectedDate);
    this.onTouched();
    this.close();
  }
}
