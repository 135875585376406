import { Filter } from '@shared/components/filter';
import { CustomerDTO, CustomerInfoDTO } from '@swagger/crm';
import { OrderDTO, OrderListItemDTO } from '@swagger/oms';

export interface CustomerSearchState {
  processId?: number;
  customer?: CustomerDTO;
  fetchingCustomer?: boolean;
  defaultFilter?: Filter;
  queryParams?: Record<string, string>;
  fetchingFilter?: boolean;
  customerList?: CustomerInfoDTO[];
  customerListCount: number;
  fetchingCustomerList?: boolean;

  message?: string;

  customerOrders?: OrderListItemDTO[];
  fetchingCustomerOrders?: boolean;

  order?: OrderDTO;
  fetchingOrder?: boolean;

  selectedOrderItemId?: number;
}
