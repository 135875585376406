import { Injectable } from '@angular/core';
import { removeProcess } from '@core/application';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NEVER } from 'rxjs';
import { mergeMap, tap, first, map } from 'rxjs/operators';
import { BreadcrumbService } from '../breadcrumb.service';

@Injectable()
export class BreadcrumbEffects {
  removeProcess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeProcess),
        mergeMap((action) =>
          this.breadcrumb.getBreadcrumbByKey$(action.processId).pipe(
            first(),
            tap((breadcrumbs) => {
              breadcrumbs?.forEach((crumb) => this.breadcrumb.removeBreadcrumb(crumb.id));
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private breadcrumb: BreadcrumbService,
  ) {}
}
