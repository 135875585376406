<div class="w-full flex flex-row flex-grow mb-2">
  <ng-container *ngIf="historyLogEntryData?.changed; else created">
    <div class="flex flex-row font-bold mr-6">
      <div class="flex flex-row whitespace-nowrap changed-timestamp">
        {{ historyLogEntryData.changed | date }} <span class="separator font-normal">|</span>
        {{ historyLogEntryData.changed | date: 'shortTime' }} Uhr
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex flex-row changed-by" *ngIf="historyLogEntryData.changedBy">
        Geändert von {{ historyLogEntryData.changedBy }}
        <span *ngIf="historyLogEntryData.changedAt" class="separator">|</span>
      </div>
      <div class="flex flex-row changed-at" *ngIf="historyLogEntryData.changedAt">{{ historyLogEntryData.changedAt }}</div>
    </div>
  </ng-container>
</div>

<div class="details-container">
  <div class="details-header">
    <strong class="px-6 log-entry-name">{{ historyLogEntryData?.name ?? 'Geändertes Feld' }}</strong>
    <div *ngIf="historyLogEntryData?.values?.length > 0" class="pr-6"><strong class="old-value">Alter Wert</strong></div>
    <div *ngIf="historyLogEntryData?.values?.length > 0" class="pr-6"><strong class="new-value">Neuer Wert</strong></div>
  </div>
  <div class="details-values" [class.first]="isFirst" *ngFor="let values of historyLogEntryData?.values; first as isFirst">
    <shared-history-log-entry-values [historyLogEntryValues]="values"></shared-history-log-entry-values>
  </div>
</div>

<ng-template #created>
  <ng-container *ngIf="historyLogEntryData?.created">
    <div class="flex flex-row font-bold mr-6">
      <div class="flex flex-row whitespace-nowrap created-timestamp">
        {{ historyLogEntryData.created | date }} <span class="separator font-normal">|</span>
        {{ historyLogEntryData.created | date: 'shortTime' }} Uhr
      </div>
    </div>
    <div class="flex flex-row">
      <div class="flex flex-row created-by" *ngIf="historyLogEntryData.createdBy">
        Angelegt von {{ historyLogEntryData.createdBy }}
        <span *ngIf="historyLogEntryData.createdAt" class="separator">|</span>
      </div>
      <div class="flex flex-row created-at" *ngIf="historyLogEntryData.createdAt">{{ historyLogEntryData.createdAt }}</div>
    </div>
  </ng-container>
</ng-template>
