import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IUiOption, UiOption } from '../../../tree';

@Component({
  selector: 'ui-input-option-number-range',
  templateUrl: 'filter-input-option-number-range.component.html',
  styleUrls: ['filter-input-option-number-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiInputOptionNumberRangeComponent {
  private _options: UiOption[];

  @Input()
  set options(value: IUiOption[]) {
    this._options = value?.map((option) => (option instanceof UiOption ? option : UiOption.create(option)));
    this.subscribeChanges();
  }

  get uiOptions() {
    return this._options;
  }

  get uiStartOption() {
    return this.uiOptions?.find((o) => o.key === 'start');
  }

  get uiStopOption() {
    return this.uiOptions?.find((o) => o.key === 'stop');
  }

  optionChangeSubscription: Subscription;

  constructor(private cdr: ChangeDetectorRef) {}

  subscribeChanges() {
    this.unsubscribeChanges();
    if (this.uiStartOption) {
      this.optionChangeSubscription.add(
        this.uiStartOption.changes.subscribe(() => {
          this.cdr.markForCheck();
        }),
      );
    }
    if (this.uiStopOption) {
      this.optionChangeSubscription.add(
        this.uiStopOption.changes.subscribe(() => {
          this.cdr.markForCheck();
        }),
      );
    }
  }

  unsubscribeChanges() {
    this.optionChangeSubscription?.unsubscribe();
    this.optionChangeSubscription = new Subscription();
  }
}
