export enum InputType {
  NotSet = 0,
  Text = 1,
  Bool = 2,
  TriState = 4,
  InputSelector = 8,
  Date = 16,
  DateTime = 32,
  Time = 64,
  DateRange = 128,
  DateTimeRange = 256,
  TimeRange = 512,
  Integer = 1024,
  Decimal = 2048,
  Number = 3072,
  IntegerRange = 4096,
  DecimalRange = 8192,
  NumberRange = 12288,
}
