import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from '@core/application';
import { UiModalRef } from '@ui/modal';
import { isBoolean } from '@utils/common';
import { encodeFormData, mapCustomerDtoToCustomerCreateFormData } from '../../create-customer/customer-create-form-data';
import { CantAddCustomerToCartData } from './cant-add-customer-to-cart.data';
import { CustomerCreateNavigation } from '@shared/services/navigation';

@Component({
  selector: 'pages-cant-add-customer-to-cart-modal',
  templateUrl: 'cant-add-customer-to-cart.component.html',
  styleUrls: ['cant-add-customer-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CantAddCustomerToCartModalComponent {
  customerCreateNavigation = inject(CustomerCreateNavigation);

  customerSearchNav = this.customerCreateNavigation.defaultRoute({
    processId: this.applicationService.activatedProcessId,
  });

  get upgradable(): boolean {
    return typeof this.option?.value === 'string';
  }

  get option() {
    return (
      this.ref.data.upgradeableTo?.options.values.find((upgradeOption) =>
        this.ref.data.required.options.values.some((requiredOption) => upgradeOption.key === requiredOption.key),
      ) || { value: this.queryParams }
    );
  }

  get queryParams() {
    let option = this.ref.data.required?.options.values.find((f) => f.selected);
    if (!option) {
      option = this.ref.data.required?.options.values.find((f) => (isBoolean(f.enabled) ? f.enabled : true));
    }
    return option ? { customertype: option.value } : {};
  }

  constructor(
    public ref: UiModalRef<any, CantAddCustomerToCartData>,
    public readonly applicationService: ApplicationService,
    private router: Router,
  ) {}

  upgradeCustomer() {
    const option = this.option.value;
    const { attributes, customer } = this.ref.data;

    const queryParams: Record<string, string> = {};

    if (customer) {
      queryParams['formData'] = encodeFormData(mapCustomerDtoToCustomerCreateFormData(customer));
    }

    if (option === 'webshop' && attributes.some((a) => a.key === 'p4mUser')) {
      const nav = this.customerCreateNavigation.createCustomerRoute({
        processId: this.applicationService.activatedProcessId,
        customerType: 'webshop-p4m',
      });
      this.router.navigate(nav.path, {
        queryParams: { ...nav.queryParams, ...queryParams },
      });
    } else {
      const nav = this.customerCreateNavigation.createCustomerRoute({
        processId: this.applicationService.activatedProcessId,
        customerType: option as any,
      });

      this.router.navigate(nav.path, {
        queryParams: { ...nav.queryParams, ...queryParams },
      });
    }

    this.ref.close();
  }
}
