import { AbstractControl, ValidatorFn } from '@angular/forms';

export function zipCodeValidator(): ValidatorFn | null {
  return (control: AbstractControl) => {
    if (!control || !control.parent) {
      return null;
    }

    const country = control?.parent?.get('country')?.value;
    const zipCode = control?.value;

    if (!country || !zipCode) {
      return null;
    }

    if (country === 'DEU') {
      const zipCodePattern = /^[0-9]{5}$/;
      if (zipCode?.length > 5) {
        return { tooManyDigitsForZipCode: 'PLZ zu lang' };
      }
      return zipCodePattern.test(zipCode) ? null : { incompleteZipCode: 'PLZ nicht vollständig' };
    }

    if (country === 'AUT' || country === 'CHE') {
      const zipCodePattern = /^[0-9]{4}$/;
      if (zipCode?.length > 4) {
        return { tooManyDigitsForZipCode: 'PLZ zu lang' };
      }
      return zipCodePattern.test(zipCode) ? null : { incompleteZipCode: 'PLZ nicht vollständig' };
    }

    return null;
  };
}
