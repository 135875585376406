import { Directive, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { IInput, FilterInput } from '../../tree';

@Directive({})
export abstract class AbstractUiFilterInputDirective {
  private _input: FilterInput;

  @Input()
  set input(value: IInput) {
    if (value instanceof FilterInput) {
      this._input = value;
    } else {
      this._input = FilterInput.create(value);
    }

    this._onUiInputChange.next(this._input);
  }

  get input() {
    return this._input;
  }

  get uiInput() {
    return this._input;
  }

  get value() {
    return this.uiInput?.value;
  }

  protected _onUiInputChange = new Subject<FilterInput>();

  onUiInputChange$ = this._onUiInputChange.asObservable();

  constructor() {}

  setValue(value: string) {
    this.uiInput.setValue(value, { emitEvent: true });
  }
}
