<shared-searchbox
  class="w-full"
  [placeholder]="uiInput?.placeholder"
  [query]="uiInput?.value"
  (queryChange)="onQueryChange($event)"
  (complete)="complete.next($event)"
  (search)="emitSearch($event)"
  (scan)="emitSearch($event)"
  [loading]="loading"
  [hint]="hint"
  [scanner]="scanner"
>
  <ui-autocomplete *ngIf="autocompleteProvider">
    <button *ngFor="let item of autocompleteResults$ | async" [uiAutocompleteItem]="item.query">
      {{ item.display }}
    </button>
  </ui-autocomplete>
</shared-searchbox>
<ng-container *ngIf="showDescription && uiInput?.description">
  <button [uiOverlayTrigger]="tooltipContent" #tooltip="uiOverlayTrigger" class="info-tooltip-button" type="button">i</button>
  <ui-tooltip #tooltipContent yPosition="above" xPosition="after" [yOffset]="-16">
    {{ uiInput.description }}
  </ui-tooltip>
</ng-container>
