/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { WwsConfiguration as __Configuration } from '../wws-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ListResponseArgsOfProductListItemDTO } from '../models/list-response-args-of-product-list-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfProductListDTO } from '../models/response-args-of-product-list-dto';
import { ProductListDTO } from '../models/product-list-dto';
import { ProductListItemDTO } from '../models/product-list-item-dto';
import { ResponseArgsOfString } from '../models/response-args-of-string';
import { DocumentPayloadOfIEnumerableOfProductListItemDTO } from '../models/document-payload-of-ienumerable-of-product-list-item-dto';
import { ListResponseArgsOfProductListDTO } from '../models/list-response-args-of-product-list-dto';
import { ResponseArgsOfProductListItemDTO } from '../models/response-args-of-product-list-item-dto';
import { BatchResponseArgsOfProductListItemDTOAndString } from '../models/batch-response-args-of-product-list-item-dtoand-string';
@Injectable({
  providedIn: 'root',
})
class ProductListService extends __BaseService {
  static readonly ProductListQueryProductListItemsSettingsPath = '/inventory/productlist/items/s/settings';
  static readonly ProductListQueryProductListItemPath = '/inventory/productlist/items/s';
  static readonly ProductListGetProductListPath = '/inventory/productlist/key/{key}';
  static readonly ProductListCreateOrUpdateProductlistPath = '/inventory/productlist/{key}';
  static readonly ProductListAddOrUpdateProductListItemsPath = '/inventory/productlist/{key}/items';
  static readonly ProductListProductListItemPdfAsBase64Path = '/inventory/productlist/items/pdf/base64';
  static readonly ProductListQueryProductListPath = '/inventory/productlist/s';
  static readonly ProductListCreateProductListPath = '/inventory/productlist';
  static readonly ProductListUpdateProductListPath = '/inventory/productlist/{productlistUId}';
  static readonly ProductListGetProductlistItemsPath = '/inventory/productlist/{productlistUId}';
  static readonly ProductListProductlistCompletedPath = '/inventory/productlist/{productlistUId}/completed';
  static readonly ProductListCreateProductListItemPath = '/inventory/productlist/items';
  static readonly ProductListUpdateProductListItemPath = '/inventory/productlist/items/{productListItemUId}';
  static readonly ProductListProductListItemCompletedPath = '/inventory/productlist/items/{productListItemUId}/completed';
  static readonly ProductListProductListItemsCompletedPath = '/inventory/productlist/items/completed';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Settings - Produktlistenpositionen
   */
  ProductListQueryProductListItemsSettingsResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/productlist/items/s/settings`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      }),
    );
  }
  /**
   * Settings - Produktlistenpositionen
   */
  ProductListQueryProductListItemsSettings(): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.ProductListQueryProductListItemsSettingsResponse().pipe(__map((_r) => _r.body as ResponseArgsOfQuerySettingsDTO));
  }

  /**
   * @param queryTokenDTO undefined
   */
  ProductListQueryProductListItemResponse(
    queryTokenDTO: QueryTokenDTO,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfProductListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryTokenDTO;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist/items/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfProductListItemDTO>;
      }),
    );
  }
  /**
   * @param queryTokenDTO undefined
   */
  ProductListQueryProductListItem(queryTokenDTO: QueryTokenDTO): __Observable<ListResponseArgsOfProductListItemDTO> {
    return this.ProductListQueryProductListItemResponse(queryTokenDTO).pipe(__map((_r) => _r.body as ListResponseArgsOfProductListItemDTO));
  }

  /**
   * Productliste by key
   * @param key undefined
   */
  ProductListGetProductListResponse(key: string): __Observable<__StrictHttpResponse<ResponseArgsOfProductListDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/productlist/key/${encodeURIComponent(String(key))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListDTO>;
      }),
    );
  }
  /**
   * Productliste by key
   * @param key undefined
   */
  ProductListGetProductList(key: string): __Observable<ResponseArgsOfProductListDTO> {
    return this.ProductListGetProductListResponse(key).pipe(__map((_r) => _r.body as ResponseArgsOfProductListDTO));
  }

  /**
   * Create or Update Productlist
   * @param params The `ProductListService.ProductListCreateOrUpdateProductlistParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `key`:
   */
  ProductListCreateOrUpdateProductlistResponse(
    params: ProductListService.ProductListCreateOrUpdateProductlistParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfProductListDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist/${encodeURIComponent(String(params.key))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListDTO>;
      }),
    );
  }
  /**
   * Create or Update Productlist
   * @param params The `ProductListService.ProductListCreateOrUpdateProductlistParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `key`:
   */
  ProductListCreateOrUpdateProductlist(
    params: ProductListService.ProductListCreateOrUpdateProductlistParams,
  ): __Observable<ResponseArgsOfProductListDTO> {
    return this.ProductListCreateOrUpdateProductlistResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfProductListDTO));
  }

  /**
   * Add Or Update List Items
   * @param params The `ProductListService.ProductListAddOrUpdateProductListItemsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `key`:
   */
  ProductListAddOrUpdateProductListItemsResponse(
    params: ProductListService.ProductListAddOrUpdateProductListItemsParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfProductListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payload;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/productlist/${encodeURIComponent(String(params.key))}/items`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfProductListItemDTO>;
      }),
    );
  }
  /**
   * Add Or Update List Items
   * @param params The `ProductListService.ProductListAddOrUpdateProductListItemsParams` containing the following parameters:
   *
   * - `payload`:
   *
   * - `key`:
   */
  ProductListAddOrUpdateProductListItems(
    params: ProductListService.ProductListAddOrUpdateProductListItemsParams,
  ): __Observable<ListResponseArgsOfProductListItemDTO> {
    return this.ProductListAddOrUpdateProductListItemsResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfProductListItemDTO));
  }

  /**
   * Artikelliste als PDF (base64)
   * @param payload DocumentPayload mit EANsK
   */
  ProductListProductListItemPdfAsBase64Response(
    payload: DocumentPayloadOfIEnumerableOfProductListItemDTO,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = payload;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist/items/pdf/base64`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfString>;
      }),
    );
  }
  /**
   * Artikelliste als PDF (base64)
   * @param payload DocumentPayload mit EANsK
   */
  ProductListProductListItemPdfAsBase64(payload: DocumentPayloadOfIEnumerableOfProductListItemDTO): __Observable<ResponseArgsOfString> {
    return this.ProductListProductListItemPdfAsBase64Response(payload).pipe(__map((_r) => _r.body as ResponseArgsOfString));
  }

  /**
   * @param queryTokenDTO undefined
   */
  ProductListQueryProductListResponse(queryTokenDTO: QueryTokenDTO): __Observable<__StrictHttpResponse<ListResponseArgsOfProductListDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = queryTokenDTO;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist/s`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfProductListDTO>;
      }),
    );
  }
  /**
   * @param queryTokenDTO undefined
   */
  ProductListQueryProductList(queryTokenDTO: QueryTokenDTO): __Observable<ListResponseArgsOfProductListDTO> {
    return this.ProductListQueryProductListResponse(queryTokenDTO).pipe(__map((_r) => _r.body as ListResponseArgsOfProductListDTO));
  }

  /**
   * @param params The `ProductListService.ProductListCreateProductListParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ProductListCreateProductListResponse(
    params: ProductListService.ProductListCreateProductListParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfProductListDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListDTO>;
      }),
    );
  }
  /**
   * @param params The `ProductListService.ProductListCreateProductListParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ProductListCreateProductList(params: ProductListService.ProductListCreateProductListParams): __Observable<ResponseArgsOfProductListDTO> {
    return this.ProductListCreateProductListResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfProductListDTO));
  }

  /**
   * @param params The `ProductListService.ProductListUpdateProductListParams` containing the following parameters:
   *
   * - `productlistUId`:
   *
   * - `data`:
   *
   * - `productListItemUId`:
   *
   * - `locale`:
   */
  ProductListUpdateProductListResponse(
    params: ProductListService.ProductListUpdateProductListParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfProductListDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.productListItemUId != null) __params = __params.set('productListItemUId', params.productListItemUId.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/inventory/productlist/${encodeURIComponent(String(params.productlistUId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListDTO>;
      }),
    );
  }
  /**
   * @param params The `ProductListService.ProductListUpdateProductListParams` containing the following parameters:
   *
   * - `productlistUId`:
   *
   * - `data`:
   *
   * - `productListItemUId`:
   *
   * - `locale`:
   */
  ProductListUpdateProductList(params: ProductListService.ProductListUpdateProductListParams): __Observable<ResponseArgsOfProductListDTO> {
    return this.ProductListUpdateProductListResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfProductListDTO));
  }

  /**
   * @param params The `ProductListService.ProductListGetProductlistItemsParams` containing the following parameters:
   *
   * - `productlistUId`:
   *
   * - `take`:
   *
   * - `skip`:
   */
  ProductListGetProductlistItemsResponse(
    params: ProductListService.ProductListGetProductlistItemsParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfProductListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/productlist/${encodeURIComponent(String(params.productlistUId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfProductListItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ProductListService.ProductListGetProductlistItemsParams` containing the following parameters:
   *
   * - `productlistUId`:
   *
   * - `take`:
   *
   * - `skip`:
   */
  ProductListGetProductlistItems(
    params: ProductListService.ProductListGetProductlistItemsParams,
  ): __Observable<ListResponseArgsOfProductListItemDTO> {
    return this.ProductListGetProductlistItemsResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfProductListItemDTO));
  }

  /**
   * @param productlistUId undefined
   */
  ProductListProductlistCompletedResponse(productlistUId: string): __Observable<__StrictHttpResponse<ResponseArgsOfProductListDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/productlist/${encodeURIComponent(String(productlistUId))}/completed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListDTO>;
      }),
    );
  }
  /**
   * @param productlistUId undefined
   */
  ProductListProductlistCompleted(productlistUId: string): __Observable<ResponseArgsOfProductListDTO> {
    return this.ProductListProductlistCompletedResponse(productlistUId).pipe(__map((_r) => _r.body as ResponseArgsOfProductListDTO));
  }

  /**
   * @param params The `ProductListService.ProductListCreateProductListItemParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ProductListCreateProductListItemResponse(
    params: ProductListService.ProductListCreateProductListItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfProductListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist/items`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ProductListService.ProductListCreateProductListItemParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ProductListCreateProductListItem(
    params: ProductListService.ProductListCreateProductListItemParams,
  ): __Observable<ResponseArgsOfProductListItemDTO> {
    return this.ProductListCreateProductListItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfProductListItemDTO));
  }

  /**
   * @param params The `ProductListService.ProductListUpdateProductListItemParams` containing the following parameters:
   *
   * - `productListItemUId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ProductListUpdateProductListItemResponse(
    params: ProductListService.ProductListUpdateProductListItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfProductListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/inventory/productlist/items/${encodeURIComponent(String(params.productListItemUId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ProductListService.ProductListUpdateProductListItemParams` containing the following parameters:
   *
   * - `productListItemUId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ProductListUpdateProductListItem(
    params: ProductListService.ProductListUpdateProductListItemParams,
  ): __Observable<ResponseArgsOfProductListItemDTO> {
    return this.ProductListUpdateProductListItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfProductListItemDTO));
  }

  /**
   * @param productListItemUId undefined
   */
  ProductListProductListItemCompletedResponse(
    productListItemUId: string,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfProductListItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/productlist/items/${encodeURIComponent(String(productListItemUId))}/completed`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfProductListItemDTO>;
      }),
    );
  }
  /**
   * @param productListItemUId undefined
   */
  ProductListProductListItemCompleted(productListItemUId: string): __Observable<ResponseArgsOfProductListItemDTO> {
    return this.ProductListProductListItemCompletedResponse(productListItemUId).pipe(
      __map((_r) => _r.body as ResponseArgsOfProductListItemDTO),
    );
  }

  /**
   * @param uids undefined
   */
  ProductListProductListItemsCompletedResponse(
    uids: Array<string>,
  ): __Observable<__StrictHttpResponse<BatchResponseArgsOfProductListItemDTOAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = uids;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/productlist/items/completed`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BatchResponseArgsOfProductListItemDTOAndString>;
      }),
    );
  }
  /**
   * @param uids undefined
   */
  ProductListProductListItemsCompleted(uids: Array<string>): __Observable<BatchResponseArgsOfProductListItemDTOAndString> {
    return this.ProductListProductListItemsCompletedResponse(uids).pipe(
      __map((_r) => _r.body as BatchResponseArgsOfProductListItemDTOAndString),
    );
  }
}

module ProductListService {
  /**
   * Parameters for ProductListCreateOrUpdateProductlist
   */
  export interface ProductListCreateOrUpdateProductlistParams {
    payload: ProductListDTO;
    key: string;
  }

  /**
   * Parameters for ProductListAddOrUpdateProductListItems
   */
  export interface ProductListAddOrUpdateProductListItemsParams {
    payload: Array<ProductListItemDTO>;
    key: string;
  }

  /**
   * Parameters for ProductListCreateProductList
   */
  export interface ProductListCreateProductListParams {
    data: ProductListDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ProductListUpdateProductList
   */
  export interface ProductListUpdateProductListParams {
    productlistUId: string;
    data: ProductListDTO;
    productListItemUId?: null | string;
    locale?: null | string;
  }

  /**
   * Parameters for ProductListGetProductlistItems
   */
  export interface ProductListGetProductlistItemsParams {
    productlistUId: string;
    take?: null | number;
    skip?: null | number;
  }

  /**
   * Parameters for ProductListCreateProductListItem
   */
  export interface ProductListCreateProductListItemParams {
    data: ProductListItemDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ProductListUpdateProductListItem
   */
  export interface ProductListUpdateProductListItemParams {
    productListItemUId: string;
    data: ProductListItemDTO;
    locale?: null | string;
  }
}

export { ProductListService };
