import { Injectable, inject } from '@angular/core';
import { NavigationRoute } from './defs/navigation-route';
import { Router } from '@angular/router';
import { Config } from '@core/config';
import { DBHOrderItemListItemDTO, OrderItemProcessingStatusValue } from '@swagger/oms';
import { isBoolean } from 'lodash';

@Injectable({ providedIn: 'root' })
export class PickupShelfInNavigationService {
  private readonly _router = inject(Router);

  defaultRoute(): NavigationRoute {
    const path = ['/filiale', 'pickup-shelf', { outlets: { primary: ['main'], side: ['search'] } }].filter((v) => !!v);

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  listRoute(): NavigationRoute {
    const path = ['/filiale', 'pickup-shelf', { outlets: { primary: ['list'], side: null } }].filter((v) => !!v);

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  filterRoute(): NavigationRoute {
    const path = ['/filiale', 'pickup-shelf', { outlets: { primary: ['list', 'filter'], side: ['list'] } }].filter((v) => !!v);

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  detailRoute({ item, side }: { item: DBHOrderItemListItemDTO; side?: boolean }): NavigationRoute {
    let path: any[];
    side = isBoolean(side) ? side : true;

    if (!item.orderItemSubsetId) {
      console.log(item);
      throw new Error('orderItemSubsetId is missing');
    }

    if (item.compartmentCode) {
      path = [
        '/filiale',
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              'compartment',
              item.compartmentCode ? encodeURIComponent(item.compartmentCode) : undefined,
              item.compartmentInfo ? encodeURIComponent(item.compartmentInfo) : undefined,
              item.orderItemSubsetId,
            ].filter((v) => !!v),
            side: side ? ['list'] : null,
          },
        },
      ].filter((v) => !!v);
    } else {
      if (!item.processingStatus) {
        console.log(item);
        throw new Error('processingStatus is missing');
      }

      path = [
        '/filiale',
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              encodeURIComponent(item.orderNumber),
              'item',
              'status',
              item.processingStatus,
              item.orderItemSubsetId,
            ].filter((v) => !!v),
            side: side ? ['list'] : null,
          },
        },
      ].filter((v) => !!v);
    }

    const queryParams = {
      side: String(side),
    };

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  editRoute(
    item: {
      orderId: number;
      orderNumber: string;
      compartmentCode: string;
      processingStatus: OrderItemProcessingStatusValue;
      orderItemSubsetId: number;
      compartmentInfo: string;
    },
    { side }: { side?: boolean } = { side: true },
  ): NavigationRoute {
    let path: any[];

    if (!item.orderItemSubsetId) {
      console.log(item);
      throw new Error('orderItemSubsetId is missing');
    }

    if (item.compartmentCode) {
      path = [
        '/filiale',
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              'compartment',
              item.compartmentCode ? encodeURIComponent(item.compartmentCode) : undefined,
              item.compartmentInfo ? encodeURIComponent(item.compartmentInfo) : undefined,
              item.orderItemSubsetId,
              'edit',
            ].filter((v) => !!v),
            side: side ? ['list'] : null,
          },
        },
      ].filter((v) => !!v);
    } else {
      path = [
        '/filiale',
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              encodeURIComponent(item.orderNumber),
              'item',
              'status',
              item.processingStatus,
              item.orderItemSubsetId,
              'edit',
            ].filter((v) => !!v),
            side: side ? ['list'] : null,
          },
        },
      ].filter((v) => !!v);
    }

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }

  historyRoute(
    item: {
      orderId: number;
      orderNumber: string;
      compartmentCode: string;
      processingStatus: OrderItemProcessingStatusValue;
      orderItemSubsetId: number;
      compartmentInfo: string;
    },
    { side }: { side?: boolean } = { side: true },
  ): NavigationRoute {
    let path: any[];

    if (!item.orderItemSubsetId) {
      console.log(item);
      throw new Error('orderItemSubsetId is missing');
    }

    if (item.compartmentCode) {
      path = [
        '/filiale',
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              'compartment',
              item.compartmentCode ? encodeURIComponent(item.compartmentCode) : undefined,
              item.compartmentInfo ? encodeURIComponent(item.compartmentInfo) : undefined,
              item.orderItemSubsetId,
              'history',
            ].filter((v) => !!v),
            side: side ? ['list'] : null,
          },
        },
      ].filter((v) => !!v);
    } else {
      path = [
        '/filiale',
        'pickup-shelf',
        {
          outlets: {
            primary: [
              'order',
              item.orderId,
              encodeURIComponent(item.orderNumber),
              'item',
              'status',
              item.processingStatus,
              item.orderItemSubsetId,
              'history',
            ].filter((v) => !!v),
            side: side ? ['list'] : null,
          },
        },
      ].filter((v) => !!v);
    }

    const queryParams = {};

    const urlTree = this._router.createUrlTree(path, { queryParams });

    return {
      path,
      queryParams,
      urlTree,
    };
  }
}
