/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { PrintConfiguration as __Configuration } from '../print-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgs } from '../models/response-args';
import { PrintRequestOfIEnumerableOfLong } from '../models/print-request-of-ienumerable-of-long';
import { PrintRequestOfIEnumerableOfDisplayOrderDTO } from '../models/print-request-of-ienumerable-of-display-order-dto';
import { PrintRequestOfIEnumerableOfPriceQRCodeDTO } from '../models/print-request-of-ienumerable-of-price-qrcode-dto';
@Injectable({
  providedIn: 'root',
})
class OMSPrintService extends __BaseService {
  static readonly OMSPrintAbholscheinByIdPath = '/print/abholschein';
  static readonly OMSPrintAbholscheinPath = '/print/abholschein/data';
  static readonly OMSPrintAbholfachetikettPath = '/print/abholfachetikett';
  static readonly OMSPrintAbholfachetikettDirectPath = '/print-direct/abholfachetikett';
  static readonly OMSPrintPriceQRCodePath = '/print/priceqrcode';
  static readonly OMSPrintPriceQRCodeDirectPath = '/print-direct/priceqrcode';
  static readonly OMSPrintLieferscheinPath = '/print/lieferschein';
  static readonly OMSPrintKleinbetragsrechnungPath = '/print/kleinbetragsrechnung';
  static readonly OMSPrintKleinbetragsrechnungPdfPath = '/print/kleinbetragsrechnung/{receiptId}/pdf';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Abholschein oder Bestellbestätigung
   * @param data Bestellung PKs
   */
  OMSPrintAbholscheinByIdResponse(data: PrintRequestOfIEnumerableOfLong): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/abholschein`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Abholschein oder Bestellbestätigung
   * @param data Bestellung PKs
   */
  OMSPrintAbholscheinById(data: PrintRequestOfIEnumerableOfLong): __Observable<ResponseArgs> {
    return this.OMSPrintAbholscheinByIdResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Abholschein oder Bestellbestätigung
   * @param data Bestellungen (Objekte)
   */
  OMSPrintAbholscheinResponse(data: PrintRequestOfIEnumerableOfDisplayOrderDTO): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/abholschein/data`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Abholschein oder Bestellbestätigung
   * @param data Bestellungen (Objekte)
   */
  OMSPrintAbholschein(data: PrintRequestOfIEnumerableOfDisplayOrderDTO): __Observable<ResponseArgs> {
    return this.OMSPrintAbholscheinResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Abholfachetikett
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintAbholfachetikettResponse(data: PrintRequestOfIEnumerableOfLong): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/abholfachetikett`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Abholfachetikett
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintAbholfachetikett(data: PrintRequestOfIEnumerableOfLong): __Observable<ResponseArgs> {
    return this.OMSPrintAbholfachetikettResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Abholfachetikett (direkte Druckeransteuerung)
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintAbholfachetikettDirectResponse(data: PrintRequestOfIEnumerableOfLong): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print-direct/abholfachetikett`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Abholfachetikett (direkte Druckeransteuerung)
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintAbholfachetikettDirect(data: PrintRequestOfIEnumerableOfLong): __Observable<ResponseArgs> {
    return this.OMSPrintAbholfachetikettDirectResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Abholfachpreis-Etikett
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintPriceQRCodeResponse(data: PrintRequestOfIEnumerableOfPriceQRCodeDTO): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/priceqrcode`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Abholfachpreis-Etikett
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintPriceQRCode(data: PrintRequestOfIEnumerableOfPriceQRCodeDTO): __Observable<ResponseArgs> {
    return this.OMSPrintPriceQRCodeResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Abholfachpreis-Etikett (direkte Druckeransteuerung)
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintPriceQRCodeDirectResponse(data: PrintRequestOfIEnumerableOfPriceQRCodeDTO): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print-direct/priceqrcode`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Abholfachpreis-Etikett (direkte Druckeransteuerung)
   * @param data Bestellpostenteilmenge PKs
   */
  OMSPrintPriceQRCodeDirect(data: PrintRequestOfIEnumerableOfPriceQRCodeDTO): __Observable<ResponseArgs> {
    return this.OMSPrintPriceQRCodeDirectResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Lieferschein
   * @param data Lieferschein PKs
   */
  OMSPrintLieferscheinResponse(data: PrintRequestOfIEnumerableOfLong): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/lieferschein`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Lieferschein
   * @param data Lieferschein PKs
   */
  OMSPrintLieferschein(data: PrintRequestOfIEnumerableOfLong): __Observable<ResponseArgs> {
    return this.OMSPrintLieferscheinResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Kleinbetragsrechnung
   * @param data Kleinbetragsrechnung PKs
   */
  OMSPrintKleinbetragsrechnungResponse(data: PrintRequestOfIEnumerableOfLong): __Observable<__StrictHttpResponse<ResponseArgs>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>('POST', this.rootUrl + `/print/kleinbetragsrechnung`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgs>;
      }),
    );
  }
  /**
   * Kleinbetragsrechnung
   * @param data Kleinbetragsrechnung PKs
   */
  OMSPrintKleinbetragsrechnung(data: PrintRequestOfIEnumerableOfLong): __Observable<ResponseArgs> {
    return this.OMSPrintKleinbetragsrechnungResponse(data).pipe(__map((_r) => _r.body as ResponseArgs));
  }

  /**
   * Kleinbetragsrechnung PDF
   * @param receiptId Kleinbetragsrechnung PK
   */
  OMSPrintKleinbetragsrechnungPdfResponse(receiptId: number): __Observable<__StrictHttpResponse<Blob>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/print/kleinbetragsrechnung/${encodeURIComponent(String(receiptId))}/pdf`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'blob',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Blob>;
      }),
    );
  }
  /**
   * Kleinbetragsrechnung PDF
   * @param receiptId Kleinbetragsrechnung PK
   */
  OMSPrintKleinbetragsrechnungPdf(receiptId: number): __Observable<Blob> {
    return this.OMSPrintKleinbetragsrechnungPdfResponse(receiptId).pipe(__map((_r) => _r.body as Blob));
  }
}

module OMSPrintService {}

export { OMSPrintService };
