<table>
  <thead>
    <tr>
      <th>M</th>
      <th>D</th>
      <th>M</th>
      <th>D</th>
      <th>F</th>
      <th>S</th>
      <th>S</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let week of dates$ | async" class="row">
      <td *ngFor="let date of week" class="cell" [appUiDatepickerCell]="date">
        {{ date | getCellName }}
      </td>
    </tr>
  </tbody>
</table>
