import { inject, Injectable } from '@angular/core';
import { CrmCustomerService } from '@domain/crm';
import { AddressDTO } from '@swagger/crm';
import { UiModalService } from '@ui/modal';
import { map } from 'rxjs/operators';
import { AddressSelectionModalComponent } from './address-selection-modal.component';

@Injectable({ providedIn: 'root' })
export class AddressSelectionModalService {
  private modal = inject(UiModalService);

  constructor(private customerService: CrmCustomerService) {}

  async validateAddress(address: AddressDTO): Promise<AddressDTO> {
    if (address.street && address.streetNumber && address.zipCode && address.city && address.country) {
      try {
        let addresses = await this.customerService
          .validateAddress(address)
          .pipe(
            map((response) =>
              response.result?.map((ad) => ({
                ...address,
                ...ad,
              })),
            ),
          )
          .toPromise();

        if (addresses?.length > 0) {
          const modalResult = await this.modal.open({ content: AddressSelectionModalComponent, data: addresses }).afterClosed$.toPromise();
          if (modalResult?.data) {
            if (modalResult.data === 'continue') {
              return address;
            } else {
              return modalResult.data;
            }
          }
        }
      } catch (error) {
        throw error;
      }
    }
    return address;
  }
}
