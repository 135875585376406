import { Component, ChangeDetectionStrategy, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { IOption, Option } from '../../../tree';

@Component({
  selector: 'shared-input-option-tri-state',
  templateUrl: 'filter-input-option-tri-state.component.html',
  styleUrls: ['filter-input-option-tri-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputOptionTriStateComponent implements OnDestroy {
  private _option: Option;

  @Input()
  set option(value: IOption) {
    if (value instanceof Option) {
      this._option = value;
    } else {
      this._option = Option.create(value);
    }
    this.subscribeChanges();
  }

  get uiOption() {
    return this._option;
  }

  optionChangeSubscription = new Subscription();

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.unsubscribeChanges();
  }

  subscribeChanges() {
    this.unsubscribeChanges();
    if (this.uiOption) {
      this.optionChangeSubscription.add(
        this.uiOption.changes.subscribe(() => {
          this.cdr.markForCheck();
        }),
      );
    }
  }

  unsubscribeChanges() {
    this.optionChangeSubscription.unsubscribe();
    this.optionChangeSubscription = new Subscription();
  }
}
