import { isDevMode, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  CanActivateCartGuard,
  CanActivateCartWithProcessIdGuard,
  CanActivateCustomerGuard,
  CanActivateCustomerOrdersGuard,
  CanActivateCustomerOrdersWithProcessIdGuard,
  CanActivateCustomerWithProcessIdGuard,
  CanActivateGoodsInGuard,
  CanActivateProductGuard,
  CanActivateProductWithProcessIdGuard,
  CanActivateRemissionGuard,
  CanActivateTaskCalendarGuard,
  IsAuthenticatedGuard,
} from './guards';
import { CanActivateAssortmentGuard } from './guards/can-activate-assortment.guard';
import { CanActivatePackageInspectionGuard } from './guards/can-activate-package-inspection.guard';
import { MainComponent } from './main.component';
import { PreviewComponent } from './preview';
import { BranchSectionResolver, CustomerSectionResolver, ProcessIdResolver } from './resolvers';
import { TokenLoginComponent, TokenLoginModule } from './token-login';
import { ProcessIdGuard } from './guards/process-id.guard';
import {
  ActivateProcessIdGuard,
  ActivateProcessIdWithConfigKeyGuard,
} from './guards/activate-process-id.guard';
import { MatomoRouteData } from 'ngx-matomo-client';
import { processResolverFn } from '@isa/core/process';
import { provideScrollPositionRestoration } from '@isa/core/scroll-position';

const routes: Routes = [
  { path: '', redirectTo: 'kunde/dashboard', pathMatch: 'full' },
  {
    path: 'login',
    children: [
      { path: ':token', component: TokenLoginComponent },
      { path: '**', redirectTo: 'kunde', pathMatch: 'full' },
    ],
  },
  {
    path: '',
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'kunde',
        component: MainComponent,
        children: [
          {
            path: 'dashboard',
            loadChildren: () => import('@page/dashboard').then((m) => m.DashboardModule),
            data: {
              matomo: {
                title: 'Dashboard',
              } as MatomoRouteData,
            },
          },
          {
            path: 'product',
            loadChildren: () => import('@page/catalog').then((m) => m.PageCatalogModule),
            canActivate: [CanActivateProductGuard],
          },
          {
            path: ':processId/product',
            loadChildren: () => import('@page/catalog').then((m) => m.PageCatalogModule),
            canActivate: [CanActivateProductWithProcessIdGuard],
            resolve: { processId: ProcessIdResolver },
          },
          {
            path: 'order',
            loadChildren: () => import('@page/customer-order').then((m) => m.CustomerOrderModule),
            canActivate: [CanActivateCustomerOrdersGuard],
          },
          {
            path: ':processId/order',
            loadChildren: () => import('@page/customer-order').then((m) => m.CustomerOrderModule),
            canActivate: [CanActivateCustomerOrdersWithProcessIdGuard],
            resolve: { processId: ProcessIdResolver },
          },
          {
            path: 'customer',
            loadChildren: () => import('@page/customer').then((m) => m.CustomerModule),
            canActivate: [CanActivateCustomerGuard],
          },
          {
            path: ':processId/customer',
            loadChildren: () => import('@page/customer').then((m) => m.CustomerModule),
            canActivate: [CanActivateCustomerWithProcessIdGuard],
            resolve: { processId: ProcessIdResolver },
          },
          {
            path: 'cart',
            loadChildren: () => import('@page/checkout').then((m) => m.PageCheckoutModule),
            canActivate: [CanActivateCartGuard],
          },
          {
            path: ':processId/cart',
            loadChildren: () => import('@page/checkout').then((m) => m.PageCheckoutModule),
            canActivate: [CanActivateCartWithProcessIdGuard],
          },
          {
            path: 'pickup-shelf',
            canActivate: [ProcessIdGuard],
            // NOTE: This is a workaround for the canActivate guard not being called
            loadChildren: () => import('@page/pickup-shelf').then((m) => m.PickupShelfOutModule),
          },
          {
            path: ':processId/pickup-shelf',
            canActivate: [ActivateProcessIdGuard],
            loadChildren: () => import('@page/pickup-shelf').then((m) => m.PickupShelfOutModule),
          },
          { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
        resolve: { section: CustomerSectionResolver },
      },
      {
        path: 'filiale',
        component: MainComponent,
        children: [
          {
            path: 'task-calendar',
            loadChildren: () => import('@page/task-calendar').then((m) => m.PageTaskCalendarModule),
            canActivate: [CanActivateTaskCalendarGuard],
          },
          {
            path: 'pickup-shelf',
            canActivate: [ActivateProcessIdWithConfigKeyGuard('pickupShelf')],
            // NOTE: This is a workaround for the canActivate guard not being called
            loadChildren: () => import('@page/pickup-shelf').then((m) => m.PickupShelfInModule),
          },
          {
            path: 'goods/in',
            loadChildren: () => import('@page/goods-in').then((m) => m.GoodsInModule),
            canActivate: [CanActivateGoodsInGuard],
          },
          {
            path: 'remission',
            loadChildren: () => import('@page/remission').then((m) => m.PageRemissionModule),
            canActivate: [CanActivateRemissionGuard],
          },
          {
            path: 'package-inspection',
            loadChildren: () =>
              import('@page/package-inspection').then((m) => m.PackageInspectionModule),
            canActivate: [CanActivatePackageInspectionGuard],
          },
          {
            path: 'assortment',
            loadChildren: () => import('@page/assortment').then((m) => m.AssortmentModule),
            canActivate: [CanActivateAssortmentGuard],
          },
          { path: '**', redirectTo: 'task-calendar', pathMatch: 'full' },
        ],
        resolve: { section: BranchSectionResolver },
      },
    ],
  },
  {
    path: ':processId',
    component: MainComponent,
    resolve: { process: processResolverFn },
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'return',
        loadChildren: () => import('@isa/oms/feature/return-search').then((m) => m.routes),
      },
    ],
  },
];

if (isDevMode()) {
  routes.unshift({
    path: 'preview',
    component: PreviewComponent,
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes), TokenLoginModule],
  exports: [RouterModule],
  providers: [provideScrollPositionRestoration()],
})
export class AppRoutingModule {}
