import { Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { CustomerDTO } from '@swagger/crm';
import { CheckoutEntity } from './defs/checkout.entity';
import { storeCheckoutAdapter, storeFeatureSelector } from './domain-checkout.state';

const { selectAll, selectEntities, selectIds, selectTotal } = storeCheckoutAdapter.getSelectors(storeFeatureSelector);

export const selectCheckoutEntityByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId],
);

export const selectShoppingCartByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.shoppingCart,
);

export const selectCheckoutByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.checkout,
);

export const selectCustomerFeaturesByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => getCusomterFeatures(entities[processId]?.customer),
);

export const selectShippingAddressByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.shippingAddress,
);

export const selectPayerByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.payer,
);

export const selectBuyerByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.buyer,
);

export const selectSpecialComment = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.specialComment,
);

export const selectNotificationChannels = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.notificationChannels,
);

export const selectBuyerCommunicationDetails = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.buyer?.communicationDetails,
);

export const selectOrders = createSelector(storeFeatureSelector, (s) => s.orders);

export const selectOlaErrorsByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.olaErrorIds,
);

export const selectCustomerByProcessId = createSelector(
  selectEntities,
  (entities: Dictionary<CheckoutEntity>, { processId }: { processId: number }) => entities[processId]?.customer,
);

function getCusomterFeatures(custoemr: CustomerDTO): { [key: string]: string } {
  const customerFeatures = custoemr?.features ?? [];
  const features: { [key: string]: string } = {};

  for (const feature of customerFeatures) {
    features[feature.key] = feature.key;
  }

  return features;
}
