import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@core/auth';

@Component({
  selector: 'app-token-login',
  templateUrl: 'token-login.component.html',
  styleUrls: ['token-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TokenLoginComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
  ) {}

  ngOnInit() {
    if (this._route.snapshot.params.token && !this._authService.isAuthenticated()) {
      this._authService.setKeyCardToken(this._route.snapshot.params.token);
      this._authService.login();
    } else if (!this._authService.isAuthenticated()) {
      this._authService.login();
    } else if (this._authService.isAuthenticated()) {
      this._router.navigate(['/']);
    }
  }
}
