import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UiFilterInputGroupMainComponent } from './filter-input-group-main.component';
import { UiAutocompleteModule } from '@ui/autocomplete';
import { UiSearchboxNextModule } from '@ui/searchbox';
import { UiCommonModule } from '@ui/common';
import { UiTooltipModule } from '@ui/tooltip';

@NgModule({
  imports: [CommonModule, UiCommonModule, UiSearchboxNextModule, UiAutocompleteModule, UiTooltipModule],
  exports: [UiFilterInputGroupMainComponent],
  declarations: [UiFilterInputGroupMainComponent],
})
export class UiFilterInputGroupMainModule {}
