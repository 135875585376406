import { Component, OnInit, ContentChild, ViewChild, TemplateRef } from '@angular/core';
import { MenuComponent } from './menu.component';

@Component({
  selector: 'menu-template',
  template: `<ng-template #menuTpl><ng-content selector="menu"></ng-content></ng-template>`,
  exportAs: 'menuTemplate',
})
export class MenuTemplateComponent implements OnInit {
  @ContentChild(MenuComponent)
  menu: MenuComponent;

  @ViewChild('menuTpl', { read: TemplateRef, static: true })
  templateRef: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
