import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PickupShelfInNavigationService } from '@shared/services/navigation';
import { UiFilter } from '@ui/filter';
import { MessageBoardItemDTO } from '@hub/notifications';

@Component({
  selector: 'modal-notifications-remission-group',
  templateUrl: 'notifications-remission-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNotificationsRemissionGroupComponent {
  private _pickupShelfInNavigationService = inject(PickupShelfInNavigationService);

  @Input()
  notifications: MessageBoardItemDTO[];

  @Output()
  navigated = new EventEmitter<void>();

  constructor(private _router: Router) {}

  itemSelected(item: MessageBoardItemDTO) {
    const defaultNav = this._pickupShelfInNavigationService.listRoute();
    const queryParams = UiFilter.getQueryParamsFromQueryTokenDTO(item.queryToken);
    this._router.navigate(defaultNav.path, {
      queryParams: {
        ...defaultNav.queryParams,
        ...queryParams,
      },
    });
    this.navigated.emit();
  }
}
