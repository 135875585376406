/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfRequiredData } from '../models/response-args-of-ienumerable-of-required-data';
import { ResponseArgsOfCheckoutDTO } from '../models/response-args-of-checkout-dto';
import { PayerDTO } from '../models/payer-dto';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutPayerService extends __BaseService {
  static readonly StoreCheckoutPayerRequiredDataForPayerPath = '/store/checkout/{checkoutId}/payer/requireddata';
  static readonly StoreCheckoutPayerSetPayerPOSTPath = '/store/checkout/{checkoutId}/payer';
  static readonly StoreCheckoutPayerSetPayerPUTPath = '/store/checkout/{checkoutId}/payer';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `StoreCheckoutPayerService.StoreCheckoutPayerRequiredDataForPayerParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutPayerRequiredDataForPayerResponse(
    params: StoreCheckoutPayerService.StoreCheckoutPayerRequiredDataForPayerParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfRequiredData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/payer/requireddata`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfRequiredData>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutPayerService.StoreCheckoutPayerRequiredDataForPayerParams` containing the following parameters:
   *
   * - `checkoutId`:
   *
   * - `locale`:
   */
  StoreCheckoutPayerRequiredDataForPayer(
    params: StoreCheckoutPayerService.StoreCheckoutPayerRequiredDataForPayerParams,
  ): __Observable<ResponseArgsOfIEnumerableOfRequiredData> {
    return this.StoreCheckoutPayerRequiredDataForPayerResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfRequiredData),
    );
  }

  /**
   * @param params The `StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPOSTParams` containing the following parameters:
   *
   * - `payerDTO`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutPayerSetPayerPOSTResponse(
    params: StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPOSTParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payerDTO;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/payer`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPOSTParams` containing the following parameters:
   *
   * - `payerDTO`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutPayerSetPayerPOST(
    params: StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPOSTParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutPayerSetPayerPOSTResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }

  /**
   * @param params The `StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPUTParams` containing the following parameters:
   *
   * - `payerDTO`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutPayerSetPayerPUTResponse(
    params: StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPUTParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckoutDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.payerDTO;

    if (params.returnCheckout != null) __params = __params.set('returnCheckout', params.returnCheckout.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('PUT', this.rootUrl + `/store/checkout/${encodeURIComponent(String(params.checkoutId))}/payer`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckoutDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPUTParams` containing the following parameters:
   *
   * - `payerDTO`:
   *
   * - `checkoutId`:
   *
   * - `returnCheckout`:
   *
   * - `locale`:
   */
  StoreCheckoutPayerSetPayerPUT(
    params: StoreCheckoutPayerService.StoreCheckoutPayerSetPayerPUTParams,
  ): __Observable<ResponseArgsOfCheckoutDTO> {
    return this.StoreCheckoutPayerSetPayerPUTResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckoutDTO));
  }
}

module StoreCheckoutPayerService {
  /**
   * Parameters for StoreCheckoutPayerRequiredDataForPayer
   */
  export interface StoreCheckoutPayerRequiredDataForPayerParams {
    checkoutId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutPayerSetPayerPOST
   */
  export interface StoreCheckoutPayerSetPayerPOSTParams {
    payerDTO: PayerDTO;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutPayerSetPayerPUT
   */
  export interface StoreCheckoutPayerSetPayerPUTParams {
    payerDTO: PayerDTO;
    checkoutId: number;
    returnCheckout?: boolean;
    locale?: null | string;
  }
}

export { StoreCheckoutPayerService };
