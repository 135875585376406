/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CrmConfiguration as __Configuration } from '../crm-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-entity-key-value-dtoof-string-and-string';
import { ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-key-value-dtoof-string-and-string';
import { ResponseArgsOfCheckLoyaltyCardResult } from '../models/response-args-of-check-loyalty-card-result';
@Injectable({
  providedIn: 'root',
})
class LoyaltyCardService extends __BaseService {
  static readonly LoyaltyCardListInteressenPath = '/customer/interessen';
  static readonly LoyaltyCardSaveInteressenPath = '/customer/{customerId}/interessen';
  static readonly LoyaltyCardCheckLoyaltyCardPath = '/customer/loyaltycard/check';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Interessen
   * @param locale undefined
   */
  LoyaltyCardListInteressenResponse(
    locale?: null | string,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/customer/interessen`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Interessen
   * @param locale undefined
   */
  LoyaltyCardListInteressen(locale?: null | string): __Observable<ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString> {
    return this.LoyaltyCardListInteressenResponse(locale).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfEntityKeyValueDTOOfStringAndString),
    );
  }

  /**
   * Interessen speichern
   * @param params The `LoyaltyCardService.LoyaltyCardSaveInteressenParams` containing the following parameters:
   *
   * - `interessen`:
   *
   * - `customerId`: PK Kunde
   *
   * - `locale`:
   */
  LoyaltyCardSaveInteressenResponse(
    params: LoyaltyCardService.LoyaltyCardSaveInteressenParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.interessen;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/customer/${encodeURIComponent(String(params.customerId))}/interessen`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Interessen speichern
   * @param params The `LoyaltyCardService.LoyaltyCardSaveInteressenParams` containing the following parameters:
   *
   * - `interessen`:
   *
   * - `customerId`: PK Kunde
   *
   * - `locale`:
   */
  LoyaltyCardSaveInteressen(
    params: LoyaltyCardService.LoyaltyCardSaveInteressenParams,
  ): __Observable<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString> {
    return this.LoyaltyCardSaveInteressenResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString),
    );
  }

  /**
   * Kundenkartennummer prüfen
   * @param params The `LoyaltyCardService.LoyaltyCardCheckLoyaltyCardParams` containing the following parameters:
   *
   * - `loyaltyCardNumber`: Kundenkarten Code
   *
   * - `locale`:
   *
   * - `customerId`: PK Kunde (optional)
   */
  LoyaltyCardCheckLoyaltyCardResponse(
    params: LoyaltyCardService.LoyaltyCardCheckLoyaltyCardParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfCheckLoyaltyCardResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.loyaltyCardNumber != null) __params = __params.set('loyaltyCardNumber', params.loyaltyCardNumber.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.customerId != null) __params = __params.set('customerId', params.customerId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/customer/loyaltycard/check`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfCheckLoyaltyCardResult>;
      }),
    );
  }
  /**
   * Kundenkartennummer prüfen
   * @param params The `LoyaltyCardService.LoyaltyCardCheckLoyaltyCardParams` containing the following parameters:
   *
   * - `loyaltyCardNumber`: Kundenkarten Code
   *
   * - `locale`:
   *
   * - `customerId`: PK Kunde (optional)
   */
  LoyaltyCardCheckLoyaltyCard(
    params: LoyaltyCardService.LoyaltyCardCheckLoyaltyCardParams,
  ): __Observable<ResponseArgsOfCheckLoyaltyCardResult> {
    return this.LoyaltyCardCheckLoyaltyCardResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfCheckLoyaltyCardResult));
  }
}

module LoyaltyCardService {
  /**
   * Parameters for LoyaltyCardSaveInteressen
   */
  export interface LoyaltyCardSaveInteressenParams {
    interessen: Array<string>;

    /**
     * PK Kunde
     */
    customerId: number;
    locale?: null | string;
  }

  /**
   * Parameters for LoyaltyCardCheckLoyaltyCard
   */
  export interface LoyaltyCardCheckLoyaltyCardParams {
    /**
     * Kundenkarten Code
     */
    loyaltyCardNumber?: null | string;
    locale?: null | string;

    /**
     * PK Kunde (optional)
     */
    customerId?: null | number;
  }
}

export { LoyaltyCardService };
