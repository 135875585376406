import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedHistoryListComponent } from './history-list.component';
import { SharedHistoryLogEntryModule } from './history-log-entry';

@NgModule({
  imports: [CommonModule, SharedHistoryLogEntryModule],
  exports: [SharedHistoryListComponent],
  declarations: [SharedHistoryListComponent],
  providers: [],
})
export class SharedHistoryListModule {}
