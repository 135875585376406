import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageBoardItemDTO } from '@hub/notifications';

@Component({
  selector: 'modal-notifications-list-item',
  templateUrl: 'notifications-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalNotificationsListItemComponent {
  @Input()
  item: MessageBoardItemDTO;

  @Output()
  itemSelected = new EventEmitter<MessageBoardItemDTO>();

  @Input()
  editButton = true;

  @Input()
  editButtonLabel = 'Bearbeiten';

  constructor() {}
}
