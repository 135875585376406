import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerDetailsViewMainComponent } from './details-main-view.component';
import { CountryPipe } from '@shared/pipes/country';
import { RouterModule } from '@angular/router';
import { DetailsMainViewBillingAddressesComponent } from './details-main-view-billing-addresses/details-main-view-billing-addresses.component';
import { DetailsMainViewDeliveryAddressesComponent } from './details-main-view-delivery-addresses/details-main-view-delivery-addresses.component';
import { LoaderComponent } from '@shared/components/loader';
import { IconComponent } from '@shared/components/icon';
import { CustomerMenuComponent } from '../../components/customer-menu';

@NgModule({
  imports: [
    LoaderComponent,
    CommonModule,
    CountryPipe,
    RouterModule,
    DetailsMainViewBillingAddressesComponent,
    DetailsMainViewDeliveryAddressesComponent,
    LoaderComponent,
    IconComponent,
    CustomerMenuComponent,
  ],
  exports: [CustomerDetailsViewMainComponent],
  declarations: [CustomerDetailsViewMainComponent],
})
export class CustomerDetailsMainViewModule {}
