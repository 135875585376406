import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'shared-input-control-indicator',
  template: `<ng-template #template>
    <div class="shared-input-control-indicator">
      <ng-content></ng-content>
    </div>
  </ng-template>`,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class IndicatorComponent {
  @Input()
  invalid: boolean | undefined;

  @Input()
  valid: boolean | undefined;

  @Input()
  dirty: boolean | undefined;

  @Input()
  disabled: boolean | undefined;

  @Input()
  enabled: boolean | undefined;

  @Input()
  pristine: boolean | undefined;

  @Input()
  pending: boolean | undefined;

  @ViewChild('template', { static: true }) tempalteRef: TemplateRef<any>;

  constructor() {}
}
