import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerComponent } from './customer-page.component';
import { RouterModule } from '@angular/router';
import { routes } from './routes';

import { BreadcrumbModule } from '@shared/components/breadcrumb';
import { CustomerSearchModule } from './customer-search/customer-search.module';
import { CreateCustomerModule } from './create-customer';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), BreadcrumbModule, CustomerSearchModule, CreateCustomerModule],
  exports: [CustomerComponent],
  declarations: [CustomerComponent],
})
export class CustomerModule {}
