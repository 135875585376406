import { inject, Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { from, NEVER, Observable, throwError } from 'rxjs';
import { UiMessageModalComponent, UiModalService } from '@ui/modal';
import { catchError, filter, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AuthService, LoginStrategy } from '@core/auth';
import { IsaLogProvider } from '../providers';
import { LogLevel } from '@core/logger';
import { injectOnline$ } from '../services/network-status.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  readonly offline$ = injectOnline$().pipe(filter((online) => !online));
  readonly injector = inject(Injector);

  constructor(
    private _modal: UiModalService,
    private _auth: AuthService,
    private _isaLogProvider: IsaLogProvider,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      takeUntil(this.offline$),
      catchError((error: HttpErrorResponse, caught: any) => this.handleError(error)),
    );
  }

  handleError(error: HttpErrorResponse): Observable<any> {
    if (error.status === 401) {
      const strategy = this.injector.get(LoginStrategy);

      return from(strategy.login('Sie sind nicht mehr angemeldet')).pipe(mergeMap(() => NEVER));
    }

    if (!error.url.endsWith('/isa/logging')) {
      this._isaLogProvider.log(LogLevel.ERROR, 'Http Error', error);
    }

    return throwError(error);
  }
}
