import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerResultsMainViewComponent } from './results-main-view.component';
import { CustomerResultListModule } from '../../components/customer-result-list/customer-result-list.module';
import { FilterModule } from '@shared/components/filter';
import { RouterModule } from '@angular/router';
import { IconComponent } from '@shared/components/icon';

@NgModule({
  imports: [CommonModule, CustomerResultListModule, FilterModule, RouterModule, IconComponent],
  exports: [CustomerResultsMainViewComponent],
  declarations: [CustomerResultsMainViewComponent],
})
export class CustomerResultsMainViewModule {}
