import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AddressSelectionModalComponent } from './address-selection-modal.component';
import { UiIconModule } from '@ui/icon';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [AddressSelectionModalComponent],
  declarations: [AddressSelectionModalComponent],
})
export class AddressSelectionModalModule {}
