import { UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

export function genderLastNameValidator(isB2b: boolean): ValidatorFn | null {
  if (isB2b) {
    return (control: UntypedFormGroup): ValidationErrors | null => {
      const gender = control.get('gender').value;
      const lastName = control.get('lastName').value;
      if (!!lastName) {
        control.get('gender').setValidators([Validators.required]);
        return { genderNotSet: true };
      } else {
        control.get('gender').setValidators(null);
        return null;
      }
    };
  } else {
    return null;
  }
}
