import { Directive, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from './auth.service';

@Directive({
  selector: '[ifRole],[ifRoleElse],[ifNotRole],[ifNotRoleElse]',
})
export class IfRoleDirective implements OnChanges {
  @Input()
  ifRole: string | string[];

  @Input()
  ifRoleElse: TemplateRef<any>;

  @Input()
  ifNotRole: string | string[];

  @Input()
  ifNotRoleElse: TemplateRef<any>;

  get renderTemplateRef() {
    if (this.ifRole) {
      return this._authService.hasRole(this.ifRole);
    }
    if (this.ifNotRole) {
      return !this._authService.hasRole(this.ifNotRole);
    }
    return false;
  }

  get elseTemplateRef() {
    return this.ifRoleElse || this.ifNotRoleElse;
  }

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _authService: AuthService,
  ) {}

  ngOnChanges() {
    this.render();
  }

  render() {
    if (this.renderTemplateRef) {
      this._viewContainer.createEmbeddedView(this._templateRef, this.getContext());
      return;
    }

    if (this.elseTemplateRef) {
      this._viewContainer.createEmbeddedView(this.elseTemplateRef, this.getContext());
      return;
    }

    this._viewContainer.clear();
  }

  getContext(): { $implicit: string | string[] } {
    return {
      $implicit: this.ifRole || this.ifNotRole,
    };
  }
}
