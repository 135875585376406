/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { RemiConfiguration as __Configuration } from '../remi-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfReturnItemDTO } from '../models/response-args-of-return-item-dto';
import { ReturnItemDTO } from '../models/return-item-dto';
import { ResponseArgsOfReceiptDTO } from '../models/response-args-of-receipt-dto';
import { PackageDTO } from '../models/package-dto';
import { ReceiptDTO } from '../models/receipt-dto';
import { ResponseArgsOfIEnumerableOfReceiptDTO } from '../models/response-args-of-ienumerable-of-receipt-dto';
import { ReceiptFinalizeValues } from '../models/receipt-finalize-values';
import { ResponseArgsOfReturnDTO } from '../models/response-args-of-return-dto';
import { ResponseArgsOfIEnumerableOfReturnItemDTO } from '../models/response-args-of-ienumerable-of-return-item-dto';
import { ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTO } from '../models/response-args-of-value-tuple-of-receipt-item-dtoand-return-item-dto';
import { ReturnItemValues } from '../models/return-item-values';
import { ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnSuggestionDTO } from '../models/response-args-of-value-tuple-of-receipt-item-dtoand-return-suggestion-dto';
import { ReturnSuggestionValues } from '../models/return-suggestion-values';
import { ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO } from '../models/response-args-of-value-tuple-of-receipt-item-dtoand-return-item-dtoand-return-suggestion-dtoand-return-suggestion-dto';
import { ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-key-value-dtoof-string-and-string';
import { ReturnDTO } from '../models/return-dto';
import { ResponseArgsOfIEnumerableOfReturnDTO } from '../models/response-args-of-ienumerable-of-return-dto';
import { ImpedimentValues } from '../models/impediment-values';
import { ResponseArgsOfReturnSuggestionDTO } from '../models/response-args-of-return-suggestion-dto';
import { ReturnSuggestionDTO } from '../models/return-suggestion-dto';
import { ListResponseArgsOfReturnItemDTO } from '../models/list-response-args-of-return-item-dto';
import { QueryTokenDTO } from '../models/query-token-dto';
import { ResponseArgsOfInteger } from '../models/response-args-of-integer';
import { ListResponseArgsOfReturnDTO } from '../models/list-response-args-of-return-dto';
import { ReturnQueryTokenDTO } from '../models/return-query-token-dto';
import { ResponseArgsOfReceiptItemDTO } from '../models/response-args-of-receipt-item-dto';
import { ReceiptItemDTO } from '../models/receipt-item-dto';
@Injectable({
  providedIn: 'root',
})
class ReturnService extends __BaseService {
  static readonly ReturnCreateReturnItemPath = '/inventory/return/item';
  static readonly ReturnCreateAndAssignPackagePath = '/inventory/return/{returnId}/receipt/{receiptId}/package';
  static readonly ReturnCreateReceiptPath = '/inventory/return/{returnId}/receipt';
  static readonly ReturnGetReturnReceiptsPath = '/inventory/return/{returnId}/receipt';
  static readonly ReturnFinalizeReceiptPath = '/inventory/return/{returnId}/receipt/{receiptId}/finalize';
  static readonly ReturnFinalizeReturnPath = '/inventory/return/{returnId}/finalize';
  static readonly ReturnTransferReturnToBookhitPath = '/remi/return/{returnId}/transfer-to-bookhit';
  static readonly ReturnAddReturnItemPath = '/inventory/return/{returnId}/receipt/{receiptId}/addreturnitem';
  static readonly ReturnAddReturnSuggestionPath = '/inventory/return/{returnId}/receipt/{receiptId}/addreturnsuggestion';
  static readonly ReturnRemoveReturnItemPath = '/inventory/return/{returnId}/receipt/{receiptId}/items/{receiptItemId}';
  static readonly ReturnCancelReturnReceiptPath = '/inventory/return/{returnId}/receipt/{receiptId}/cancel';
  static readonly ReturnGetReturnReasonsPath = '/inventory/stock/{stockId}/return/reasons';
  static readonly ReturnCreateReturnPath = '/inventory/return';
  static readonly ReturnGetReturnPath = '/inventory/return/{returnId}';
  static readonly ReturnCancelReturnPath = '/inventory/return/{returnId}/cancel';
  static readonly ReturnFinalizeReturnGroupPath = '/inventory/returngroup/{returnGroup}/finalize';
  static readonly ReturnGetReturnItemByIdPath = '/inventory/return/item/{itemId}';
  static readonly ReturnDeleteReturnItemPath = '/inventory/return/item/{itemId}';
  static readonly ReturnReturnItemImpedimentPath = '/inventory/return/item/{itemId}/impediment';
  static readonly ReturnCreateReturnSuggestionPath = '/inventory/return/suggestion';
  static readonly ReturnGetReturnSuggestionByIdPath = '/inventory/return/suggestion/{itemId}';
  static readonly ReturnReturnSuggestionImpedimentPath = '/inventory/return/suggestion/{itemId}/impediment';
  static readonly ReturnGetOpenReturnItemsPath = '/inventory/stock/{stockId}/return/item';
  static readonly ReturnGetReturnSuggestionsPath = '/inventory/stock/{stockId}/return/suggestion';
  static readonly ReturnDeleteReturnSuggestionsPath = '/inventory/stock/{stockId}/return/suggestion';
  static readonly ReturnDeleteReturnSuggestionPath = '/inventory/return/suggestion/{suggestionId}';
  static readonly ReturnGetUncompletedReturnsPath = '/inventory/stock/{stockId}/return/uncompleted';
  static readonly ReturnQueryReturnsPath = '/inventory/stock/{stockId}/return';
  static readonly ReturnGetReturnReceiptPath = '/inventory/return/{returnId}/receipt/{receiptId}';
  static readonly ReturnCreateReceiptItemPath = '/inventory/return/{returnId}/receipt/{receiptId}/item';
  static readonly ReturnUpdateReceiptItemPath = '/inventory/return/{returnId}/receipt/{receiptId}/item/{receiptItemId}';
  static readonly ReturnPatchReceiptItemPath = '/inventory/return/{returnId}/receipt/{receiptId}/item/{receiptItemId}';
  static readonly ReturnDeleteReceiptItemPath = '/inventory/return/{returnId}/receipt/{receiptId}/item/{receiptItemId}';
  static readonly ReturnGetUncompletedReceiptsPath = '/inventory/stock/{stockId}/receipt/{receiptType}/uncompleted';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * @param params The `ReturnService.ReturnCreateReturnItemParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReturnItemResponse(
    params: ReturnService.ReturnCreateReturnItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/return/item`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCreateReturnItemParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReturnItem(params: ReturnService.ReturnCreateReturnItemParams): __Observable<ResponseArgsOfReturnItemDTO> {
    return this.ReturnCreateReturnItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnCreateAndAssignPackageParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateAndAssignPackageResponse(
    params: ReturnService.ReturnCreateAndAssignPackageParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/package`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCreateAndAssignPackageParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateAndAssignPackage(params: ReturnService.ReturnCreateAndAssignPackageParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReturnCreateAndAssignPackageResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptDTO));
  }

  /**
   * @param params The `ReturnService.ReturnCreateReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReceiptResponse(
    params: ReturnService.ReturnCreateReceiptParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCreateReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReceipt(params: ReturnService.ReturnCreateReceiptParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReturnCreateReceiptResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnReceiptsParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnReceiptsResponse(
    params: ReturnService.ReturnGetReturnReceiptsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnReceiptsParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnReceipts(params: ReturnService.ReturnGetReturnReceiptsParams): __Observable<ResponseArgsOfIEnumerableOfReceiptDTO> {
    return this.ReturnGetReturnReceiptsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfReceiptDTO));
  }

  /**
   * @param params The `ReturnService.ReturnFinalizeReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnFinalizeReceiptResponse(
    params: ReturnService.ReturnFinalizeReceiptParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/finalize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnFinalizeReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnFinalizeReceipt(params: ReturnService.ReturnFinalizeReceiptParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReturnFinalizeReceiptResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptDTO));
  }

  /**
   * @param params The `ReturnService.ReturnFinalizeReturnParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   */
  ReturnFinalizeReturnResponse(
    params: ReturnService.ReturnFinalizeReturnParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/inventory/return/${encodeURIComponent(String(params.returnId))}/finalize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnFinalizeReturnParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   */
  ReturnFinalizeReturn(params: ReturnService.ReturnFinalizeReturnParams): __Observable<ResponseArgsOfReturnDTO> {
    return this.ReturnFinalizeReturnResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnDTO));
  }

  /**
   * Übertragung der Remissionsdaten an bookhit
   * @param params The `ReturnService.ReturnTransferReturnToBookhitParams` containing the following parameters:
   *
   * - `returnId`: Remission PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  ReturnTransferReturnToBookhitResponse(
    params: ReturnService.ReturnTransferReturnToBookhitParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/remi/return/${encodeURIComponent(String(params.returnId))}/transfer-to-bookhit`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnItemDTO>;
      }),
    );
  }
  /**
   * Übertragung der Remissionsdaten an bookhit
   * @param params The `ReturnService.ReturnTransferReturnToBookhitParams` containing the following parameters:
   *
   * - `returnId`: Remission PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  ReturnTransferReturnToBookhit(
    params: ReturnService.ReturnTransferReturnToBookhitParams,
  ): __Observable<ResponseArgsOfIEnumerableOfReturnItemDTO> {
    return this.ReturnTransferReturnToBookhitResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnAddReturnItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnAddReturnItemResponse(
    params: ReturnService.ReturnAddReturnItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/addreturnitem`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnAddReturnItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnAddReturnItem(
    params: ReturnService.ReturnAddReturnItemParams,
  ): __Observable<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTO> {
    return this.ReturnAddReturnItemResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTO),
    );
  }

  /**
   * @param params The `ReturnService.ReturnAddReturnSuggestionParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnAddReturnSuggestionResponse(
    params: ReturnService.ReturnAddReturnSuggestionParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/addreturnsuggestion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnAddReturnSuggestionParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnAddReturnSuggestion(
    params: ReturnService.ReturnAddReturnSuggestionParams,
  ): __Observable<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnSuggestionDTO> {
    return this.ReturnAddReturnSuggestionResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnSuggestionDTO),
    );
  }

  /**
   * @param params The `ReturnService.ReturnRemoveReturnItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   */
  ReturnRemoveReturnItemResponse(
    params: ReturnService.ReturnRemoveReturnItemParams,
  ): __Observable<
    __StrictHttpResponse<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO>
  > {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/items/${encodeURIComponent(String(params.receiptItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnRemoveReturnItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   */
  ReturnRemoveReturnItem(
    params: ReturnService.ReturnRemoveReturnItemParams,
  ): __Observable<ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO> {
    return this.ReturnRemoveReturnItemResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfValueTupleOfReceiptItemDTOAndReturnItemDTOAndReturnSuggestionDTOAndReturnSuggestionDTO),
    );
  }

  /**
   * @param params The `ReturnService.ReturnCancelReturnReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   */
  ReturnCancelReturnReceiptResponse(
    params: ReturnService.ReturnCancelReturnReceiptParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCancelReturnReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   */
  ReturnCancelReturnReceipt(params: ReturnService.ReturnCancelReturnReceiptParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReturnCancelReturnReceiptResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnReasonsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `locale`:
   */
  ReturnGetReturnReasonsResponse(
    params: ReturnService.ReturnGetReturnReasonsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/return/reasons`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnReasonsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `locale`:
   */
  ReturnGetReturnReasons(
    params: ReturnService.ReturnGetReturnReasonsParams,
  ): __Observable<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString> {
    return this.ReturnGetReturnReasonsResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString),
    );
  }

  /**
   * @param params The `ReturnService.ReturnCreateReturnParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReturnResponse(params: ReturnService.ReturnCreateReturnParams): __Observable<__StrictHttpResponse<ResponseArgsOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/return`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCreateReturnParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReturn(params: ReturnService.ReturnCreateReturnParams): __Observable<ResponseArgsOfReturnDTO> {
    return this.ReturnCreateReturnResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnResponse(params: ReturnService.ReturnGetReturnParams): __Observable<__StrictHttpResponse<ResponseArgsOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/return/${encodeURIComponent(String(params.returnId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturn(params: ReturnService.ReturnGetReturnParams): __Observable<ResponseArgsOfReturnDTO> {
    return this.ReturnGetReturnResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnDTO));
  }

  /**
   * @param params The `ReturnService.ReturnCancelReturnParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   */
  ReturnCancelReturnResponse(params: ReturnService.ReturnCancelReturnParams): __Observable<__StrictHttpResponse<ResponseArgsOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/inventory/return/${encodeURIComponent(String(params.returnId))}/cancel`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCancelReturnParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `locale`:
   */
  ReturnCancelReturn(params: ReturnService.ReturnCancelReturnParams): __Observable<ResponseArgsOfReturnDTO> {
    return this.ReturnCancelReturnResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnDTO));
  }

  /**
   * @param params The `ReturnService.ReturnFinalizeReturnGroupParams` containing the following parameters:
   *
   * - `returnGroup`:
   *
   * - `locale`:
   */
  ReturnFinalizeReturnGroupResponse(
    params: ReturnService.ReturnFinalizeReturnGroupParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/inventory/returngroup/${encodeURIComponent(String(params.returnGroup))}/finalize`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnFinalizeReturnGroupParams` containing the following parameters:
   *
   * - `returnGroup`:
   *
   * - `locale`:
   */
  ReturnFinalizeReturnGroup(params: ReturnService.ReturnFinalizeReturnGroupParams): __Observable<ResponseArgsOfIEnumerableOfReturnDTO> {
    return this.ReturnFinalizeReturnGroupResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfReturnDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnItemByIdParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnItemByIdResponse(
    params: ReturnService.ReturnGetReturnItemByIdParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/return/item/${encodeURIComponent(String(params.itemId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnItemByIdParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnItemById(params: ReturnService.ReturnGetReturnItemByIdParams): __Observable<ResponseArgsOfReturnItemDTO> {
    return this.ReturnGetReturnItemByIdResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnDeleteReturnItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `locale`:
   */
  ReturnDeleteReturnItemResponse(
    params: ReturnService.ReturnDeleteReturnItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('DELETE', this.rootUrl + `/inventory/return/item/${encodeURIComponent(String(params.itemId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnDeleteReturnItemParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `locale`:
   */
  ReturnDeleteReturnItem(params: ReturnService.ReturnDeleteReturnItemParams): __Observable<ResponseArgsOfReturnItemDTO> {
    return this.ReturnDeleteReturnItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnReturnItemImpedimentParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnReturnItemImpedimentResponse(
    params: ReturnService.ReturnReturnItemImpedimentParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/inventory/return/item/${encodeURIComponent(String(params.itemId))}/impediment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnReturnItemImpedimentParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnReturnItemImpediment(params: ReturnService.ReturnReturnItemImpedimentParams): __Observable<ResponseArgsOfReturnItemDTO> {
    return this.ReturnReturnItemImpedimentResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnCreateReturnSuggestionParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReturnSuggestionResponse(
    params: ReturnService.ReturnCreateReturnSuggestionParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/return/suggestion`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCreateReturnSuggestionParams` containing the following parameters:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReturnSuggestion(params: ReturnService.ReturnCreateReturnSuggestionParams): __Observable<ResponseArgsOfReturnSuggestionDTO> {
    return this.ReturnCreateReturnSuggestionResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnSuggestionDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnSuggestionByIdParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnSuggestionByIdResponse(
    params: ReturnService.ReturnGetReturnSuggestionByIdParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/return/suggestion/${encodeURIComponent(String(params.itemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnSuggestionByIdParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnSuggestionById(
    params: ReturnService.ReturnGetReturnSuggestionByIdParams,
  ): __Observable<ResponseArgsOfReturnSuggestionDTO> {
    return this.ReturnGetReturnSuggestionByIdResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnSuggestionDTO));
  }

  /**
   * @param params The `ReturnService.ReturnReturnSuggestionImpedimentParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnReturnSuggestionImpedimentResponse(
    params: ReturnService.ReturnReturnSuggestionImpedimentParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/inventory/return/suggestion/${encodeURIComponent(String(params.itemId))}/impediment`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnReturnSuggestionImpedimentParams` containing the following parameters:
   *
   * - `itemId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnReturnSuggestionImpediment(
    params: ReturnService.ReturnReturnSuggestionImpedimentParams,
  ): __Observable<ResponseArgsOfReturnSuggestionDTO> {
    return this.ReturnReturnSuggestionImpedimentResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnSuggestionDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetOpenReturnItemsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `queryToken`:
   *
   * - `locale`:
   */
  ReturnGetOpenReturnItemsResponse(
    params: ReturnService.ReturnGetOpenReturnItemsParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/return/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetOpenReturnItemsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `queryToken`:
   *
   * - `locale`:
   */
  ReturnGetOpenReturnItems(params: ReturnService.ReturnGetOpenReturnItemsParams): __Observable<ListResponseArgsOfReturnItemDTO> {
    return this.ReturnGetOpenReturnItemsResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnSuggestionsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `queryToken`:
   *
   * - `locale`:
   */
  ReturnGetReturnSuggestionsResponse(
    params: ReturnService.ReturnGetReturnSuggestionsParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/return/suggestion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnSuggestionsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `queryToken`:
   *
   * - `locale`:
   */
  ReturnGetReturnSuggestions(params: ReturnService.ReturnGetReturnSuggestionsParams): __Observable<ListResponseArgsOfReturnItemDTO> {
    return this.ReturnGetReturnSuggestionsResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfReturnItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnDeleteReturnSuggestionsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `locale`:
   *
   * - `department`:
   */
  ReturnDeleteReturnSuggestionsResponse(
    params: ReturnService.ReturnDeleteReturnSuggestionsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.department != null) __params = __params.set('department', params.department.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/return/suggestion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInteger>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnDeleteReturnSuggestionsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `locale`:
   *
   * - `department`:
   */
  ReturnDeleteReturnSuggestions(params: ReturnService.ReturnDeleteReturnSuggestionsParams): __Observable<ResponseArgsOfInteger> {
    return this.ReturnDeleteReturnSuggestionsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInteger));
  }

  /**
   * @param params The `ReturnService.ReturnDeleteReturnSuggestionParams` containing the following parameters:
   *
   * - `suggestionId`:
   *
   * - `locale`:
   */
  ReturnDeleteReturnSuggestionResponse(
    params: ReturnService.ReturnDeleteReturnSuggestionParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/inventory/return/suggestion/${encodeURIComponent(String(params.suggestionId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnDeleteReturnSuggestionParams` containing the following parameters:
   *
   * - `suggestionId`:
   *
   * - `locale`:
   */
  ReturnDeleteReturnSuggestion(params: ReturnService.ReturnDeleteReturnSuggestionParams): __Observable<ResponseArgsOfReturnSuggestionDTO> {
    return this.ReturnDeleteReturnSuggestionResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReturnSuggestionDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetUncompletedReturnsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `locale`:
   */
  ReturnGetUncompletedReturnsResponse(
    params: ReturnService.ReturnGetUncompletedReturnsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/return/uncompleted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetUncompletedReturnsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `locale`:
   */
  ReturnGetUncompletedReturns(params: ReturnService.ReturnGetUncompletedReturnsParams): __Observable<ResponseArgsOfIEnumerableOfReturnDTO> {
    return this.ReturnGetUncompletedReturnsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfReturnDTO));
  }

  /**
   * @param params The `ReturnService.ReturnQueryReturnsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `queryToken`:
   *
   * - `locale`:
   */
  ReturnQueryReturnsResponse(
    params: ReturnService.ReturnQueryReturnsParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfReturnDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/return`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReturnDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnQueryReturnsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `queryToken`:
   *
   * - `locale`:
   */
  ReturnQueryReturns(params: ReturnService.ReturnQueryReturnsParams): __Observable<ListResponseArgsOfReturnDTO> {
    return this.ReturnQueryReturnsResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfReturnDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetReturnReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnReceiptResponse(
    params: ReturnService.ReturnGetReturnReceiptParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.eagerLoading != null) __params = __params.set('eagerLoading', params.eagerLoading.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetReturnReceiptParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   *
   * - `eagerLoading`:
   */
  ReturnGetReturnReceipt(params: ReturnService.ReturnGetReturnReceiptParams): __Observable<ResponseArgsOfReceiptDTO> {
    return this.ReturnGetReturnReceiptResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptDTO));
  }

  /**
   * @param params The `ReturnService.ReturnCreateReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReceiptItemResponse(
    params: ReturnService.ReturnCreateReceiptItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnCreateReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnCreateReceiptItem(params: ReturnService.ReturnCreateReceiptItemParams): __Observable<ResponseArgsOfReceiptItemDTO> {
    return this.ReturnCreateReceiptItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnUpdateReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnUpdateReceiptItemResponse(
    params: ReturnService.ReturnUpdateReceiptItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/item/${encodeURIComponent(String(params.receiptItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnUpdateReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnUpdateReceiptItem(params: ReturnService.ReturnUpdateReceiptItemParams): __Observable<ResponseArgsOfReceiptItemDTO> {
    return this.ReturnUpdateReceiptItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnPatchReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnPatchReceiptItemResponse(
    params: ReturnService.ReturnPatchReceiptItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/item/${encodeURIComponent(String(params.receiptItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnPatchReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `data`:
   *
   * - `locale`:
   */
  ReturnPatchReceiptItem(params: ReturnService.ReturnPatchReceiptItemParams): __Observable<ResponseArgsOfReceiptItemDTO> {
    return this.ReturnPatchReceiptItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnDeleteReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   */
  ReturnDeleteReceiptItemResponse(
    params: ReturnService.ReturnDeleteReceiptItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfReceiptItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/inventory/return/${encodeURIComponent(String(params.returnId))}/receipt/${encodeURIComponent(String(params.receiptId))}/item/${encodeURIComponent(String(params.receiptItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfReceiptItemDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnDeleteReceiptItemParams` containing the following parameters:
   *
   * - `returnId`:
   *
   * - `receiptItemId`:
   *
   * - `receiptId`:
   *
   * - `locale`:
   */
  ReturnDeleteReceiptItem(params: ReturnService.ReturnDeleteReceiptItemParams): __Observable<ResponseArgsOfReceiptItemDTO> {
    return this.ReturnDeleteReceiptItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfReceiptItemDTO));
  }

  /**
   * @param params The `ReturnService.ReturnGetUncompletedReceiptsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `receiptType`:
   *
   * - `locale`:
   */
  ReturnGetUncompletedReceiptsResponse(
    params: ReturnService.ReturnGetUncompletedReceiptsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/inventory/stock/${encodeURIComponent(String(params.stockId))}/receipt/${encodeURIComponent(String(params.receiptType))}/uncompleted`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReceiptDTO>;
      }),
    );
  }
  /**
   * @param params The `ReturnService.ReturnGetUncompletedReceiptsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `receiptType`:
   *
   * - `locale`:
   */
  ReturnGetUncompletedReceipts(
    params: ReturnService.ReturnGetUncompletedReceiptsParams,
  ): __Observable<ResponseArgsOfIEnumerableOfReceiptDTO> {
    return this.ReturnGetUncompletedReceiptsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfReceiptDTO));
  }
}

module ReturnService {
  /**
   * Parameters for ReturnCreateReturnItem
   */
  export interface ReturnCreateReturnItemParams {
    data: ReturnItemDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnCreateAndAssignPackage
   */
  export interface ReturnCreateAndAssignPackageParams {
    returnId: number;
    receiptId: number;
    data: PackageDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnCreateReceipt
   */
  export interface ReturnCreateReceiptParams {
    returnId: number;
    data: ReceiptDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturnReceipts
   */
  export interface ReturnGetReturnReceiptsParams {
    returnId: number;
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for ReturnFinalizeReceipt
   */
  export interface ReturnFinalizeReceiptParams {
    returnId: number;
    receiptId: number;
    data: ReceiptFinalizeValues;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnFinalizeReturn
   */
  export interface ReturnFinalizeReturnParams {
    returnId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnTransferReturnToBookhit
   */
  export interface ReturnTransferReturnToBookhitParams {
    /**
     * Remission PK
     */
    returnId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for ReturnAddReturnItem
   */
  export interface ReturnAddReturnItemParams {
    returnId: number;
    receiptId: number;
    data: ReturnItemValues;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnAddReturnSuggestion
   */
  export interface ReturnAddReturnSuggestionParams {
    returnId: number;
    receiptId: number;
    data: ReturnSuggestionValues;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnRemoveReturnItem
   */
  export interface ReturnRemoveReturnItemParams {
    returnId: number;
    receiptItemId: number;
    receiptId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnCancelReturnReceipt
   */
  export interface ReturnCancelReturnReceiptParams {
    returnId: number;
    receiptId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturnReasons
   */
  export interface ReturnGetReturnReasonsParams {
    stockId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnCreateReturn
   */
  export interface ReturnCreateReturnParams {
    data: ReturnDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturn
   */
  export interface ReturnGetReturnParams {
    returnId: number;
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for ReturnCancelReturn
   */
  export interface ReturnCancelReturnParams {
    returnId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnFinalizeReturnGroup
   */
  export interface ReturnFinalizeReturnGroupParams {
    returnGroup: string;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturnItemById
   */
  export interface ReturnGetReturnItemByIdParams {
    itemId: number;
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for ReturnDeleteReturnItem
   */
  export interface ReturnDeleteReturnItemParams {
    itemId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnReturnItemImpediment
   */
  export interface ReturnReturnItemImpedimentParams {
    itemId: number;
    data: ImpedimentValues;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnCreateReturnSuggestion
   */
  export interface ReturnCreateReturnSuggestionParams {
    data: ReturnSuggestionDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturnSuggestionById
   */
  export interface ReturnGetReturnSuggestionByIdParams {
    itemId: number;
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for ReturnReturnSuggestionImpediment
   */
  export interface ReturnReturnSuggestionImpedimentParams {
    itemId: number;
    data: ImpedimentValues;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetOpenReturnItems
   */
  export interface ReturnGetOpenReturnItemsParams {
    stockId: number;
    queryToken: QueryTokenDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturnSuggestions
   */
  export interface ReturnGetReturnSuggestionsParams {
    stockId: number;
    queryToken: QueryTokenDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnDeleteReturnSuggestions
   */
  export interface ReturnDeleteReturnSuggestionsParams {
    stockId: number;
    locale?: null | string;
    department?: null | string;
  }

  /**
   * Parameters for ReturnDeleteReturnSuggestion
   */
  export interface ReturnDeleteReturnSuggestionParams {
    suggestionId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetUncompletedReturns
   */
  export interface ReturnGetUncompletedReturnsParams {
    stockId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnQueryReturns
   */
  export interface ReturnQueryReturnsParams {
    stockId: number;
    queryToken: ReturnQueryTokenDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetReturnReceipt
   */
  export interface ReturnGetReturnReceiptParams {
    returnId: number;
    receiptId: number;
    locale?: null | string;
    eagerLoading?: number;
  }

  /**
   * Parameters for ReturnCreateReceiptItem
   */
  export interface ReturnCreateReceiptItemParams {
    returnId: number;
    receiptId: number;
    data: ReceiptItemDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnUpdateReceiptItem
   */
  export interface ReturnUpdateReceiptItemParams {
    returnId: number;
    receiptItemId: number;
    receiptId: number;
    data: ReceiptItemDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnPatchReceiptItem
   */
  export interface ReturnPatchReceiptItemParams {
    returnId: number;
    receiptItemId: number;
    receiptId: number;
    data: ReceiptItemDTO;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnDeleteReceiptItem
   */
  export interface ReturnDeleteReceiptItemParams {
    returnId: number;
    receiptItemId: number;
    receiptId: number;
    locale?: null | string;
  }

  /**
   * Parameters for ReturnGetUncompletedReceipts
   */
  export interface ReturnGetUncompletedReceiptsParams {
    stockId: number;
    receiptType: 0 | 1 | 2 | 4;
    locale?: null | string;
  }
}

export { ReturnService };
