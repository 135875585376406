import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { DomainCheckoutService } from './checkout.service';
import { domainCheckoutReducer } from './store/domain-checkout.reducer';
import { storeFeatureName } from './store/domain-checkout.state';
import { EffectsModule } from '@ngrx/effects';
import { DomainCheckoutEffects } from './store/domain-checkout.effects';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature(storeFeatureName, domainCheckoutReducer)],
  providers: [DomainCheckoutService],
})
export class DomainCheckoutModule {
  static forRoot(): ModuleWithProviders<DomainCheckoutModule> {
    return {
      ngModule: RootDomainCheckoutModule,
      providers: [DomainCheckoutService],
    };
  }
}

@NgModule({
  imports: [StoreModule.forFeature(storeFeatureName, domainCheckoutReducer), EffectsModule.forFeature([DomainCheckoutEffects])],
})
export class RootDomainCheckoutModule {}
