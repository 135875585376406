import { ComponentStore } from '@ngrx/component-store';
import { ShoppingCartItemDTO, AvailabilityDTO } from '@swagger/checkout';
import { DomainAvailabilityService } from '@domain/availability';
import { Injectable } from '@angular/core';
import { KulturpassOrderModalStore } from '../kulturpass-order-modal.store';
import { getCatalogProductNumber } from '../catalog-product-number';
import { map, switchMap } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

export interface KulturpassOrderItemState {
  item: ShoppingCartItemDTO;
}

@Injectable()
export class KulturpassOrderItemStore extends ComponentStore<KulturpassOrderItemState> {
  get item() {
    return this.get((s) => s.item);
  }

  get availability() {
    return this._parentStore.getAvailability(getCatalogProductNumber(this.item));
  }

  constructor(
    private _parentStore: KulturpassOrderModalStore,
    private _availabilityService: DomainAvailabilityService,
  ) {
    super({ item: null });
  }

  readonly item$ = this.select((state) => state.item);

  readonly updateItem = this.updater((state, item: ShoppingCartItemDTO) => {
    return { ...state, item };
  });

  readonly itemQuantity$ = this.select((state) => state.item?.quantity ?? 0);

  readonly availability$ = this.item$.pipe(switchMap((item) => this._parentStore.getAvailability$(getCatalogProductNumber(item))));

  readonly availableQuantity$ = this.availability$.pipe(map((availability) => availability?.inStock ?? 0));

  readonly stockWarning$ = combineLatest([this.availability$, this.itemQuantity$]).pipe(
    map(([availability, quantity]) => {
      if (!availability) {
        return false;
      }

      return availability.inStock < quantity;
    }),
  );

  updateQuantity(quantity: number) {
    this._parentStore.quantityChange({ ...this.item, quantity });
  }
}
