import { Component, ChangeDetectionStrategy, OnInit, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AbstractUiFilterInputDirective } from '../abstract-filter-input.directive';

@Component({
  selector: 'shared-filter-input-text',
  templateUrl: 'input-text.component.html',
  styleUrls: ['input-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterInputTextComponent extends AbstractUiFilterInputDirective implements OnInit, OnDestroy {
  private _subscriptions = new Subscription();

  control = new FormControl<string>('');

  constructor() {
    super();
  }

  ngOnInit() {
    this.control.setValue(this.value);
    this.updateValidator();

    const uiInputChangesSub = this.uiInput?.changes?.subscribe((changes) => {
      if (this.control.value !== changes?.target?.value) this.control.setValue(changes?.target?.value);

      this.updateValidator();
    });

    const onInputChangeSub = this.onUiInputChange$.subscribe((input) => {
      if (this.control.value !== input.value) this.control.setValue(input.value);

      this.updateValidator();
    });

    const onControlValueChangeSub = this.control.valueChanges.subscribe((value) => {
      if (this.value !== value) this.setValue(value);
    });

    this._subscriptions.add(uiInputChangesSub);
    this._subscriptions.add(onInputChangeSub);
    this._subscriptions.add(onControlValueChangeSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  updateValidator() {
    if (this.input.constraint) {
      this.control.setValidators(Validators.pattern(this.input.constraint));
    }
  }
}
