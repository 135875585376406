import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UiCommonModule } from '@ui/common';
import { UiIconModule } from '@ui/icon';

import { QuantityDropdownContentComponent } from './quantity-dropdown-content/quantity-dropdown-content.component';
import { QuantityDropdownComponent } from './quantity-dropdown.component';
import { UiSpinnerModule } from '@ui/spinner';

@NgModule({
  declarations: [QuantityDropdownComponent, QuantityDropdownContentComponent],
  imports: [CommonModule, UiCommonModule, UiIconModule, OverlayModule, UiSpinnerModule, FormsModule],
  exports: [QuantityDropdownComponent, QuantityDropdownContentComponent],
})
export class UiQuantityDropdownModule {}
