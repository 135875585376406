/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { CheckoutConfiguration as __Configuration } from '../checkout-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfItemResult } from '../models/response-args-of-item-result';
import { ItemPayload } from '../models/item-payload';
import { ResponseArgsOfItemsResult } from '../models/response-args-of-items-result';
import { ResponseArgsOfBuyerResult } from '../models/response-args-of-buyer-result';
import { BuyerPayload } from '../models/buyer-payload';
import { ResponseArgsOfDestinationResult } from '../models/response-args-of-destination-result';
import { DestinationDTO } from '../models/destination-dto';
import { ResponseArgsOfShoppingCartDTO } from '../models/response-args-of-shopping-cart-dto';
import { AddToShoppingCartDTO } from '../models/add-to-shopping-cart-dto';
import { UpdateShoppingCartItemDTO } from '../models/update-shopping-cart-item-dto';
import { AvailabilityDTO } from '../models/availability-dto';
import { PromotionDTO } from '../models/promotion-dto';
@Injectable({
  providedIn: 'root',
})
class StoreCheckoutShoppingCartService extends __BaseService {
  static readonly StoreCheckoutShoppingCartCanAddItemPath = '/store/shoppingcart/{shoppingCartId}/item/canadd';
  static readonly StoreCheckoutShoppingCartCanAddItemsPath = '/store/shoppingcart/{shoppingCartId}/items/canadd';
  static readonly StoreCheckoutShoppingCartCanAddBuyerPath = '/store/shoppingcart/{shoppingCartId}/buyer/canadd';
  static readonly StoreCheckoutShoppingCartCanAddDestinationPath = '/store/shoppingcart/{shoppingCartId}/destination/canadd';
  static readonly StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerPath =
    '/store/shoppingcart/{shoppingCartId}/item/destination';
  static readonly StoreCheckoutShoppingCartAddItemToShoppingCartPath = '/store/shoppingcart/{shoppingCartId}/item';
  static readonly StoreCheckoutShoppingCartGetShoppingCartPath = '/store/shoppingcart/{shoppingCartId}';
  static readonly StoreCheckoutShoppingCartUpdateShoppingCartItemPath = '/store/shoppingcart/{shoppingCartId}/item/{shoppingCartItemId}';
  static readonly StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityPath =
    '/store/shoppingcart/{shoppingCartId}/item/{shoppingCartItemId}/availability';
  static readonly StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityPath =
    '/store/shoppingcart/{shoppingCartId}/item/{shoppingCartItemId}/availability';
  static readonly StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionPath =
    '/store/shoppingcart/{shoppingCartId}/item/{shoppingCartItemId}/promotion';
  static readonly StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionPath =
    '/store/shoppingcart/{shoppingCartId}/item/{shoppingCartItemId}/promotion';
  static readonly StoreCheckoutShoppingCartCreateShoppingCartPath = '/store/shoppingcart';
  static readonly StoreCheckoutShoppingCartCreateShoppingCartAndAddItemPath = '/store/shoppingcart/item';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Überprüfung, ob Artikel einem Warenkorb hinzugefügt werden kann
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddItemResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfItemResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/canadd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfItemResult>;
      }),
    );
  }
  /**
   * Überprüfung, ob Artikel einem Warenkorb hinzugefügt werden kann
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddItem(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemParams,
  ): __Observable<ResponseArgsOfItemResult> {
    return this.StoreCheckoutShoppingCartCanAddItemResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfItemResult));
  }

  /**
   * Überprüfung, ob die übergebenen Artikel dem Warenkorb hinzugefügt werden können
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemsParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddItemsResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfItemsResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/items/canadd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfItemsResult>;
      }),
    );
  }
  /**
   * Überprüfung, ob die übergebenen Artikel dem Warenkorb hinzugefügt werden können
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemsParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddItems(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddItemsParams,
  ): __Observable<ResponseArgsOfItemsResult> {
    return this.StoreCheckoutShoppingCartCanAddItemsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfItemsResult));
  }

  /**
   * Überprüfung, ob ein Kundentyp einem Warenkorb hinzugefügt werden kann
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddBuyerParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddBuyerResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddBuyerParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfBuyerResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/buyer/canadd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBuyerResult>;
      }),
    );
  }
  /**
   * Überprüfung, ob ein Kundentyp einem Warenkorb hinzugefügt werden kann
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddBuyerParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddBuyer(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddBuyerParams,
  ): __Observable<ResponseArgsOfBuyerResult> {
    return this.StoreCheckoutShoppingCartCanAddBuyerResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfBuyerResult));
  }

  /**
   * Überprüfung, ob das übergebene Lieferziel dem Warenkorb hinzugefügt werden kann
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddDestinationParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddDestinationResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddDestinationParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfDestinationResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/destination/canadd`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfDestinationResult>;
      }),
    );
  }
  /**
   * Überprüfung, ob das übergebene Lieferziel dem Warenkorb hinzugefügt werden kann
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddDestinationParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartCanAddDestination(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCanAddDestinationParams,
  ): __Observable<ResponseArgsOfDestinationResult> {
    return this.StoreCheckoutShoppingCartCanAddDestinationResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfDestinationResult));
  }

  /**
   * Alle Versand-Destinations anhand er übergebnenen Kundendaten aktualisieren
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfBuyerResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/destination`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBuyerResult>;
      }),
    );
  }
  /**
   * Alle Versand-Destinations anhand er übergebnenen Kundendaten aktualisieren
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerParams` containing the following parameters:
   *
   * - `shoppingCartId`: Warenkorb PK
   *
   * - `payload`: Daten
   *
   * - `locale`: Lokalisierung
   */
  StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyer(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerParams,
  ): __Observable<ResponseArgsOfBuyerResult> {
    return this.StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfBuyerResult),
    );
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartAddItemToShoppingCartParams` containing the following parameters:
   *
   * - `shoppingCartId`:
   *
   * - `items`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartAddItemToShoppingCartResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartAddItemToShoppingCartParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.items;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartAddItemToShoppingCartParams` containing the following parameters:
   *
   * - `shoppingCartId`:
   *
   * - `items`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartAddItemToShoppingCart(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartAddItemToShoppingCartParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartAddItemToShoppingCartResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartGetShoppingCartParams` containing the following parameters:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartGetShoppingCartResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartGetShoppingCartParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartGetShoppingCartParams` containing the following parameters:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartGetShoppingCart(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartGetShoppingCartParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartGetShoppingCartResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfShoppingCartDTO));
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemParams` containing the following parameters:
   *
   * - `values`:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartUpdateShoppingCartItemResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.values;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/${encodeURIComponent(String(params.shoppingCartItemId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemParams` containing the following parameters:
   *
   * - `values`:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartUpdateShoppingCartItem(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartUpdateShoppingCartItemResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `availability`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.availability;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/${encodeURIComponent(String(params.shoppingCartItemId))}/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `availability`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartUpdateShoppingCartItemAvailability(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/${encodeURIComponent(String(params.shoppingCartItemId))}/availability`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartDeleteShoppingCartItemAvailability(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `promotion`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.promotion;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl +
        `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/${encodeURIComponent(String(params.shoppingCartItemId))}/promotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `promotion`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartUpdateShoppingCartItemPromotion(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl +
        `/store/shoppingcart/${encodeURIComponent(String(params.shoppingCartId))}/item/${encodeURIComponent(String(params.shoppingCartItemId))}/promotion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionParams` containing the following parameters:
   *
   * - `shoppingCartItemId`:
   *
   * - `shoppingCartId`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartDeleteShoppingCartItemPromotion(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }

  /**
   * @param locale undefined
   */
  StoreCheckoutShoppingCartCreateShoppingCartResponse(
    locale?: null | string,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/store/shoppingcart`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param locale undefined
   */
  StoreCheckoutShoppingCartCreateShoppingCart(locale?: null | string): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartCreateShoppingCartResponse(locale).pipe(__map((_r) => _r.body as ResponseArgsOfShoppingCartDTO));
  }

  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCreateShoppingCartAndAddItemParams` containing the following parameters:
   *
   * - `items`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartCreateShoppingCartAndAddItemResponse(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCreateShoppingCartAndAddItemParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfShoppingCartDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.items;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/store/shoppingcart/item`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfShoppingCartDTO>;
      }),
    );
  }
  /**
   * @param params The `StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCreateShoppingCartAndAddItemParams` containing the following parameters:
   *
   * - `items`:
   *
   * - `locale`:
   */
  StoreCheckoutShoppingCartCreateShoppingCartAndAddItem(
    params: StoreCheckoutShoppingCartService.StoreCheckoutShoppingCartCreateShoppingCartAndAddItemParams,
  ): __Observable<ResponseArgsOfShoppingCartDTO> {
    return this.StoreCheckoutShoppingCartCreateShoppingCartAndAddItemResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfShoppingCartDTO),
    );
  }
}

module StoreCheckoutShoppingCartService {
  /**
   * Parameters for StoreCheckoutShoppingCartCanAddItem
   */
  export interface StoreCheckoutShoppingCartCanAddItemParams {
    /**
     * Warenkorb PK
     */
    shoppingCartId: number;

    /**
     * Daten
     */
    payload: ItemPayload;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartCanAddItems
   */
  export interface StoreCheckoutShoppingCartCanAddItemsParams {
    /**
     * Warenkorb PK
     */
    shoppingCartId: number;

    /**
     * Daten
     */
    payload: Array<ItemPayload>;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartCanAddBuyer
   */
  export interface StoreCheckoutShoppingCartCanAddBuyerParams {
    /**
     * Warenkorb PK
     */
    shoppingCartId: number;

    /**
     * Daten
     */
    payload: BuyerPayload;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartCanAddDestination
   */
  export interface StoreCheckoutShoppingCartCanAddDestinationParams {
    /**
     * Warenkorb PK
     */
    shoppingCartId: number;

    /**
     * Daten
     */
    payload: DestinationDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyer
   */
  export interface StoreCheckoutShoppingCartSetLogisticianOnDestinationsByBuyerParams {
    /**
     * Warenkorb PK
     */
    shoppingCartId: number;

    /**
     * Daten
     */
    payload: BuyerPayload;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartAddItemToShoppingCart
   */
  export interface StoreCheckoutShoppingCartAddItemToShoppingCartParams {
    shoppingCartId: number;
    items: Array<AddToShoppingCartDTO>;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartGetShoppingCart
   */
  export interface StoreCheckoutShoppingCartGetShoppingCartParams {
    shoppingCartId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartUpdateShoppingCartItem
   */
  export interface StoreCheckoutShoppingCartUpdateShoppingCartItemParams {
    values: UpdateShoppingCartItemDTO;
    shoppingCartItemId: number;
    shoppingCartId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartUpdateShoppingCartItemAvailability
   */
  export interface StoreCheckoutShoppingCartUpdateShoppingCartItemAvailabilityParams {
    shoppingCartItemId: number;
    shoppingCartId: number;
    availability: AvailabilityDTO;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartDeleteShoppingCartItemAvailability
   */
  export interface StoreCheckoutShoppingCartDeleteShoppingCartItemAvailabilityParams {
    shoppingCartItemId: number;
    shoppingCartId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartUpdateShoppingCartItemPromotion
   */
  export interface StoreCheckoutShoppingCartUpdateShoppingCartItemPromotionParams {
    shoppingCartItemId: number;
    shoppingCartId: number;
    promotion: PromotionDTO;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartDeleteShoppingCartItemPromotion
   */
  export interface StoreCheckoutShoppingCartDeleteShoppingCartItemPromotionParams {
    shoppingCartItemId: number;
    shoppingCartId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StoreCheckoutShoppingCartCreateShoppingCartAndAddItem
   */
  export interface StoreCheckoutShoppingCartCreateShoppingCartAndAddItemParams {
    items: Array<AddToShoppingCartDTO>;
    locale?: null | string;
  }
}

export { StoreCheckoutShoppingCartService };
