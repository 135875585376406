<div class="desktop-large:hidden">
  <div class="text-center pt-10 px-8 rounded-card grow">
    <h1 class="text-[1.625rem] font-bold">Kundensuche</h1>
    <p class="text-lg mt-2 mb-6">Haben Sie ein Konto bei uns?</p>
    <div class="grid grid-cols-[1fr_auto] items-center gap-2">
      <shared-filter-input-group-main
        class="grow"
        *ngIf="filter$ | async; let filter"
        [inputGroup]="filter?.input | group: 'main'"
        (search)="search(filter)"
        [loading]="fetching$ | async"
        [hint]="message$ | async"
        [scanner]="true"
      ></shared-filter-input-group-main>
      <a
        *ngIf="filterRoute$ | async; let filterRoute"
        class="filter-btn h-14 px-4 rounded font-bold text-xl grid grid-flow-col items-center gap-2"
        [routerLink]="filterRoute.path"
        [queryParams]="filterRoute.queryParams"
        [queryParamsHandling]="'merge'"
        [class.active]="hasFilter$ | async"
      >
        <shared-icon icon="filter-variant"></shared-icon>
        <span> Filter </span>
      </a>
    </div>
    <p class="mt-6">
      Kunde nicht gefunden?
      <a class="text-brand" *ngIf="createRoute$ | async; let route" [routerLink]="route.path" [queryParams]="route.queryParams">
        Neue Kundendaten erfassen
      </a>
    </p>
  </div>
</div>
<div class="hidden desktop-large:block">
  <page-customer-filter-main-view></page-customer-filter-main-view>
</div>
