<div class="flex flex-row justify-end -mt-4 -mr-2">
  <a
    *ngIf="detailsRoute$ | async; let detailsRoute"
    [routerLink]="detailsRoute.path"
    [queryParams]="detailsRoute.urlTree.queryParams"
    [queryParamsHandling]="'merge'"
  >
    <shared-icon icon="close" [size]="32"></shared-icon>
  </a>
</div>
<h1 class="text-2xl text-center font-bold mb-6">Rechnungsadresse bearbeiten</h1>

<form [formGroup]="formGroup" (ngSubmit)="save()">
  <shared-form-control label="Anrede">
    <shared-select formControlName="gender" placeholder="Anrede" tabindex="1" [autofocus]="true">
      <shared-select-option *ngFor="let gender of genderSettings.genders" [value]="gender.value">{{ gender.label }}</shared-select-option>
    </shared-select>
  </shared-form-control>

  <shared-form-control label="Titel">
    <shared-select formControlName="title" placeholder="Titel" tabindex="2">
      <shared-select-option value="Dipl.-Ing.">Dipl.-Ing.</shared-select-option>
      <shared-select-option value="Dr.">Dr.</shared-select-option>
      <shared-select-option value="Dr. med.">Dr. med.</shared-select-option>
      <shared-select-option value="Prof.">Prof.</shared-select-option>
      <shared-select-option value="Prof. Dr.">Prof. Dr.</shared-select-option>
      <shared-select-option value="RA">RA</shared-select-option>
    </shared-select>
  </shared-form-control>

  <shared-form-control label="Nachname">
    <input class="input-control" placeholder="Nachname" type="text" formControlName="lastName" tabindex="3" />
  </shared-form-control>

  <shared-form-control label="Vorname">
    <input class="input-control" placeholder="Vorname" type="text" formControlName="firstName" tabindex="4" />
  </shared-form-control>

  <shared-form-control label="Firma" class="col-span-2">
    <input class="input-control" placeholder="Firma" type="text" formControlName="organisation" tabindex="5" />
  </shared-form-control>

  <shared-form-control label="Straße">
    <input class="input-control" placeholder="Straße" type="text" formControlName="street" tabindex="6" />
  </shared-form-control>

  <shared-form-control label="Hausnummer">
    <input class="input-control" placeholder="Hausnummer" type="text" formControlName="streetNumber" tabindex="7" />
  </shared-form-control>

  <shared-form-control label="PLZ">
    <input class="input-control" placeholder="PLZ" type="text" formControlName="zipCode" tabindex="8" />
  </shared-form-control>

  <shared-form-control label="Ort">
    <input class="input-control" placeholder="Ort" type="text" formControlName="city" tabindex="9" />
  </shared-form-control>

  <shared-form-control label="Adresszusatz" class="col-span-2">
    <input class="input-control" placeholder="Adresszusatz" type="text" formControlName="info" tabindex="10" />
  </shared-form-control>

  <shared-form-control class="col-span-2" label="Land">
    <shared-select placeholder="Land" formControlName="country" tabindex="11">
      <shared-select-option *ngFor="let country of countries$ | async" [value]="country.isO3166_A_3">
        {{ country.name }}
      </shared-select-option>
    </shared-select>
  </shared-form-control>

  <div class="text-center col-span-2">
    <shared-checkbox formControlName="isDefault">Diese Rechnungsadresse als Standard Adresse festlegen</shared-checkbox>
  </div>
  <div class="mt-6 text-center col-span-2">
    <button
      [disabled]="formGroup.invalid || formGroup.disabled"
      type="submit"
      class="px-5 py-3 font-bold text-lg rounded-full bg-brand text-white disabled:bg-gray-400 disabled:cursor-not-allowed"
    >
      Speichern
    </button>
  </div>
</form>
