import { Component, ChangeDetectionStrategy, ContentChild, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { UiSearchboxAutocompleteComponent } from './ui-searchbox-autocomplete.component';
import { UiSearchboxInputDirective } from './ui-searchbox-input.directive';

@Component({
  selector: 'ui-searchbox',
  templateUrl: 'ui-searchbox.component.html',
  styleUrls: ['ui-searchbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSearchboxComponent implements AfterContentInit {
  @ContentChild(UiSearchboxAutocompleteComponent, {
    read: UiSearchboxAutocompleteComponent,
  })
  autocomplete: UiSearchboxAutocompleteComponent;

  @ContentChild(UiSearchboxInputDirective, { read: UiSearchboxInputDirective })
  input: UiSearchboxInputDirective;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterContentInit(): void {
    if (this.autocomplete) {
      this.autocomplete.registerOnSelect((value) => this.selectOption(value));
      this.autocomplete.registerOnShowChanged((value) => this.cdr.markForCheck());
    }
  }

  selectOption(value: string) {
    if (this.input) {
      this.input.setValue(value, { inputType: 'autocomplete' });
    }
  }
}
