import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterInputOptionDateRangeComponent } from './filter-input-option-date-range.component';
import { UiDatepickerModule } from '@ui/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiIconModule } from '@ui/icon';
import { UiCommonModule } from '@ui/common';
import { FormControlComponent } from '@shared/components/form-control';
import { IconComponent } from '@shared/components/icon';
import { InputControlModule } from '@shared/components/input-control';
import { UiDateInputDirective } from '@ui/input';
import { DateInputDirective } from '@shared/forms';

@NgModule({
  imports: [
    CommonModule,
    InputControlModule,
    FormControlComponent,
    UiCommonModule,
    UiDatepickerModule,
    ReactiveFormsModule,
    FormsModule,
    IconComponent,
    UiDateInputDirective,
    DateInputDirective,
  ],
  exports: [FilterInputOptionDateRangeComponent],
  declarations: [FilterInputOptionDateRangeComponent],
})
export class FilterInputOptionDateRangeModule {}
