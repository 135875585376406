import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CustomerInfoDTO } from '@swagger/crm';
import { CustomerLabelColor, CustomerLabelTextColor } from '../../../constants';

@Component({
  selector: 'page-customer-result-list-item',
  templateUrl: 'customer-result-list-item.component.html',
  styleUrls: ['customer-result-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerResultListItemComponent {
  customerLabelColor = CustomerLabelColor;

  customerLabelTextColor = CustomerLabelTextColor;

  get label() {
    return this.customer?.features?.find((f) => f.enabled);
  }

  @Input()
  customer: CustomerInfoDTO;

  constructor() {}
}
