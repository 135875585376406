import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterFilterGroupFilterComponent } from './filter-filter-group-filter.component';
import { FilterInputOptionsModule } from '../../shared/filter-input-options';
import { FilterInputModule } from '../../shared/filter-input';
import { IconComponent } from '@shared/components/icon';

@NgModule({
  imports: [CommonModule, IconComponent, FilterInputOptionsModule, FilterInputModule],
  exports: [FilterFilterGroupFilterComponent],
  declarations: [FilterFilterGroupFilterComponent],
})
export class FilterFilterGroupFilterModule {}
