import { AsyncPipe, CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { OrderDestinationPipe, OrderProcessingStatusPipe } from '@shared/pipes/order';
import { AddressPipe } from '@shared/pipes/customer';

import { OrderListItemDTO } from '@swagger/oms';
import { CustomerSearchStore } from '../../store';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'page-customer-order-list-item',
  templateUrl: 'order-list-item.component.html',
  styleUrls: ['order-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-order-list-item' },
  standalone: true,
  imports: [DatePipe, OrderProcessingStatusPipe, OrderDestinationPipe, CurrencyPipe, NgIf, AddressPipe, AsyncPipe, RouterLink],
})
export class CustomerOrderListItemComponent {
  private _store = inject(CustomerSearchStore);
  private _navigation = inject(CustomerSearchNavigation);

  private _orderListItemSub = new BehaviorSubject<OrderListItemDTO>(undefined);

  @Input()
  get orderListItem() {
    return this._orderListItemSub.getValue();
  }

  set orderListItem(value: OrderListItemDTO) {
    this._orderListItemSub.next(value);
  }
  orderDetailsRoute$ = combineLatest([this._store.processId$, this._store.customerId$, this._orderListItemSub]).pipe(
    map(([processId, customerId, order]) => this._navigation.orderDetialsRoute({ processId, customerId, orderId: order?.id })),
  );
}
