import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FilterFilterGroupMainComponent } from './filter-filter-group-main.component';
import { FilterInputChipModule } from '../../shared/filter-input-chip';

@NgModule({
  imports: [CommonModule, FilterInputChipModule],
  exports: [FilterFilterGroupMainComponent],
  declarations: [FilterFilterGroupMainComponent],
})
export class FilterFilterGroupMainModule {}
