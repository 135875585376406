export const CustomerLabelColor = {
  Abholfachbestellung: '#EDEFF0',
  'Versandbestellung (oder gemischt)': '#EDEFF0',
  'Bestellung ohne Konto': '#EDEFF0',
  Onlinekonto: '#804279',
  'Onlinekonto mit Kundenkarte': '#804279',
  'Business Konto (auf Rechnung)': '#804279',
  Kundenkarte: '#804279',
  Mitarbeiter: '#f70400',
};

export const CustomerLabelTextColor = {
  Abholfachbestellung: '#000000',
  'Bestellung ohne Konto': '#000000',
  'Versandbestellung (oder gemischt)': '#000000',
  Onlinekonto: '#FFFFFF',
  'Onlinekonto mit Kundenkarte': '#FFFFFF',
  'Business Konto (auf Rechnung)': '#FFFFFF',
  Kundenkarte: '#FFFFFF',
  Mitarbeiter: '#FFFFFF',
};
