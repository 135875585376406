<div class="scroll-container scroll-bar pt-5 overflow-y-scroll overflow-x-hidden mr-2">
  <div *ngFor="let logEntry of history; first as isFirst; last as isLast" class="flex flex-col pl-2 pr-7 mb-10">
    <div class="log-entry relative pl-7" [class.last]="isLast" [class.first]="isFirst">
      <span class="timeline-dot" [class.last]="isLast && !isFirst"></span>
      <span class="timeline-dot" [class.bottom-square]="isLast && isFirst"></span>

      <shared-history-log-entry [historyLogEntryData]="logEntry"></shared-history-log-entry>
    </div>
  </div>
</div>
