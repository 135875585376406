import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerResultsSideViewComponent } from './results-side-view.component';
import { CustomerResultListModule } from '../../components/customer-result-list/customer-result-list.module';
import { RouterModule } from '@angular/router';
import { FilterModule } from '@shared/components/filter';
import { IconComponent } from '@shared/components/icon';

@NgModule({
  imports: [CommonModule, CustomerResultListModule, FilterModule, RouterModule, IconComponent],
  exports: [CustomerResultsSideViewComponent],
  declarations: [CustomerResultsSideViewComponent],
})
export class CustomerResultsSideViewModule {}
