<div class="bg-surface text-surface-content rounded-t p-4 font-bold text-xl">
  {{ customer$ | async | customerName }}
</div>
<div class="bg-surface-2 text-surface-2-content p-4 text-right">{{ (orderItems$ | async)?.length }} Artikel</div>
<div class="grid grid-flow-row gap-1" *ngIf="selectedOrderItemId$ | async; let selectedOrderItemId">
  <ng-container *ngFor="let orderItem of orderItems$ | async">
    <div
      [class.order-item-active]="selectedOrderItemId === orderItem?.id"
      class="grid grid-cols-[auto_1fr] gap-4 p-4 border border-solid bg-surface text-surface-content rounded first:rounded-t-none"
      (click)="selectOrderItem(orderItem?.id)"
    >
      <div>
        <img [src]="orderItem?.product?.ean | productImage" [alt]="orderItem?.product?.name" class="rounded shadow mx-auto w-[3.75rem]" />
      </div>
      <div class="grid grid-flow-row gap-1">
        <span>{{ orderItem?.product?.contributors }}</span>
        <span class="font-bold truncate whitespace-nowrap">{{ orderItem?.product?.name }}</span>
        <span>
          <span class="isa-label">{{ orderItem?.subsetItems?.[0].data?.processingStatus | orderItemProcessingStatus }}</span>
        </span>
      </div>
    </div>
  </ng-container>
</div>
