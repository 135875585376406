import { ChangeDetectorRef, Directive, HostBinding, HostListener, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import moment from 'moment';
import { DE_DATE_REGEX, ISO_DATE_REGEX } from '../regex';

@Directive({
  selector: 'input[type="text"][sharedDateInput]',
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateInputDirective),
      multi: true,
    },
  ],
})
export class DateInputDirective implements ControlValueAccessor {
  private _onChange = (_: any) => {};

  private _onTouched = () => {};

  @Input()
  value: any;

  @HostBinding('value')
  displayValue: string = '';

  @HostBinding('disabled')
  disabled: boolean;

  constructor(private _cdr: ChangeDetectorRef) {}

  writeValue(obj: any): void {
    this.value = obj;
    this.setDisplayValue(obj);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setDisplayValue(value: any) {
    let date: Date;

    if (value instanceof Date) {
      date = value;
    } else {
      date = new Date(value);
    }

    // 06.09.2023 Nino Righi --- Code Auskommentiert und abgeändert, da nicht richtig funktioniert -> Bugticket #4255 HSC // Neue Filteroption - Erscheinungsdatum

    // else if (DE_DATE_REGEX.test(value)) {
    //   const mom = moment(value, 'L');
    //   date = mom.toDate();
    // } else if (ISO_DATE_REGEX.test(value)) {
    //   date = new Date(value);
    // }

    if (date && date.toString() !== 'Invalid Date') {
      this.displayValue = moment(date).format('L');
    } else {
      this.displayValue = value ?? '';
    }

    this._cdr.markForCheck();
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    let date: Date;
    if (DE_DATE_REGEX.test(value)) {
      const mom = moment(value, 'L');
      date = mom.toDate();
    }

    this.value = date ?? value;

    this.displayValue = value;

    this._onTouched();
    this._onChange(this.value);
  }
}
