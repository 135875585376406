/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { RemiConfiguration as __Configuration } from '../remi-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfReturnItemDTO } from '../models/list-response-args-of-return-item-dto';
import { RemiQueryTokenDTO } from '../models/remi-query-token-dto';
import { BatchResponseArgsOfReturnItemDTOAndReturnItemDTO } from '../models/batch-response-args-of-return-item-dtoand-return-item-dto';
import { ReturnItemDTO } from '../models/return-item-dto';
import { ResponseArgsOfInteger } from '../models/response-args-of-integer';
import { ListResponseArgsOfReturnSuggestionDTO } from '../models/list-response-args-of-return-suggestion-dto';
import { BatchResponseArgsOfReturnSuggestionDTOAndReturnSuggestionDTO } from '../models/batch-response-args-of-return-suggestion-dtoand-return-suggestion-dto';
import { ReturnSuggestionDTO } from '../models/return-suggestion-dto';
import { ResponseArgsOfQuerySettingsDTO } from '../models/response-args-of-query-settings-dto';
import { ResponseArgsOfIEnumerableOfInputDTO } from '../models/response-args-of-ienumerable-of-input-dto';
import { ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString } from '../models/response-args-of-ienumerable-of-key-value-dtoof-string-and-string';
import { ResponseArgsOfIEnumerableOfValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString } from '../models/response-args-of-ienumerable-of-value-tuple-of-string-and-integer-and-integer-and-nullable-integer-and-string';
import { CapacityRequest } from '../models/capacity-request';
@Injectable({
  providedIn: 'root',
})
class RemiService extends __BaseService {
  static readonly RemiPflichtremissionsartikelPath = '/remi/pflichtremission';
  static readonly RemiCanAddReturnItemPath = '/inventory/pflichtremission/item/canadd';
  static readonly RemiCreateReturnItemPath = '/inventory/pflichtremission/item';
  static readonly RemiDeleteNullgutschriftenPath = '/remi/stock/{stockId}/nullgutschriften';
  static readonly RemiUeberlaufPath = '/remi/ueberlauf';
  static readonly RemiCreateReturnSuggestionsPath = '/inventory/ueberlauf/item';
  static readonly RemiRemissionQuerySettingsPath = '/remi/stock/{stockId}/supplier/{supplierId}/{remiType}/settings';
  static readonly RemiGetPflichtremissionFilterPath = '/remi/stock/{stockId}/filter';
  static readonly RemiGetPflichtremissionFilter2Path = '/remi/stock/{stockId}/pflichtremission/filter';
  static readonly RemiGetUeberlaufFilterPath = '/remi/stock/{stockId}/ueberlauf/filter';
  static readonly RemiProductgroupsPath = '/remi/stock/{stockId}/productgroup';
  static readonly RemiGetRequiredCapacitiesPath = '/remi/stock/{stockId}/ueberlauf/required-capacity';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Listet alle offenen Remittenden
   * @param params The `RemiService.RemiPflichtremissionsartikelParams` containing the following parameters:
   *
   * - `queryToken`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  RemiPflichtremissionsartikelResponse(
    params: RemiService.RemiPflichtremissionsartikelParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/remi/pflichtremission`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReturnItemDTO>;
      }),
    );
  }
  /**
   * Listet alle offenen Remittenden
   * @param params The `RemiService.RemiPflichtremissionsartikelParams` containing the following parameters:
   *
   * - `queryToken`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  RemiPflichtremissionsartikel(params: RemiService.RemiPflichtremissionsartikelParams): __Observable<ListResponseArgsOfReturnItemDTO> {
    return this.RemiPflichtremissionsartikelResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfReturnItemDTO));
  }

  /**
   * Prüfen, ob Remittenden angelegt werden kann
   * @param params The `RemiService.RemiCanAddReturnItemParams` containing the following parameters:
   *
   * - `data`: Daten
   *
   * - `locale`: Lokalisiserung
   */
  RemiCanAddReturnItemResponse(
    params: RemiService.RemiCanAddReturnItemParams,
  ): __Observable<__StrictHttpResponse<BatchResponseArgsOfReturnItemDTOAndReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/pflichtremission/item/canadd`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BatchResponseArgsOfReturnItemDTOAndReturnItemDTO>;
      }),
    );
  }
  /**
   * Prüfen, ob Remittenden angelegt werden kann
   * @param params The `RemiService.RemiCanAddReturnItemParams` containing the following parameters:
   *
   * - `data`: Daten
   *
   * - `locale`: Lokalisiserung
   */
  RemiCanAddReturnItem(params: RemiService.RemiCanAddReturnItemParams): __Observable<BatchResponseArgsOfReturnItemDTOAndReturnItemDTO> {
    return this.RemiCanAddReturnItemResponse(params).pipe(__map((_r) => _r.body as BatchResponseArgsOfReturnItemDTOAndReturnItemDTO));
  }

  /**
   * Remittenden anlegen/aktualisieren
   * @param params The `RemiService.RemiCreateReturnItemParams` containing the following parameters:
   *
   * - `data`: Daten
   *
   * - `locale`: Lokalisiserung
   */
  RemiCreateReturnItemResponse(
    params: RemiService.RemiCreateReturnItemParams,
  ): __Observable<__StrictHttpResponse<BatchResponseArgsOfReturnItemDTOAndReturnItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/pflichtremission/item`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BatchResponseArgsOfReturnItemDTOAndReturnItemDTO>;
      }),
    );
  }
  /**
   * Remittenden anlegen/aktualisieren
   * @param params The `RemiService.RemiCreateReturnItemParams` containing the following parameters:
   *
   * - `data`: Daten
   *
   * - `locale`: Lokalisiserung
   */
  RemiCreateReturnItem(params: RemiService.RemiCreateReturnItemParams): __Observable<BatchResponseArgsOfReturnItemDTOAndReturnItemDTO> {
    return this.RemiCreateReturnItemResponse(params).pipe(__map((_r) => _r.body as BatchResponseArgsOfReturnItemDTOAndReturnItemDTO));
  }

  /**
   * Nullgutschriften löschen
   * @param params The `RemiService.RemiDeleteNullgutschriftenParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `locale`: Lokalisiserung
   *
   * - `department`: Abteilung
   */
  RemiDeleteNullgutschriftenResponse(
    params: RemiService.RemiDeleteNullgutschriftenParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfInteger>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    if (params.department != null) __params = __params.set('department', params.department.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/remi/stock/${encodeURIComponent(String(params.stockId))}/nullgutschriften`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfInteger>;
      }),
    );
  }
  /**
   * Nullgutschriften löschen
   * @param params The `RemiService.RemiDeleteNullgutschriftenParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `locale`: Lokalisiserung
   *
   * - `department`: Abteilung
   */
  RemiDeleteNullgutschriften(params: RemiService.RemiDeleteNullgutschriftenParams): __Observable<ResponseArgsOfInteger> {
    return this.RemiDeleteNullgutschriftenResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfInteger));
  }

  /**
   * Listet die Überlaufsartikel
   * @param params The `RemiService.RemiUeberlaufParams` containing the following parameters:
   *
   * - `queryToken`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  RemiUeberlaufResponse(
    params: RemiService.RemiUeberlaufParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.queryToken;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/remi/ueberlauf`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * Listet die Überlaufsartikel
   * @param params The `RemiService.RemiUeberlaufParams` containing the following parameters:
   *
   * - `queryToken`: Abfragedaten
   *
   * - `locale`: Lokalisierung
   */
  RemiUeberlauf(params: RemiService.RemiUeberlaufParams): __Observable<ListResponseArgsOfReturnSuggestionDTO> {
    return this.RemiUeberlaufResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfReturnSuggestionDTO));
  }

  /**
   * Überlauf-Artikel anlegen/aktualisieren
   * @param params The `RemiService.RemiCreateReturnSuggestionsParams` containing the following parameters:
   *
   * - `data`: Daten
   *
   * - `locale`: Lokalisiserung
   */
  RemiCreateReturnSuggestionsResponse(
    params: RemiService.RemiCreateReturnSuggestionsParams,
  ): __Observable<__StrictHttpResponse<BatchResponseArgsOfReturnSuggestionDTOAndReturnSuggestionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.data;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/ueberlauf/item`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<BatchResponseArgsOfReturnSuggestionDTOAndReturnSuggestionDTO>;
      }),
    );
  }
  /**
   * Überlauf-Artikel anlegen/aktualisieren
   * @param params The `RemiService.RemiCreateReturnSuggestionsParams` containing the following parameters:
   *
   * - `data`: Daten
   *
   * - `locale`: Lokalisiserung
   */
  RemiCreateReturnSuggestions(
    params: RemiService.RemiCreateReturnSuggestionsParams,
  ): __Observable<BatchResponseArgsOfReturnSuggestionDTOAndReturnSuggestionDTO> {
    return this.RemiCreateReturnSuggestionsResponse(params).pipe(
      __map((_r) => _r.body as BatchResponseArgsOfReturnSuggestionDTOAndReturnSuggestionDTO),
    );
  }

  /**
   * Remission-Filter
   * @param params The `RemiService.RemiRemissionQuerySettingsParams` containing the following parameters:
   *
   * - `supplierId`: Remissionsziel/Liferant PK
   *
   * - `stockId`: Lager PK
   *
   * - `remiType`: Remissionsart (pflichtremission, abteilungsremission)
   *
   * - `locale`: Lokalisierung
   */
  RemiRemissionQuerySettingsResponse(
    params: RemiService.RemiRemissionQuerySettingsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl +
        `/remi/stock/${encodeURIComponent(String(params.stockId))}/supplier/${encodeURIComponent(String(params.supplierId))}/${encodeURIComponent(String(params.remiType))}/settings`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfQuerySettingsDTO>;
      }),
    );
  }
  /**
   * Remission-Filter
   * @param params The `RemiService.RemiRemissionQuerySettingsParams` containing the following parameters:
   *
   * - `supplierId`: Remissionsziel/Liferant PK
   *
   * - `stockId`: Lager PK
   *
   * - `remiType`: Remissionsart (pflichtremission, abteilungsremission)
   *
   * - `locale`: Lokalisierung
   */
  RemiRemissionQuerySettings(params: RemiService.RemiRemissionQuerySettingsParams): __Observable<ResponseArgsOfQuerySettingsDTO> {
    return this.RemiRemissionQuerySettingsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfQuerySettingsDTO));
  }

  /**
   * Filter
   * @param params The `RemiService.RemiGetPflichtremissionFilterParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `supplierId`: Lieferant PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetPflichtremissionFilterResponse(
    params: RemiService.RemiGetPflichtremissionFilterParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.supplierId != null) __params = __params.set('supplierId', params.supplierId.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/remi/stock/${encodeURIComponent(String(params.stockId))}/filter`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>;
      }),
    );
  }
  /**
   * Filter
   * @param params The `RemiService.RemiGetPflichtremissionFilterParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `supplierId`: Lieferant PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetPflichtremissionFilter(
    params: RemiService.RemiGetPflichtremissionFilterParams,
  ): __Observable<ResponseArgsOfIEnumerableOfInputDTO> {
    return this.RemiGetPflichtremissionFilterResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfInputDTO));
  }

  /**
   * Filter
   * @param params The `RemiService.RemiGetPflichtremissionFilter2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `supplierId`: Lieferant PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetPflichtremissionFilter2Response(
    params: RemiService.RemiGetPflichtremissionFilter2Params,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.supplierId != null) __params = __params.set('supplierId', params.supplierId.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remi/stock/${encodeURIComponent(String(params.stockId))}/pflichtremission/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>;
      }),
    );
  }
  /**
   * Filter
   * @param params The `RemiService.RemiGetPflichtremissionFilter2Params` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `supplierId`: Lieferant PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetPflichtremissionFilter2(
    params: RemiService.RemiGetPflichtremissionFilter2Params,
  ): __Observable<ResponseArgsOfIEnumerableOfInputDTO> {
    return this.RemiGetPflichtremissionFilter2Response(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfInputDTO));
  }

  /**
   * Filter
   * @param params The `RemiService.RemiGetUeberlaufFilterParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `supplierId`: Lieferant PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetUeberlaufFilterResponse(
    params: RemiService.RemiGetUeberlaufFilterParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.supplierId != null) __params = __params.set('supplierId', params.supplierId.toString());
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/remi/stock/${encodeURIComponent(String(params.stockId))}/ueberlauf/filter`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfInputDTO>;
      }),
    );
  }
  /**
   * Filter
   * @param params The `RemiService.RemiGetUeberlaufFilterParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `supplierId`: Lieferant PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetUeberlaufFilter(params: RemiService.RemiGetUeberlaufFilterParams): __Observable<ResponseArgsOfIEnumerableOfInputDTO> {
    return this.RemiGetUeberlaufFilterResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfInputDTO));
  }

  /**
   * Warengruppen
   * @param params The `RemiService.RemiProductgroupsParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiProductgroupsResponse(
    params: RemiService.RemiProductgroupsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/remi/stock/${encodeURIComponent(String(params.stockId))}/productgroup`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString>;
      }),
    );
  }
  /**
   * Warengruppen
   * @param params The `RemiService.RemiProductgroupsParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiProductgroups(params: RemiService.RemiProductgroupsParams): __Observable<ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString> {
    return this.RemiProductgroupsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfKeyValueDTOOfStringAndString));
  }

  /**
   * Überlauf-Kapazitäten
   * @param params The `RemiService.RemiGetRequiredCapacitiesParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `payload`: Abfragedaten
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetRequiredCapacitiesResponse(
    params: RemiService.RemiGetRequiredCapacitiesParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.payload;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/remi/stock/${encodeURIComponent(String(params.stockId))}/ueberlauf/required-capacity`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString>;
      }),
    );
  }
  /**
   * Überlauf-Kapazitäten
   * @param params The `RemiService.RemiGetRequiredCapacitiesParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `payload`: Abfragedaten
   *
   * - `locale`: Lokalisierung (optional)
   */
  RemiGetRequiredCapacities(
    params: RemiService.RemiGetRequiredCapacitiesParams,
  ): __Observable<ResponseArgsOfIEnumerableOfValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString> {
    return this.RemiGetRequiredCapacitiesResponse(params).pipe(
      __map((_r) => _r.body as ResponseArgsOfIEnumerableOfValueTupleOfStringAndIntegerAndIntegerAndNullableIntegerAndString),
    );
  }
}

module RemiService {
  /**
   * Parameters for RemiPflichtremissionsartikel
   */
  export interface RemiPflichtremissionsartikelParams {
    /**
     * Abfragedaten
     */
    queryToken: RemiQueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiCanAddReturnItem
   */
  export interface RemiCanAddReturnItemParams {
    /**
     * Daten
     */
    data: Array<ReturnItemDTO>;

    /**
     * Lokalisiserung
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiCreateReturnItem
   */
  export interface RemiCreateReturnItemParams {
    /**
     * Daten
     */
    data: Array<ReturnItemDTO>;

    /**
     * Lokalisiserung
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiDeleteNullgutschriften
   */
  export interface RemiDeleteNullgutschriftenParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Lokalisiserung
     */
    locale?: null | string;

    /**
     * Abteilung
     */
    department?: null | string;
  }

  /**
   * Parameters for RemiUeberlauf
   */
  export interface RemiUeberlaufParams {
    /**
     * Abfragedaten
     */
    queryToken: RemiQueryTokenDTO;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiCreateReturnSuggestions
   */
  export interface RemiCreateReturnSuggestionsParams {
    /**
     * Daten
     */
    data: Array<ReturnSuggestionDTO>;

    /**
     * Lokalisiserung
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiRemissionQuerySettings
   */
  export interface RemiRemissionQuerySettingsParams {
    /**
     * Remissionsziel/Liferant PK
     */
    supplierId: number;

    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Remissionsart (pflichtremission, abteilungsremission)
     */
    remiType: string;

    /**
     * Lokalisierung
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiGetPflichtremissionFilter
   */
  export interface RemiGetPflichtremissionFilterParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Lieferant PK
     */
    supplierId?: null | number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiGetPflichtremissionFilter2
   */
  export interface RemiGetPflichtremissionFilter2Params {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Lieferant PK
     */
    supplierId?: null | number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiGetUeberlaufFilter
   */
  export interface RemiGetUeberlaufFilterParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Lieferant PK
     */
    supplierId?: null | number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiProductgroups
   */
  export interface RemiProductgroupsParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for RemiGetRequiredCapacities
   */
  export interface RemiGetRequiredCapacitiesParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Abfragedaten
     */
    payload: CapacityRequest;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }
}

export { RemiService };
