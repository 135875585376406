import { NgModule } from '@angular/core';
import { ShellComponent } from './shell.component';
import { ShellTopBarModule } from './top-bar';
import { ShellSideMenuModule } from './side-menu';
import { CommonModule } from '@angular/common';
import { ProcessBarModule } from './process-bar';
import { ToasterOutletComponent } from './toaster/toaster-outlet.component';

@NgModule({
  declarations: [ShellComponent],
  imports: [CommonModule, ShellTopBarModule, ShellSideMenuModule, ProcessBarModule, ToasterOutletComponent],
  exports: [ShellComponent, ShellTopBarModule, ShellSideMenuModule, ProcessBarModule],
})
export class ShellModule {
  static forRoot() {
    return {
      ngModule: ShellRootModule,
    };
  }
}

@NgModule({
  imports: [ShellModule],
  exports: [ShellModule],
})
export class ShellRootModule {}
