/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { WwsConfiguration as __Configuration } from '../wws-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ResponseArgsOfIEnumerableOfStockInfoDTO } from '../models/response-args-of-ienumerable-of-stock-info-dto';
import { StocksRequestValues } from '../models/stocks-request-values';
import { StockRequestValues } from '../models/stock-request-values';
import { ResponseArgsOfIEnumerableOfReturnInfoDTO } from '../models/response-args-of-ienumerable-of-return-info-dto';
import { ResponseArgsOfDBHBranchExtensionDTO } from '../models/response-args-of-dbhbranch-extension-dto';
import { ResponseArgsOfBranchDTO } from '../models/response-args-of-branch-dto';
import { ResponseArgsOfStockDTO } from '../models/response-args-of-stock-dto';
import { ResponseArgsOfIEnumerableOfStockDTO } from '../models/response-args-of-ienumerable-of-stock-dto';
import { ResponseArgsOfIEnumerableOfStockEntryDTO } from '../models/response-args-of-ienumerable-of-stock-entry-dto';
import { ResponseArgsOfIEnumerableOfStockItemDTO } from '../models/response-args-of-ienumerable-of-stock-item-dto';
import { ResponseArgsOfIEnumerableOfStockOrderDTO } from '../models/response-args-of-ienumerable-of-stock-order-dto';
import { ResponseArgsOfIEnumerableOfStockReservationDTO } from '../models/response-args-of-ienumerable-of-stock-reservation-dto';
@Injectable({
  providedIn: 'root',
})
class StockService extends __BaseService {
  static readonly StockInStockPath = '/inventory/stock/{stockId}/instock';
  static readonly StockStocksRequestPath = '/inventory/stocks/instock';
  static readonly StockStockRequestPath = '/inventory/stock/instock';
  static readonly StockCheckItemsForReturnPath = '/inventory/branch/{branchNumber}/checkitemsforreturn';
  static readonly StockInStockByEANPath = '/inventory/stock/{stockId}/instockbyean';
  static readonly StockGetCurrentBranchExtensionPath = '/inventory/stock/current/branchExtension';
  static readonly StockCurrentBranchPath = '/inventory/stock/current/branch';
  static readonly StockCurrentStockPath = '/inventory/stock/current';
  static readonly StockGetStocksByBranchPath = '/inventory/branch/{branchId}/stock';
  static readonly StockGetStocksPath = '/inventory/stock';
  static readonly StockGetStockEntriesPath = '/inventory/stock/stockitem/{stockItemId}';
  static readonly StockGetStockItemsPath = '/inventory/stock/stockitem';
  static readonly StockGetStockOrdersPath = '/inventory/stock/{stockId}/order';
  static readonly StockGetStockReservationsPath = '/inventory/stock/{stockId}/reservation';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockInStockParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `articleIds`: Artikel PKs
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockInStockResponse(
    params: StockService.StockInStockParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.articleIds;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/instock`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>;
      }),
    );
  }
  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockInStockParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `articleIds`: Artikel PKs
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockInStock(params: StockService.StockInStockParams): __Observable<ResponseArgsOfIEnumerableOfStockInfoDTO> {
    return this.StockInStockResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockInfoDTO));
  }

  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockStocksRequestParams` containing the following parameters:
   *
   * - `stocksRequest`: Abfragedaten
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockStocksRequestResponse(
    params: StockService.StockStocksRequestParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stocksRequest;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/stocks/instock`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>;
      }),
    );
  }
  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockStocksRequestParams` containing the following parameters:
   *
   * - `stocksRequest`: Abfragedaten
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockStocksRequest(params: StockService.StockStocksRequestParams): __Observable<ResponseArgsOfIEnumerableOfStockInfoDTO> {
    return this.StockStocksRequestResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockInfoDTO));
  }

  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockStockRequestParams` containing the following parameters:
   *
   * - `stockRequest`: Abfragedaten
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockStockRequestResponse(
    params: StockService.StockStockRequestParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.stockRequest;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('POST', this.rootUrl + `/inventory/stock/instock`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>;
      }),
    );
  }
  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockStockRequestParams` containing the following parameters:
   *
   * - `stockRequest`: Abfragedaten
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockStockRequest(params: StockService.StockStockRequestParams): __Observable<ResponseArgsOfIEnumerableOfStockInfoDTO> {
    return this.StockStockRequestResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockInfoDTO));
  }

  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockCheckItemsForReturnParams` containing the following parameters:
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filial-Nr
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockCheckItemsForReturnResponse(
    params: StockService.StockCheckItemsForReturnParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.eans;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/branch/${encodeURIComponent(String(params.branchNumber))}/checkitemsforreturn`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfReturnInfoDTO>;
      }),
    );
  }
  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockCheckItemsForReturnParams` containing the following parameters:
   *
   * - `eans`: EANs
   *
   * - `branchNumber`: Filial-Nr
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockCheckItemsForReturn(params: StockService.StockCheckItemsForReturnParams): __Observable<ResponseArgsOfIEnumerableOfReturnInfoDTO> {
    return this.StockCheckItemsForReturnResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfReturnInfoDTO));
  }

  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockInStockByEANParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `eans`: EANs
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockInStockByEANResponse(
    params: StockService.StockInStockByEANParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.eans;
    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/instockbyean`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockInfoDTO>;
      }),
    );
  }
  /**
   * Lagerbestandsabfrage
   * @param params The `StockService.StockInStockByEANParams` containing the following parameters:
   *
   * - `stockId`: Lager PK
   *
   * - `eans`: EANs
   *
   * - `locale`: Lokalisierung (optional)
   */
  StockInStockByEAN(params: StockService.StockInStockByEANParams): __Observable<ResponseArgsOfIEnumerableOfStockInfoDTO> {
    return this.StockInStockByEANResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockInfoDTO));
  }

  /**
   * BranchExtension
   * @param locale Lokalisierung
   */
  StockGetCurrentBranchExtensionResponse(locale?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfDBHBranchExtensionDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock/current/branchExtension`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfDBHBranchExtensionDTO>;
      }),
    );
  }
  /**
   * BranchExtension
   * @param locale Lokalisierung
   */
  StockGetCurrentBranchExtension(locale?: null | string): __Observable<ResponseArgsOfDBHBranchExtensionDTO> {
    return this.StockGetCurrentBranchExtensionResponse(locale).pipe(__map((_r) => _r.body as ResponseArgsOfDBHBranchExtensionDTO));
  }

  /**
   * Dem aktuellen Lager zugeortnete Filiale
   * @param locale Lokalisierung
   */
  StockCurrentBranchResponse(locale?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfBranchDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock/current/branch`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfBranchDTO>;
      }),
    );
  }
  /**
   * Dem aktuellen Lager zugeortnete Filiale
   * @param locale Lokalisierung
   */
  StockCurrentBranch(locale?: null | string): __Observable<ResponseArgsOfBranchDTO> {
    return this.StockCurrentBranchResponse(locale).pipe(__map((_r) => _r.body as ResponseArgsOfBranchDTO));
  }

  /**
   * Zugeordnetes Lager
   * @param locale Lokalisierung
   */
  StockCurrentStockResponse(locale?: null | string): __Observable<__StrictHttpResponse<ResponseArgsOfStockDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (locale != null) __params = __params.set('locale', locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock/current`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfStockDTO>;
      }),
    );
  }
  /**
   * Zugeordnetes Lager
   * @param locale Lokalisierung
   */
  StockCurrentStock(locale?: null | string): __Observable<ResponseArgsOfStockDTO> {
    return this.StockCurrentStockResponse(locale).pipe(__map((_r) => _r.body as ResponseArgsOfStockDTO));
  }

  /**
   * @param params The `StockService.StockGetStocksByBranchParams` containing the following parameters:
   *
   * - `branchId`:
   *
   * - `locale`:
   */
  StockGetStocksByBranchResponse(
    params: StockService.StockGetStocksByBranchParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.locale != null) __params = __params.set('locale', params.locale.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/branch/${encodeURIComponent(String(params.branchId))}/stock`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockDTO>;
      }),
    );
  }
  /**
   * @param params The `StockService.StockGetStocksByBranchParams` containing the following parameters:
   *
   * - `branchId`:
   *
   * - `locale`:
   */
  StockGetStocksByBranch(params: StockService.StockGetStocksByBranchParams): __Observable<ResponseArgsOfIEnumerableOfStockDTO> {
    return this.StockGetStocksByBranchResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockDTO));
  }
  StockGetStocksResponse(): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockDTO>;
      }),
    );
  }
  StockGetStocks(): __Observable<ResponseArgsOfIEnumerableOfStockDTO> {
    return this.StockGetStocksResponse().pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockDTO));
  }

  /**
   * @param stockItemId undefined
   */
  StockGetStockEntriesResponse(stockItemId: number): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockEntryDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock/stockitem/${encodeURIComponent(String(stockItemId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockEntryDTO>;
      }),
    );
  }
  /**
   * @param stockItemId undefined
   */
  StockGetStockEntries(stockItemId: number): __Observable<ResponseArgsOfIEnumerableOfStockEntryDTO> {
    return this.StockGetStockEntriesResponse(stockItemId).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockEntryDTO));
  }

  /**
   * @param itemId undefined
   */
  StockGetStockItemsResponse(itemId?: number): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockItemDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (itemId != null) __params = __params.set('itemId', itemId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock/stockitem`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockItemDTO>;
      }),
    );
  }
  /**
   * @param itemId undefined
   */
  StockGetStockItems(itemId?: number): __Observable<ResponseArgsOfIEnumerableOfStockItemDTO> {
    return this.StockGetStockItemsResponse(itemId).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockItemDTO));
  }

  /**
   * @param params The `StockService.StockGetStockOrdersParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `itemId`:
   */
  StockGetStockOrdersResponse(
    params: StockService.StockGetStockOrdersParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockOrderDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.itemId != null) __params = __params.set('itemId', params.itemId.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/order`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockOrderDTO>;
      }),
    );
  }
  /**
   * @param params The `StockService.StockGetStockOrdersParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `itemId`:
   */
  StockGetStockOrders(params: StockService.StockGetStockOrdersParams): __Observable<ResponseArgsOfIEnumerableOfStockOrderDTO> {
    return this.StockGetStockOrdersResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockOrderDTO));
  }

  /**
   * @param params The `StockService.StockGetStockReservationsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `itemId`:
   */
  StockGetStockReservationsResponse(
    params: StockService.StockGetStockReservationsParams,
  ): __Observable<__StrictHttpResponse<ResponseArgsOfIEnumerableOfStockReservationDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.itemId != null) __params = __params.set('itemId', params.itemId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/inventory/stock/${encodeURIComponent(String(params.stockId))}/reservation`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfIEnumerableOfStockReservationDTO>;
      }),
    );
  }
  /**
   * @param params The `StockService.StockGetStockReservationsParams` containing the following parameters:
   *
   * - `stockId`:
   *
   * - `itemId`:
   */
  StockGetStockReservations(
    params: StockService.StockGetStockReservationsParams,
  ): __Observable<ResponseArgsOfIEnumerableOfStockReservationDTO> {
    return this.StockGetStockReservationsResponse(params).pipe(__map((_r) => _r.body as ResponseArgsOfIEnumerableOfStockReservationDTO));
  }
}

module StockService {
  /**
   * Parameters for StockInStock
   */
  export interface StockInStockParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * Artikel PKs
     */
    articleIds: Array<number>;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for StockStocksRequest
   */
  export interface StockStocksRequestParams {
    /**
     * Abfragedaten
     */
    stocksRequest: StocksRequestValues;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for StockStockRequest
   */
  export interface StockStockRequestParams {
    /**
     * Abfragedaten
     */
    stockRequest: StockRequestValues;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for StockCheckItemsForReturn
   */
  export interface StockCheckItemsForReturnParams {
    /**
     * EANs
     */
    eans: Array<string>;

    /**
     * Filial-Nr
     */
    branchNumber: string;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for StockInStockByEAN
   */
  export interface StockInStockByEANParams {
    /**
     * Lager PK
     */
    stockId: number;

    /**
     * EANs
     */
    eans: Array<string>;

    /**
     * Lokalisierung (optional)
     */
    locale?: null | string;
  }

  /**
   * Parameters for StockGetStocksByBranch
   */
  export interface StockGetStocksByBranchParams {
    branchId: number;
    locale?: null | string;
  }

  /**
   * Parameters for StockGetStockOrders
   */
  export interface StockGetStockOrdersParams {
    stockId: number;
    itemId?: number;
  }

  /**
   * Parameters for StockGetStockReservations
   */
  export interface StockGetStockReservationsParams {
    stockId: number;
    itemId?: number;
  }
}

export { StockService };
