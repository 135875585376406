<div class="grid grid-flow-col items-center justify-between px-4 py-2 border-t-2 border-solid border-surface-2">
  <h3 class="font-bold text-xl">Rechnungsadresse</h3>
  <a
    *ngIf="addBillingAddressRoute$ | async; let addRoute"
    type="button"
    class="text-brand font-bold"
    [routerLink]="addRoute.path"
    [queryParams]="addRoute.queryParams"
    [queryParamsHandling]="'merge'"
  >
    Hinzufügen
  </a>
</div>

<div class="grid grid-flow-row">
  <label
    class="px-4 py-3 font-bold border-t-2 border-solid border-surface-2 flex flex-row justify-between items-center"
    *ngIf="showCustomerAddress$ | async"
  >
    <input
      *ngIf="isNotBusinessKonto$ | async"
      name="assigned-payer"
      type="radio"
      [checked]="!(selectedPayer$ | async)"
      (change)="selectCustomerAddress()"
    />
    <div class="ml-2 flex flex-row justify-between items-start grow">
      <span class="mr-4">
        {{ customer$ | async | address }}
      </span>
      <a
        *ngIf="editRoute$ | async; let editRoute"
        [routerLink]="editRoute.path"
        [queryParams]="editRoute.queryParams"
        [queryParamsHandling]="'merge'"
        class="text-brand font-bold"
        type="button"
      >
        Bearbeiten
      </a>
    </div>
  </label>
  <label
    class="px-4 py-3 font-bold border-t-2 border-solid border-surface-2 cursor-pointer flex flex-row items-center justify-start"
    *ngFor="let assignedPayer of assignedPayers$ | async"
  >
    <input
      name="assigned-payer"
      type="radio"
      [value]="assignedPayer"
      [checked]="(selectedPayer$ | async)?.payer.id === assignedPayer.payer.id"
      (change)="selectPayer(assignedPayer)"
    />
    <div class="ml-2 flex flex-row justify-between items-start grow">
      <span class="mr-4">
        {{ assignedPayer.payer.data | address }}
      </span>
      <ng-container *ngIf="canEditAddress$ | async">
        <a
          *ngIf="editRoute(assignedPayer.payer.id); let editRoute"
          class="text-brand font-bold"
          [routerLink]="editRoute.path"
          [queryParams]="editRoute.queryParams"
          [queryParamsHandling]="'merge'"
        >
          Bearbeiten
        </a>
      </ng-container>
    </div>
  </label>
</div>
