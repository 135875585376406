import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationService } from '@core/application';
import { Config } from '@core/config';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CanActivateAssortmentGuard {
  constructor(
    private readonly _applicationService: ApplicationService,
    private readonly _config: Config,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const process = await this._applicationService
      .getProcessById$(this._config.get('process.ids.assortment'))
      .pipe(first())
      .toPromise();
    if (!process) {
      await this._applicationService.createProcess({
        id: this._config.get('process.ids.assortment'),
        type: 'assortment',
        section: 'branch',
        name: 'Sortiment',
      });
    }
    this._applicationService.activateProcess(this._config.get('process.ids.assortment'));
    return true;
  }
}
