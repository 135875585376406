import { Directive, Input, TemplateRef } from '@angular/core';
import { FilterInput } from '../../tree';

export interface UiInputContext {
  $implicit: FilterInput;
}

@Directive({ selector: '[sharedFilterCustomInput]' })
export class FilterCustomInputDirective {
  @Input('sharedFilterCustomInput')
  key: string;

  constructor(public templateRef: TemplateRef<UiInputContext>) {}

  static ngTemplateContextGuard<T>(dir: FilterCustomInputDirective, ctx: any): ctx is UiInputContext {
    return ctx.$implicit instanceof FilterInput;
  }
}
