import { ChangeDetectorRef, Pipe, PipeTransform, OnDestroy } from '@angular/core';
import { BranchService, BranchDTO } from '@swagger/oms';
import { memorize } from '@utils/common';
import { Subject } from 'rxjs';
import { shareReplay, takeUntil } from 'rxjs/operators';

@Pipe({
  name: 'resolveBranch',
  standalone: true,
  pure: false,
})
export class ResolveBranchPipe implements PipeTransform, OnDestroy {
  value: BranchDTO | undefined;

  private _onDestroy$ = new Subject<void>();

  constructor(
    private _branchService: BranchService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  @memorize()
  private _getBranches() {
    return this._branchService.BranchGetBranches({}).pipe(shareReplay(1));
  }

  fetchBranch(branchId: number): void {
    this._getBranches()
      .pipe(takeUntil(this._onDestroy$))
      .subscribe((res) => {
        this.value = res.result.find((branch) => branch.id === branchId);
        this._cdr.markForCheck();
      });
  }

  transform(branchId: number): BranchDTO | undefined {
    this.fetchBranch(branchId);

    return this.value;
  }
}
