import { Injectable } from '@angular/core';
import { AutocompleteTokenDTO, OrderService, QueryTokenDTO } from '@swagger/oms';
import { memorize } from '@utils/common';
import { map, shareReplay } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class DomainCustomerOrderService {
  constructor(private _orderService: OrderService) {}

  complete(payload: AutocompleteTokenDTO) {
    return this._orderService.OrderKundenbestellungenAutocomplete(payload);
  }

  search(payload: QueryTokenDTO) {
    return this._orderService.OrderKundenbestellungen({ ...payload });
    // branch_id'
  }

  getOrderItemsByOrderNumber(params: { compartmentCode?: string; orderId: number }) {
    return this._orderService.OrderKundenbestellungen({
      filter: { all_branches: 'true', archive: 'true' },
      input: { order_id: String(params.orderId), compartment_code: params.compartmentCode },
    });
  }

  @memorize()
  settings() {
    return this._orderService.OrderKundenbestellungenSettings().pipe(
      map((res) => res?.result),
      shareReplay(),
    );
  }
}
