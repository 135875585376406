import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CustomerSearchComponent } from './customer-search.component';
import { CustomerResultsSideViewModule } from './results-side-view/results-side-view.module';
import { RouterModule } from '@angular/router';
import { CustomerResultsMainViewModule } from './results-main-view/results-main-view.module';
import { CustomerDetailsMainViewModule } from './details-main-view/details-main-view.module';
import { CustomerHistoryMainViewModule } from './history-main-view/history-main-view.module';
import { CustomerFilterMainViewModule } from './filter-main-view/filter-main-view.module';
import { MainSideViewModule } from './main-side-view/main-side-view.module';
import { OrderDetailsSideViewComponent } from './order-details-side-view/order-details-side-view.component';
import { CustomerMainViewComponent } from './main-view/main-view.component';
import { SharedSplitscreenComponent } from '@shared/components/splitscreen';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedSplitscreenComponent,
    CustomerResultsSideViewModule,
    CustomerResultsMainViewModule,
    CustomerDetailsMainViewModule,
    CustomerHistoryMainViewModule,
    CustomerFilterMainViewModule,
    MainSideViewModule,
    OrderDetailsSideViewComponent,
    CustomerMainViewComponent,
  ],
  exports: [CustomerSearchComponent],
  declarations: [CustomerSearchComponent],
})
export class CustomerSearchModule {}
