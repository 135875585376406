/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { OmsConfiguration as __Configuration } from '../oms-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ListResponseArgsOfLogisticianDTO } from '../models/list-response-args-of-logistician-dto';
import { ResponseArgsOfLogisticianDTO } from '../models/response-args-of-logistician-dto';
@Injectable({
  providedIn: 'root',
})
class LogisticianService extends __BaseService {
  static readonly LogisticianGetLogisticiansPath = '/order/logistician';
  static readonly LogisticianGetLogisticianByIdPath = '/order/logistician/{logisticianId}';

  constructor(config: __Configuration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Listet alle Logistiker
   * @param params The `LogisticianService.LogisticianGetLogisticiansParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  LogisticianGetLogisticiansResponse(
    params: LogisticianService.LogisticianGetLogisticiansParams,
  ): __Observable<__StrictHttpResponse<ListResponseArgsOfLogisticianDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.take != null) __params = __params.set('take', params.take.toString());
    if (params.skip != null) __params = __params.set('skip', params.skip.toString());
    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/logistician`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListResponseArgsOfLogisticianDTO>;
      }),
    );
  }
  /**
   * Listet alle Logistiker
   * @param params The `LogisticianService.LogisticianGetLogisticiansParams` containing the following parameters:
   *
   * - `take`:
   *
   * - `skip`:
   */
  LogisticianGetLogisticians(params: LogisticianService.LogisticianGetLogisticiansParams): __Observable<ListResponseArgsOfLogisticianDTO> {
    return this.LogisticianGetLogisticiansResponse(params).pipe(__map((_r) => _r.body as ListResponseArgsOfLogisticianDTO));
  }

  /**
   * Logistiker
   * @param logisticianId PK Logistiker
   */
  LogisticianGetLogisticianByIdResponse(logisticianId: number): __Observable<__StrictHttpResponse<ResponseArgsOfLogisticianDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>('GET', this.rootUrl + `/order/logistician/${encodeURIComponent(String(logisticianId))}`, __body, {
      headers: __headers,
      params: __params,
      responseType: 'json',
    });

    return this.http.request<any>(req).pipe(
      __filter((_r) => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ResponseArgsOfLogisticianDTO>;
      }),
    );
  }
  /**
   * Logistiker
   * @param logisticianId PK Logistiker
   */
  LogisticianGetLogisticianById(logisticianId: number): __Observable<ResponseArgsOfLogisticianDTO> {
    return this.LogisticianGetLogisticianByIdResponse(logisticianId).pipe(__map((_r) => _r.body as ResponseArgsOfLogisticianDTO));
  }
}

module LogisticianService {
  /**
   * Parameters for LogisticianGetLogisticians
   */
  export interface LogisticianGetLogisticiansParams {
    take?: null | number;
    skip?: null | number;
  }
}

export { LogisticianService };
