import { Component, ChangeDetectionStrategy, OnInit, Renderer2, ElementRef } from '@angular/core';
import { ShellService } from './shell.service';
import { ApplicationService } from '@core/application';

@Component({
  selector: 'shell-root',
  templateUrl: 'shell.component.html',
  styleUrls: ['shell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit {
  get sideMenuOpen$() {
    return this._shellService.sideMenuOpen$;
  }

  constructor(
    private _elementRef: ElementRef,
    private renderer: Renderer2,
    private _shellService: ShellService,
    private _app: ApplicationService,
  ) {}

  ngOnInit() {
    this._app.getSection$().subscribe((section) => {
      this.renderer.setAttribute(this._elementRef.nativeElement, 'data-section', section);
    });
  }

  closeSideMenu() {
    this._shellService.closeSideMenu();
  }
}
