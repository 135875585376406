import { AsyncPipe, CurrencyPipe, DatePipe, NgIf } from '@angular/common';
import { Component, ChangeDetectionStrategy, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { ProductImagePipe } from '@cdn/product-image';
import { IconComponent } from '@shared/components/icon';
import { BranchNamePipe, ResolveBranchPipe } from '@shared/pipes/branch';
import { OrderItemProcessingStatusPipe, OrderProcessingStatusPipe } from '@shared/pipes/order';
import { OrderItemDTO } from '@swagger/oms';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CustomerSearchStore } from '../../store';
import { CustomerSearchNavigation } from '@shared/services/navigation';
import { PaymentTypePipe } from '@shared/pipes/customer';

@Component({
  selector: 'page-customer-order-item-list-item',
  templateUrl: 'order-item-list-item.component.html',
  styleUrls: ['order-item-list-item.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'page-customer-order-item-list-item' },
  standalone: true,
  imports: [
    NgIf,
    AsyncPipe,
    DatePipe,
    OrderProcessingStatusPipe,
    ProductImagePipe,
    ResolveBranchPipe,
    BranchNamePipe,
    CurrencyPipe,
    IconComponent,
    RouterLink,
    PaymentTypePipe,
    OrderItemProcessingStatusPipe,
  ],
})
export class CustomerOrderItemListItemComponent implements OnInit, OnDestroy {
  private _activatedRoute = inject(ActivatedRoute);
  private _store = inject(CustomerSearchStore);
  private _navigation = inject(CustomerSearchNavigation);

  private _onDestroy = new Subject<void>();

  private _orderItemSub = new BehaviorSubject<OrderItemDTO>(undefined);

  @Input()
  get orderItem() {
    return this._orderItemSub.getValue();
  }

  set orderItem(value: OrderItemDTO) {
    this._orderItemSub.next(value);
  }
  orderId$ = this._activatedRoute.params.pipe(map((params) => Number(params.orderId)));

  order$ = this._store.order$;

  orderPaymentType$ = this.order$.pipe(map((order) => order?.paymentType));

  customerId$ = this._activatedRoute.params.pipe(map((params) => Number(params.customerId)));

  orderItemOrderType$ = this._orderItemSub.pipe(map((orderItem) => orderItem?.features?.orderType));

  orderItemSubsetItem$ = this._orderItemSub.pipe(map((orderItem) => orderItem?.subsetItems?.[0]?.data));

  orderDetailsHistoryRoute$ = combineLatest([this.customerId$, this._store.processId$, this.orderId$, this._orderItemSub]).pipe(
    map(([customerId, processId, orderId, orderItem]) =>
      this._navigation.orderDetailsHistoryRoute({ processId, customerId, orderId, orderItemId: orderItem?.id }),
    ),
  );

  isPrebooked$ = this.orderItemSubsetItem$.pipe(map((subsetItem) => (subsetItem?.isPrebooked ? 'Ja' : 'Nein')));

  processingStatus$ = this.orderItemSubsetItem$.pipe(map((subsetItem) => subsetItem?.processingStatus));

  ngOnInit() {
    this.customerId$.pipe(takeUntil(this._onDestroy)).subscribe((customerId) => {
      this._store.selectCustomer({ customerId });
    });

    this.orderId$.pipe(takeUntil(this._onDestroy)).subscribe((orderId) => {
      this._store.selectOrder(+orderId);
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this._orderItemSub.complete();
  }
}
