import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterModule } from '@shared/components/filter';

import { CustomerFilterMainViewComponent } from './filter-main-view.component';
import { RouterModule } from '@angular/router';
import { UiIconModule } from '@ui/icon';
import { LoaderComponent } from '@shared/components/loader';

@NgModule({
  imports: [CommonModule, FilterModule, RouterModule, UiIconModule, LoaderComponent],
  exports: [CustomerFilterMainViewComponent],
  declarations: [CustomerFilterMainViewComponent],
})
export class CustomerFilterMainViewModule {}
