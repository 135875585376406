import { Subject } from 'rxjs';
import { Filter } from './filter';
import { IInput, FilterInput } from './input';

export interface IInputGroup {
  readonly description?: string;
  readonly group?: string;
  readonly input?: Array<IInput>;
  readonly label?: string;
  readonly focused?: boolean;
}

export class InputGroup implements IInputGroup {
  //#region implements IUiFilterInputGroup
  private _description?: string;
  get description() {
    return this._description;
  }

  private _group?: string;
  get group() {
    return this._group;
  }

  private _input?: Array<FilterInput>;
  get input() {
    return this._input;
  }

  private _label?: string;
  get label() {
    return this._label;
  }

  //#endregion

  readonly changes = new Subject<{ keys: (keyof IInputGroup)[]; target: InputGroup }>();

  constructor(public readonly parent?: Filter) {}

  toObject(): IInputGroup {
    return {
      description: this.description,
      group: this.group,
      input: this.input?.map((input) => input.toObject()),
      label: this.label,
    };
  }

  static create(inputGroup: IInputGroup, parent?: Filter) {
    const target = new InputGroup(parent);

    target._description = inputGroup?.description;
    target._group = inputGroup?.group;
    target._input = inputGroup?.input?.map((input) => FilterInput.create(input, target));
    target._label = inputGroup?.label;

    return target;
  }
}
