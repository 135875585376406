import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiIconModule } from '@ui/icon';

import { OrderByFilterComponent } from './order-by-filter.component';

@NgModule({
  imports: [CommonModule, UiIconModule],
  exports: [OrderByFilterComponent],
  declarations: [OrderByFilterComponent],
  providers: [],
})
export class OrderByFilterModule {}
