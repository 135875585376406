import { Injectable } from '@angular/core';
import { ActionHandler } from '@core/command';
import { Result } from '@domain/defs';
import { DomainPrinterService } from '@domain/printer';
import { PrintModalComponent, PrintModalData } from '@modal/printer';
import { CustomerInfoDTO } from '@generated/swagger/crm-api';
import { UiModalService } from '@ui/modal';

@Injectable()
export class PrintKubiCustomerCommand extends ActionHandler<Result<CustomerInfoDTO[]>> {
  constructor(
    private _uiModal: UiModalService,
    private _printerService: DomainPrinterService,
  ) {
    super('PRINT_KUBI_AGB');
  }

  async handler(data: Result<CustomerInfoDTO[]>): Promise<Result<CustomerInfoDTO[]>> {
    const customerInfo = data.result ? data.result[0] : undefined;
    let p4mCode: string;
    if (customerInfo) {
      p4mCode = customerInfo.features.find((f) => f.key === 'p4mUser').value;
    }

    await this._uiModal
      .open({
        content: PrintModalComponent,
        config: { showScrollbarY: false },
        data: {
          printerType: 'Label',
          print: (printer) => this._printerService.printKubiAgb({ printer, p4mCode }).toPromise(),
        } as PrintModalData,
      })
      .afterClosed$.toPromise();
    return data;
  }
}
