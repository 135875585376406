<div class="customer-result-list-header bg-surface-2 text-surface-2-content flex flex-row justify-between pb-4">
  <div class="grid grid-flow-col gap-3 items-center justify-start grow-0">
    <shared-filter-input-group-main
      class="w-[23.5rem]"
      *ngIf="filter$ | async; let filter"
      [inputGroup]="filter?.input | group: 'main'"
      (search)="search(filter)"
      (queryChange)="cancelSearch()"
      [hint]="message$ | async"
      [loading]="fetching$ | async"
      [scanner]="true"
    ></shared-filter-input-group-main>
    <a
      class="btn btn-light h-14 font-bold"
      *ngIf="filterRoute$ | async; let route"
      [routerLink]="route.path"
      [queryParams]="route.queryParams"
      [queryParamsHandling]="'merge'"
      [class.bg-active-branch]="hasFilter$ | async"
      [class.text-white]="hasFilter$ | async"
    >
      <shared-icon icon="filter-variant"></shared-icon>
      Filter
    </a>
  </div>

  <span class="mr-5 self-end text-sm"> {{ hits$ | async }} Treffer </span>
</div>
<page-customer-result-list
  [compact]="(isTablet$ | async) || (isDesktopSmall$ | async)"
  [processId]="processId$ | async"
  [customers]="customers$ | async"
  [hits]="hits$ | async"
  (endReached)="paginate()"
  (scrollIndexChange)="scrollIndexChange($event)"
  [fetching]="fetching$ | async"
>
</page-customer-result-list>
