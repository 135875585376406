import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApplicationService } from '@core/application';
import { Config } from '@core/config';
import { first } from 'rxjs/operators';
import { z } from 'zod';

@Injectable({ providedIn: 'root' })
export class CanActivateGoodsInGuard {
  constructor(
    private readonly _applicationService: ApplicationService,
    private readonly _config: Config,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pid = this._config.get('process.ids.goodsIn', z.number());
    const process = await this._applicationService.getProcessById$(pid).pipe(first()).toPromise();
    if (!process) {
      await this._applicationService.createProcess({
        id: this._config.get('process.ids.goodsIn'),
        type: 'goods-in',
        section: 'branch',
        name: '',
      });
    }
    this._applicationService.activateProcess(this._config.get('process.ids.goodsIn'));
    return true;
  }
}
