import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { HistoryDTO as CrmHistoryDTO } from '@swagger/crm';
import { HistoryDTO as OmsHistoryDTO } from '@swagger/oms';

@Component({
  selector: 'shared-history-log-entry',
  templateUrl: 'history-log-entry.component.html',
  styleUrls: ['history-log-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SharedHistoryLogEntryComponent {
  @Input() historyLogEntryData: CrmHistoryDTO | OmsHistoryDTO;
  constructor() {}
}
