import { Injectable } from '@angular/core';
import { CrmCustomerService } from '@domain/crm';
import { UiFilterAutocomplete, UiFilterAutocompleteProvider, UiInput } from '@ui/filter';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CustomerSearchMainAutocompleteProvider extends UiFilterAutocompleteProvider {
  for = 'customer';

  constructor(private _customerSearch: CrmCustomerService) {
    super();
  }

  complete(input: UiInput): Observable<UiFilterAutocomplete[]> {
    if (input.value?.length > 2) {
      return this._customerSearch.complete(input.value, input?.parent?.parent?.getFilterAsStringDictionary()).pipe(
        map((response) => response.result),
        catchError(() => []),
      );
    }
    return of([]);
  }
}
